import React from 'react';
import CommonContext from '../components/CommonContext';
import LayerContext from '../components/LayerContext';
import Menu from '../components/Menu';

export default function MenuContainer(props) {
  return (
    <LayerContext.Consumer>
      {({ username }) => (
        <CommonContext.Consumer>
          {({ currentAction, onCurrentActionChange }) => (
            <Menu
              {...props}
              isLoggedIn={!!username}
              isMenuOpen={currentAction === 'menu'}
              onToggleMenu={() => onCurrentActionChange(null)}
            />
          )}
        </CommonContext.Consumer>
      )}
    </LayerContext.Consumer>
  )
}

import axios from 'axios';
import config from '../config.json';
import _random from 'lodash/random';
import { HTTP_METHODS } from '../const/http-methods';

const LAYER_API_URL = config.layer_url;
console.log(LAYER_API_URL);
console.log(config.cdn_url);

export const api = axios.create({ baseURL: config.api_url });
export const apiCDN = axios.create({ baseURL: config.cdn_url });

export const getAllTags = () => apiCDN.get('/tags.json');

export const getSuggestionsByAutocomplete = (title) => api.post('/suggestions/autocomplete', { title });
export const getSuggestionsByTags = (tags) => api.post('/suggestions', { tags });

export const sendVerificationCode = (credentials) => api.post('/code/send', credentials);
export const codeVerify = (credentials) => api.post('/code/verify', credentials);

export const upsertPassword = (credentials) => api.patch('password/upsert', credentials);

export const authenticate = (credentials) => api.post('/users/authenticate', credentials);
export const authenticateAnonymous = (credentials = {}) => api.post('/users/authenticate_anonymous', credentials);
export const authenticateDocCheck = (credentials) => api.post('/doccheck/auth', credentials);

export const checkUserStatus = (credentials) => api.post('/check', credentials);

export const retrieveDocument = (slug) => api.get(`/documents/${slug}`);

export const shareContent = (details) => api.post('/share_content', details);

export const forwardMedinfo = (details) => api.post('/forward-medinfo', details);

export const reportPV = (details) => api.post('/report-pv', details);

export const updateTicket = ({ external_id, ticket }) => api.patch(`/ticket/${external_id}`, ticket);

export const addParticipantConversation = (conversation_id, user_id) => api.post(`conversations/${conversation_id}/participants`, { user_id });

export const getIdentityById = ({ user_id, session_token }) => axios({
  url: `/identities/${user_id}`,
  baseURL: LAYER_API_URL,
  method: HTTP_METHODS.GET,
  headers: {
    Accept: 'application/vnd.layer+json; version=3.0',
    Authorization: `Layer session-token=${session_token}`,
  },
});

export const universalSearch = (searchTerm) => {
  return api.post('/users/universal_search', {search_term : searchTerm});
}
import React from 'react';
import {withRouter} from 'react-router-dom';
import LayerContext from '../components/LayerContext';
import CommonContext from '../components/CommonContext';
import Header from '../components/Header';

function HeaderContainer({
                           history,
                           ...props
                         }) {
  return (
    <LayerContext.Consumer>
      {({displayName, isLoading}) => (
        <CommonContext.Consumer>
          {({onCurrentActionChange}) => (
            <Header
              {...props}
              isLoggedIn={!!((displayName || '').trim())}
              isLoading={isLoading}
              displayName={displayName}
              onLogin={(e) => {
                e.preventDefault();
                history.push('/questions/login')
              }}
              onDisplayName={(e) => {
                e.preventDefault();
                history.push('/questions/logout')
              }}
              onToggleMenu={() => onCurrentActionChange('menu')}
            />
          )}
        </CommonContext.Consumer>
      )}
    </LayerContext.Consumer>
  )
}

const HeaderContainerWithRouter = withRouter(HeaderContainer);

export default React.forwardRef((props, ref) => {
  return <HeaderContainerWithRouter {...props} forwardedRef={ref} />;
});

import Layer from '@layerhq/web-xdk';
import './results-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-results-message-view', {
  mixins: [MessageViewMixin],
  style: `
    layer-text-message-view {
      display: block;
    }
    .layer-root-viewer.layer-text-message-view > * > .layer-card-top {
      display: block;
    }
  `,
  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata ? Widths.FLEX : Widths.ANY;
      }
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container'
    }
  },
  methods: {
    onRerender() {
      const view = this;
      const conversationId = view.messageViewer.message.conversationId;
      view.model.results.forEach((result) => {
        result.snippet = result.snippet.replace(/(\n\n|\r\n|\n|\r)/gm, ` &hellip; `);

        const elem = view.createElement('xircles-result-message-view', {
          model: result,
          messageViewContainerTagName: false,
          cardBorderStyle: 'none',
          parentNode: this
        });
        elem.addEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'click', () => {
          view.trigger('xircles-result-send', { conversationId, text: result.text, id: result.text });
        });
      });
    }
  }
});
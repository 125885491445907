import Layer from '@layerhq/web-xdk';

import './button-link-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-button-link-message-view', {
    mixins: [MessageViewMixin],
    style: `
    .xircles-button-link-message-view > * > .layer-card-body-outer {
        display: none;
    }
    .xircles-button-link-message-view {
        margin-right: calc(50% - 61px);
    }
    xircles-button-link-message-view {
      display: block;
      text-align: right;
    }
    .layer-root-viewer.xircles-button-link-message-view > * > .layer-card-top {
      display: block;
    }
  `,
    template: `<span layer-id="link" class="xircles-button xircles-button-primary"></span>`,
    properties: {
        widthType: {
            get: function get() {
                return this.parentComponent.isShowingMetadata ? Widths.FLEX : Widths.ANY;
            }
        },
        messageViewContainerTagName: {
            noGetterFromSetter: true,
            value: 'layer-standard-message-view-container'
        }
    },
    methods: {
        onAfterCreate() {
            this.nodes.link.addEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'click', () => {
                dispatchEvent(new CustomEvent('change-route', { detail: { url: this.model.url } }));
            });
        },
        onRerender() {
            this.nodes.link.innerHTML = this.model.title;
            this.nodes.link.style.align = this.model.align;
        }
    }
});

import * as React from 'react';
import { animateScroll as scroll } from 'react-scroll'

import './styles.less';
import ReactDOM from "react-dom";


type Props = {
  containerRef: any,
  headerRef: any
}

type State = {
  isOpen: boolean,
  isNameVisible: boolean,
  activeNavLink: string
};

class Contents extends React.Component<Props, State> {

  state = {
    isOpen: false,
    isNameVisible: false,
    activeNavLink: ''
  };

  onScroll = () => {
    const filteredNavLinksArray = this.props.navLinksArray.filter(item => window.pageYOffset >= item.offsetTop + 40);
    if (filteredNavLinksArray &&
        filteredNavLinksArray.length
      ) {
      this.state.activeNavLink !== filteredNavLinksArray[filteredNavLinksArray.length - 1].innerText && this.setState({activeNavLink: filteredNavLinksArray[filteredNavLinksArray.length - 1].innerText})
      } else {
        this.setState({activeNavLink: ''})
      }
  }

  onWheel = (evt) => {
    // if ((evt.deltaY > 0 && !this.state.isNameVisible) || (evt.deltaY < 0 && this.state.isNameVisible)) {
    if (evt.deltaY > 0 && !this.state.isNameVisible) {
      this.setState({isNameVisible: !this.state.isNameVisible});
    }
  };

  componentDidMount(): void {
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('wheel', this.onWheel);
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { anchorFromUrlCoordinates } = this.props;
    const { anchorFromUrlCoordinates: oldAnchorFromUrlCoordinates } = prevProps;
    if(anchorFromUrlCoordinates && !oldAnchorFromUrlCoordinates) {
      scroll.scrollTo((anchorFromUrlCoordinates - 54), {
        delay: 500,
        duration: 1000,
        smooth: true
      })
      this.setState({isNameVisible: true});
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('wheel', this.onWheel);
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    const domNode = ReactDOM.findDOMNode(this);
    const isOpen = this.state.isOpen;

    if (
      (isOpen && !domNode) ||
      (isOpen && !domNode.contains(event.target))
    ) {
      this.setState({
        isOpen: false
      });
    }
  }

  iconClick = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  scrollTo(element) {
    const textRef = this.props.textRef;
    const headerRefHeight = this.props.headerRef.current.style.offsetHeight

    let positionToScroll;
    for (let i = 0; i < textRef.current.childNodes.length; i++) {
      if (textRef.current.childNodes[i].innerText === element) {
        textRef.current.childNodes[i].offsetTop < 150 ?
          positionToScroll = textRef.current.childNodes[i].offsetTop + headerRefHeight :
          positionToScroll = textRef.current.childNodes[i].offsetTop + 54
      }
    }
    scroll.scrollTo(positionToScroll)
  }

  render() {
    return (
      <div className={'contents'}
           ref={this.props.containerRef}
      >
        <button
          className={"contents-icon"}
          onClick={this.iconClick}
        >
          <i className="material-icons">
            {this.state.isOpen ? 'clear' : 'list'}
          </i>
        </button>
        <p
          style={{
            opacity:
              this.state.isNameVisible && !this.state.isOpen ?
                '1' :
                '0'
          }}
        >
          {this.state.activeNavLink !== '' && this.state.activeNavLink}
        </p>
        {this.state.isOpen &&
        <ul>
          {
            this.props.contentMenu.map((item, i) => {
                return Array.isArray(item) ?
                  <ul key={item}>
                    {item.map(subitem => <li
                      key={subitem}
                      className={this.state.activeNavLink === subitem ? 'active' : null}
                    >
                      <a onClick={(e) => {
                        e.preventDefault()
                        this.scrollTo(subitem)
                      }}
                      >
                        {subitem}
                      </a>
                    </li>)}
                  </ul>
                  :
                  <li
                    key={i}
                    className={this.state.activeNavLink === item ? 'active' : null}
                  >
                    <a onClick={(e) => {
                      e.preventDefault()
                      this.scrollTo(item)
                    }}
                    >
                      {item}
                    </a>
                  </li>
              }
            )
          }
          {/*<li>Beer</li>*/}
          {/*<li>Name of product</li>*/}
          {/*<li>About</li>*/}
          {/*<li>Effects</li>*/}
          {/*<li>Overdose</li>*/}
          {/*<ul>*/}
          {/*<li className={'active'}>Hello</li>*/}
          {/*<li>Contraindications</li>*/}
          {/*<li>Pharmacokinetic properties</li>*/}
          {/*</ul>*/}
        </ul>
        }
      </div>
    )
  }
}

export default Contents;
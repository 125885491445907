// @flow
import lineClamp from 'line-clamp';
import AlloyFinger from 'alloyfinger';
import getStatusClass from '../common/status/getStatusClass';
import translateInternalStatus from '../common/status/translateInternalStatus';
import {CONVERSATION_ACTIONS} from '../const/conversation-actions';
import {USER_DISPLAY_NAMES} from '../const/user-display-names';

import Layer from '@layerhq/web-xdk';
import {isOperator} from "../utils/user";
import lastActionTime from "../common/lastActionTime";
import translatePvStatus from "../common/status/translatePvStatus";
import translateOperatorInternalStatus from "../common/status/translateOperatorInternalStatus";

import avatar from '../images/avatar-24-px.png';

require('alloyfinger/transformjs/transform');

const DATA_OPEN_CARD_TAG = 'data-card-open';
const DATA_SWIPE_CARD_TAG = 'data-card-swiped';
/* TODO: should be changed back to -212 value for three buttons on the Header Menu */
const OPEN_TRANSFROM_X_VALUE = -153;


// const isOperatorUser = true
// console.log(Layer.client.user)

const ConversationItemMixin = {
  methods: {
    onCreate() {
      if (!isOperator(Layer.client.user)) {
        const item = document.createElement('div');
        item.classList.add('xircles-status');
        item.innerHTML = `...`;

        this.nodes.title.parentNode.insertBefore(item, this.nodes.title.nextSibling);

        const getInnerNodeTransformValue = () => {
          return this.innerNode.style.transform ?
            Number(this.innerNode.style.transform.split('(')[1].split('p')[0]) : 0
        };

        const deleteConversation = () => {
          setTimeout(() => this.item.delete(Layer.Constants.DELETION_MODE.ALL), 200);
        };

        const getConversationId = () => {
          const layerItemId = this.getAttribute('layer-item-id');
          const conversationId = layerItemId.replace('conversations_', '');
          return conversationId;
        };

        const shareConversation = () => {
          const conversationId = getConversationId();
          window.dispatchEvent(new CustomEvent(CONVERSATION_ACTIONS.share, {detail: {conversationId}}));
        };

        const isCardOpen = () => this.innerNode.hasAttribute(DATA_OPEN_CARD_TAG);
        const setCardOpen = () => this.innerNode.setAttribute(DATA_OPEN_CARD_TAG, '');
        const removeCardOpen = (conversation = this) => conversation.innerNode.removeAttribute(DATA_OPEN_CARD_TAG);

        const isCardSwipe = () => this.innerNode.hasAttribute(DATA_SWIPE_CARD_TAG);
        const setCardSwipe = () => this.innerNode.setAttribute(DATA_SWIPE_CARD_TAG, '');
        const removeCardSwipe = () => this.innerNode.removeAttribute(DATA_SWIPE_CARD_TAG);

        if ('ontouchstart' in document.documentElement) {
          window.Transform(this.innerNode);
          let that = this;
          const closeCard = (conversation = this) => {
            conversation.innerNode.style.transform = `translateX(0)`;
            conversation.nodes.confirmAction.classList.remove('open');
            removeCardOpen(conversation);
          };

          const closeAllCard = () => {
            let conversationList = document.getElementsByTagName("layer-conversation-list")[0].getElementsByTagName("layer-conversation-item");
            [].forEach.call(conversationList, (conversation) => closeCard(conversation));
          };

          const openCard = () => {
            closeAllCard();
            this.innerNode.style.transform = `translateX(${OPEN_TRANSFROM_X_VALUE}px)`;
            setCardOpen();
            let closeCardEvent = new AlloyFinger(window, {
              touchEnd: (evt) => {
                closeCard();
                closeCardEvent.destroy();
              },
            });
          };

          new AlloyFinger(this.nodes.handle, {
            touchStart: (evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            },

            touchEnd: (evt) => {
              isCardOpen() ?
                closeCard() :
                openCard();
              evt.preventDefault();
              evt.stopPropagation();
            },
          });

          new AlloyFinger(this.nodes.confirmAction, {
            touchEnd: (evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            },
          });

          new AlloyFinger(this.nodes.actions, {
            touchEnd: (evt) => {
              evt.preventDefault();
              evt.stopPropagation();
            },
          });

          new AlloyFinger(this.nodes.actions.querySelector('[layer-id="actions-delete"]'), {
            tap: (evt) => {
              this.nodes.confirmAction.classList.add('open');
              this.nodes.confirmAction.classList.remove('close');

              let confirmYes = new AlloyFinger(this.nodes.confirmAction.querySelector('.confirm-yes'), {
                tap: (evt) => {
                  closeCard();
                  this.classList.add('removed-item');
                  deleteConversation();
                  confirmYes.destroy();
                }
              });

              let confirmClose = new AlloyFinger(this.nodes.confirmAction.querySelector('.confirm-no'), {
                tap: (evt) => {
                  this.nodes.confirmAction.classList.remove('open');
                  this.nodes.confirmAction.classList.add('close');
                  confirmClose.destroy();
                }
              });
            }
          });


          new AlloyFinger(this.nodes.actions.querySelector('[layer-id="actions-share"]'), {
            tap: () => shareConversation(),
          });

          new AlloyFinger(this.innerNode, {
            touchEnd: (evt) => {
              const startPosition = isCardOpen() ? OPEN_TRANSFROM_X_VALUE : 0;
              const endPosition = getInnerNodeTransformValue();
              Math.abs(startPosition - endPosition) > 15 ? setCardSwipe() : removeCardSwipe();
              if (isCardOpen() || isCardSwipe()) {
                isCardOpen() === isCardSwipe() ? closeCard() : openCard();
              }
            }
          });

          new AlloyFinger(this, {
            pressMove: (evt) => {
              let x = evt.deltaX + getInnerNodeTransformValue();

              if (x < OPEN_TRANSFROM_X_VALUE) {
                x = OPEN_TRANSFROM_X_VALUE;
              } else if (x > 0) {
                x = 0;
              }

              this.innerNode.style.transform = `translateX(${x}px)`;
            },
          });

        } else {
          let that = this;
          const openCard = () => {
            window.dispatchEvent(new Event('mousedown'));
            this.innerNode.style.transform = `translateX(${OPEN_TRANSFROM_X_VALUE}px)`;
            setCardOpen();
            window.addEventListener('mousedown', closeCard);
          };

          const closeCard = () => {
            this.nodes.confirmAction.classList.remove('open');
            removeCardOpen();
            this.innerNode.style.transform = `translateX(0)`;
            window.removeEventListener('mousedown', closeCard);
          };

          let startPosition = 0;

          const mouseMove = (evt) => {
            let x = evt.movementX + getInnerNodeTransformValue();

            if (x > 0) {
              x = 0;
            } else if (x < OPEN_TRANSFROM_X_VALUE) {
              x = OPEN_TRANSFROM_X_VALUE;
            }

            this.innerNode.style.transform = `translateX(${x}px)`;
          };

          const mouseUp = (evt) => {
            evt.stopPropagation();
            let x = getInnerNodeTransformValue();
            Math.abs(startPosition - x) > 5 ? setCardSwipe() : removeCardSwipe();
            x < OPEN_TRANSFROM_X_VALUE / 2 ? openCard() : closeCard();

            document.removeEventListener('mousemove', mouseMove);
            document.removeEventListener('mouseup', mouseUp);
          };

          const swipe = (evt) => {
            evt.preventDefault();
            evt.stopPropagation();
            startPosition = getInnerNodeTransformValue();

            const removeSwipe = (evt) => {
              removeCardSwipe();
              window.removeEventListener('click', removeSwipe);
            };
            window.addEventListener('click', removeSwipe);

            document.addEventListener('mousemove', mouseMove);
            document.addEventListener('mouseup', mouseUp);
          };
          this.addEventListener('mousedown', swipe);

          const conversationItemClick = (evt) => {
            if (isCardOpen() || isCardSwipe()) {
              evt.preventDefault();
              evt.stopPropagation();
              isCardSwipe() ? removeCardSwipe() : closeCard();
            }
          };
          this.addEventListener('click', conversationItemClick);

          const handleClick = (evt) => {
            evt.stopPropagation();
            isCardOpen() && !isCardSwipe() ? closeCard() : openCard();
            removeCardSwipe();
          };
          this.nodes.handle.addEventListener('click', handleClick);

          const deleteAction = (evt) => {
            this.nodes.confirmAction.classList.add('open');
            this.nodes.confirmAction.classList.remove('close');

            this.nodes.confirmAction.addEventListener('click', (evt) => evt.stopPropagation());
            this.nodes.confirmAction.addEventListener('mousedown', (evt) => evt.stopPropagation());

            const confirmListener = (evt) => {
              this.nodes.confirmAction
                .querySelector('.confirm-yes')
                .removeEventListener('click', confirmListener);
              this.classList.add('removed-item');
              deleteConversation();
            };

            this.nodes.confirmAction
              .querySelector('.confirm-yes')
              .addEventListener('click', confirmListener);
          };
          this.nodes.actions.querySelector('[layer-id="actions-delete"]').addEventListener('click', deleteAction);

          const shareAction = () => shareConversation();
          this.nodes.actions.querySelector('[layer-id="actions-share"]').addEventListener('click', shareAction);

          this.nodes.actions.addEventListener('click', (evt) => evt.stopPropagation());
          this.nodes.actions.addEventListener('mousedown', (evt) => evt.stopPropagation());
          this.nodes.confirmAction
            .querySelector('.confirm-no')
            .addEventListener('click', (evt) => {
              this.nodes.confirmAction.classList.remove('open');
              this.nodes.confirmAction.classList.add('close');
            });
        }
      } else {
        const item = document.createElement('div');
        item.classList.add('xircles-status');
        item.innerHTML = `...`;

        this.nodes.title.parentNode.insertBefore(item, this.nodes.title.nextSibling);
        this.style.height = '186px'
        this.getElementsByClassName('layer-conversation-item-wrapper')[0].classList.add('layer-conversation-item-wrapper__operator')
      }

    },
    onRerender() {
      if (this.getElementsByTagName('img')[0]) {
        this.getElementsByTagName('img')[0].src=avatar
      }
      lineClamp(this.nodes.lastMessage, 3);

      this.nodes.title.innerHTML = this.item.participants
        .filter(user => user !== Layer.client.user && user.displayName !== USER_DISPLAY_NAMES.MONITOR_USER)
        .map(user => user.displayName)
        .join(", ");

      const publicStatus = translateInternalStatus(this.item.metadata.xircles_status);
      const statusElement = this.nodes.title.parentNode.getElementsByClassName('xircles-status')[0];

      statusElement.innerHTML = publicStatus;
      statusElement.className = `xircles-status ${getStatusClass(publicStatus)}`;

      if (isOperator(Layer.client.user) && this.item.lastMessage) {
        const lastActionTimeValue = (lastActionTime(this.item.lastMessage.sentAt));
        const lastActionTimeElement = this.getElementsByClassName('action-time')[0];
        lastActionTimeElement.innerHTML = lastActionTimeValue;

        const publicStatus = translateOperatorInternalStatus(this.item.metadata.xircles_status);
        const publicPvStatus = translatePvStatus(this.item.metadata.pv_status);
        const statusPvElement = this.getElementsByClassName('operator-status__first')[0];
        const statusElement = this.getElementsByClassName('operator-status__second')[0];

        statusPvElement.innerHTML = publicPvStatus;
        statusElement.innerHTML = publicStatus;
      }
    },
    onSelection(isSelected) {
      if (!isOperator(Layer.client.user)) {
        const view = this;
        if (isSelected) window.onpopstate = (e) => {
          view.isSelected = false;
        }
      }

    }
  }

};

export default ConversationItemMixin;

// @flow

/**
 *  DummyComponent is created just like dummy for grid.
 *  Please use Props for customizing
 *
 *  children    - component/phrase which will be passed inside Component. NOT required.
 *  className   - class names, NOT required
 *  style       - styles for Component, NOT required
 */

import * as React from 'react';

type Props = {
    children?: Object | string,
    className?: string,
    style?: string,
}

const DummyComponent = (props: Props) => (
    <div className={ props.className } style={ props.style }>
        { props.children? props.children: ''}
    </div>
);

export default DummyComponent;
import { layerClient } from '../get-layer';

export const sessionKey = 'xircles-last-user';

export default function persistSession() {
  const userId = layerClient.userId;
  if (userId) {
    localStorage.setItem(sessionKey, JSON.stringify({
      userId: layerClient.userId,
      displayName: layerClient.user.displayName,
      isHcp: layerClient.user.metadata.is_hcp === 'true',
    }));
  } else {
    localStorage.removeItem(sessionKey);
  }
}
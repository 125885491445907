import React, {Component} from 'react';
import {translate} from 'react-i18next';
import {Layer} from '../../../get-layer';
import '@layerhq/web-xdk/ui/components/layer-file-upload-button';
import {LayerReactComponents} from "../../../get-layer";
import {customFileSelector} from "../../../custom-message-types";
import Button from '../../../material-components/Button';
import closeBox from '../../../images/close-box.svg';

const TextModel = Layer.Core.Client.getMessageTypeModelClass('TextModel')
const CarouselModel = Layer.Core.Client.getMessageTypeModelClass('CarouselModel')
const FileModel = Layer.Core.Client.getMessageTypeModelClass('FileModel');
const ImageModel = Layer.Core.Client.getMessageTypeModelClass('ImageModel');

const {
  FileUploadButton,
} = LayerReactComponents;

type Props = {
  t: Function,
  onCancel: Function
};

type State = {
  uploadedFiles: Array,
  text: string
};

class DeliverAnswerModalFragment extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.textArea = React.createRef();
    this.fileUploadButtonRef = React.createRef();

    this.state = {
      uploadedFiles: [],
      text: ''
    }
  }

  handleTextAreaChange(value) {
    this.setState({text: value})
  }

  handleClick = () => {
    this.fileRef.current.click()
  }

  handleDeleteClick(name) {
    this.setState({uploadedFiles: this.state.uploadedFiles.filter((file) => file.name !== name)})
    this.fileUploadButtonRef.current.node.nodes.input.value = null
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const conversation = this.props.conversation

    if(this.state.text.length) {
      const textModel = new TextModel({
        text: `${this.state.text}`
      })
      textModel.send({conversation});
    }



    if(this.state.uploadedFiles.length >= 2) {
      const carouselModel = new CarouselModel({
        items: this.state.uploadedFiles.map(file => {
          return new FileModel({
            source: file,
            mimeType: `${file.type}`,
            title: `${file.name}`,
            author: Layer.client.user.displayName
          })
        })
      });
      carouselModel.send({ conversation });
    } else {
      this.state.uploadedFiles.length && this.state.uploadedFiles.map(file => {
          const fileModel = new FileModel({
            source: file,
            mimeType: `${file.type.replace('application/vnd.openxmlformats-officedocument.', '').split('.')[1] || file.type}`,
            title: `${file.name}`,
            author: Layer.client.user.displayName
          });
          fileModel.send({conversation})
      })
    }

    this.props.onCancel()
  }

  handleUploadFiles = (files) => {
    this.setState({uploadedFiles: files})
  }

  // Support use of the PDF Custom Message Type by detecting when the File Upload Widget receives a PDF file.
  filesSelected(evt: CustomEvent) {
    customFileSelector(evt, this.state.conversation, this.handleUploadFiles);
  }

  render() {
    const {t} = this.props
    const {uploadedFiles} = this.state
    return (
      <form className={'deliver-answer__wrapper'} onSubmit={this.handleSubmit}>
        <textarea
          ref={this.textArea}
          onChange={e => this.handleTextAreaChange(e.target.value)}
        >
        </textarea>
        <div className={'deliver-answer__footer-wrapper'}>
          <FileUploadButton
            ref={this.fileUploadButtonRef}
            className={'mdc-button mdc-ripple-upgraded mdc-button--secondary mdc-button--outlined button button--upload'}
            multiple="true"
            onFilesSelected={this.filesSelected.bind(this)}
          >
            {t('MODAL_UPLOAD_FILE')}
          </FileUploadButton>
          <Button
            outlined className={'button button--submit'}
            color="secondary"
            type={'submit'}
          >
            {t('MODAL_SUBMIT')}
          </Button>
          <input
            onChange={this.handleChange}
            ref={this.fileRef}
            type="file"
            multiple
            style={{display: 'none'}}
          />
          {
            !!uploadedFiles.length &&
            uploadedFiles.map((item, i) => {
                const name = item.name
                return (
                  <div className={'uploaded-item'} key={name + i}>
                    <span>{`${name.length < 15 ? name : name.split('.')[0].slice(0, 7) +"..."+ name.split('.')[0].slice(-5) + "." +name.split('.')[1]}`}</span>
                    <button
                      onClick={() => this.handleDeleteClick(name)}
                    >
                      <img src={closeBox}/></button>
                  </div>
                )
              }
            )
          }
        </div>
      </form>
    );
  }
}

export default translate()(DeliverAnswerModalFragment);
import Layer from '@layerhq/web-xdk';

import './text-link-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-text-link-message-view', {
  mixins: [MessageViewMixin],
  style: `
    xircles-text-link-message-message-view {
      display: block;
    }
    .layer-root-viewer.xircles-text-link-message-view > * > .layer-card-top {
      display: block;
    }
  `,
  template: `<a layer-id="link"><i class="material-icons">link</i><span layer-id="title"></span></a>`,
  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata ? Widths.FLEX : Widths.ANY;
      }
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container'
    }
  },
  methods: {
    onRerender() {
      this.nodes.title.innerHTML = this.model.title;
      this.nodes.link.href = this.model.url;
    }
  }
});
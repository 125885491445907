// @flow

/**
 *      Header is component with animation logic for Answer Page
 *
 *      onClickArrow    - prop for coming back to prev page, function, required
 *      question        - string, which will be at header
 *
 *      HOW WORKS OPENING/CLOSING ANIMATION
 *      titleRef it's inner tag of parentTitleRef. We compare this two height to check is it openable/opened or something like that.
 *      parentTitleRef has max-height - 3 lines and overflow is hidden. !!!IT'S HARDCODED IN JS!!!!
 *      but titleRef has no max-height and overflow.
 *      Because of it if we have 5 lines of text parentTitleRef.current.clientHeight = 84px(or other initial max-height that you use)
 *      but titleRef.current.clientHeight = 140px(or other that you use)
 *      if you change styles like font-size, line-height, you should change this hardcoded value!
 *      Initial state - header is closed(this.state.opened = false)
 *      We check heights of 2 refs(titleRef, parentTitleRef),
 *      if in closed state they are equal than content <= 3 lines
 *      => we don't need to open header(iconRef is icon for opening/closing header, if text < 3 than display: none)
 *      else we should to create button for opening/closing header(iconRef display: block)
 *      when we click on button openClick is triggering.
 *      We should show all text, new parentTitleRef max-height is titleRef.clientHeight.
 *      The same logic if we want to close
 *
 *      !!!RESIZE!!!
 *      Resizing the screen can cause bugs like icon for opening if text <= 3 lines, or something like that.
 *      The most simplest way to fix it add event listener. On resize we update styles and close header
 *
 *      upperBlockRef is upper component which will be moved onSwipe/Click
 *      backgroundMenuRef is bottom component which we will see when move upperBlockRef
 *      in componentDidMount we use addAlloy to add event listeners from AlloyFinger lib
 *      logic of addAlloy copied from conversation item
 *      spikeRef is used to add onClick event if device is not sensory
 *
 *      We need to change height of backgroundMenu if we open/close or header and also give him initial size
 *      this.defaultSize(not state because we cannot use setState at componentDidUpdate) is min max-height for backgroundMenuRef
 *      is used for coming back to min size when we close header
 */

import 'alloyfinger/transformjs/transform';
import * as React from 'react';
import AlloyFinger from 'alloyfinger';
import lineClamp from 'line-clamp';
import getStatusClass from '../../../common/status/getStatusClass';
import {translate} from 'react-i18next';

import './styles.less';
import {isIE} from "../../../utils/common";

type Props = {
  onClickArrow: Function,
  question: string,
  status: string,
  operatorStatusFirst: string,
  operatorStatusSecond: string,
  t: Function,
  onClickArrow: Function,
  onClickShare: Function,
  onClickDelete: Function,
  onClickForwardToMedinfo: Function,
  onClickClearPV: Function,
  onClickCloseRequest: Function,
  onClickDeleteRequest: Function,
  handleDeliverAnswerClick: Function,
  question: string,
  isUserOperator: boolean,
  onClickTakeOver: Function
};

type State = {
  opened: boolean,
  openedConfirmDeletion: boolean,
  headerSwiped: boolean,
};

class Header extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.titleRef = React.createRef();
    this.parentTitleRef = React.createRef();
    this.iconRef = React.createRef();
    this.upperBlockRef = React.createRef();
    this.backgroundMenuRef = React.createRef();
    this.backgroundMenuConfirmRef = React.createRef();
    this.spikeRef = React.createRef();
    this.headerRef = React.createRef();
    this.deleteRef = React.createRef();
    this.shareRef = React.createRef();
    this.backgroundMenuConfirmRefConfirm = React.createRef();
    this.backgroundMenuConfirmRefClose = React.createRef();
    this.arrowBackRef = React.createRef();
    this.operatorButtonBar = React.createRef();
    this.buttonTakeOver = React.createRef();
    this.buttonCLearPV = React.createRef();
    this.buttonForwardToMedinfo = React.createRef();
    this.buttonDeliverAnswer = React.createRef();
    this.buttonClose = React.createRef();
    this.buttonDelete = React.createRef();
    this.defaultSize = 0;
    this.state = {
      opened: false,
      openedConfirmDeletion: false,
      headerSwiped: false,
    };
  }

  componentDidMount(): void {
    isIE() && this.titleRef.current.setAttribute('style', `line-height: 1.19em`)
    this.updateStyle();
    window.addEventListener('resize', this.updateStyle);
    window.addEventListener('resize', this.checkResize);

    this.addAlloy();
    // is used to set default max-height for backgroundMenu
    this.defaultSize = this.parentTitleRef.current.clientHeight;
    this.backgroundMenuRef.current.setAttribute('style', `max-height: ${this.defaultSize + 70}px`);
    this.backgroundMenuConfirmRef.current.setAttribute('style', `top: ${this.defaultSize + 55}px`);
  }

  componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<State>): void {
    this.state.openedConfirmDeletion !== prevState.openedConfirmDeletion && this.updateConfirmDeleteMessage();
    this.updateStyle();
  }

  componentWillUnmount(): void {
    //we should remove listeners if component is unmounting
    window.removeEventListener('resize', this.updateStyle);
    window.removeEventListener('resize', this.checkResize);
  }

  addAlloy = () => {
    /* TODO: should be changed back to -212 value for three buttons on the Header Menu */
    const OPEN_TRANSFORM_X_VALUE = -155;

    const getInnerNodeTransformValue = () => this.upperBlockRef.current && this.upperBlockRef.current.translateX || 0;
    const getTitleToggleStatus = () => this.iconRef.current && this.iconRef.current.style.display !== 'none';

    let startPosition = 0;

    if ('ontouchstart' in document.documentElement) {
      window.Transform(this.upperBlockRef.current);
      const headerElement = this;

      const openActionPanel = () => {
        this.upperBlockRef.current.translateX = OPEN_TRANSFORM_X_VALUE;
        windowEventHandler.on('touchEnd', closeHeaderActionEvent);
      };

      const closeActionPanel = () => {
        this.setState((prevState, props) => ({openedConfirmDeletion: false}));
        this.upperBlockRef.current.translateX = 0;
        windowEventHandler.off('touchEnd', closeHeaderActionEvent);
      };
      const toggleActionPanel = () => {
        const isOpen = this.upperBlockRef.current.translateX === OPEN_TRANSFORM_X_VALUE;
        isOpen ? closeActionPanel() : openActionPanel();
      };

      const closeHeaderActionEvent = (event) => {
        closeActionPanel();
      };

      const windowEventHandler = new AlloyFinger(window, {});

      new AlloyFinger(this.spikeRef.current, {
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
          let x = headerElement.upperBlockRef.current.translateX || 0;
          const swipe = Math.abs(startPosition - x) > 30;

          if (
            (startPosition === OPEN_TRANSFORM_X_VALUE && (swipe || x === startPosition)) ||
            (startPosition === 0 && !swipe && x !== startPosition)
          ) {
            closeActionPanel();
          } else if (
            (startPosition === -212 && !swipe) ||
            (startPosition === 0 && swipe) ||
            (x === startPosition && x === 0)
          ) {
            openActionPanel();
          }

        }
      });

      new AlloyFinger(this.iconRef.current, {
        tap: function (event) {
          event.stopPropagation();
          event.preventDefault();
          headerElement.toggleHeaderTitle();
        }
      });
      new AlloyFinger(this.backgroundMenuRef.current, {
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
        }
      });
      new AlloyFinger(this.backgroundMenuConfirmRef.current, {
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
        }
      });
      new AlloyFinger(this.backgroundMenuConfirmRefClose.current, {
        tap: function (event) {
          headerElement.toggleDeleteConfirm();
        }
      });
      new AlloyFinger(this.arrowBackRef.current, {
        touchEnd: function (event) {
          event.preventDefault();
          event.stopPropagation();
          headerElement.props.onClickArrow();
        }
      });
      new AlloyFinger(this.backgroundMenuConfirmRefConfirm.current, {
        tap: function (event) {
          headerElement.toggleDeleteConfirm();
        }
      });
      new AlloyFinger(this.deleteRef.current, {
        tap: function (event) {
          headerElement.toggleDeleteConfirm();
        }
      });
      new AlloyFinger(this.shareRef.current, {
        tap: function (event) {
          closeActionPanel();
          headerElement.handleShareClick();
        }
      });

      new AlloyFinger(this.upperBlockRef.current, {
        touchStart: function (event) {
          startPosition = this.translateX;
        },
        tap: function (event) {
          event.stopPropagation();
          event.preventDefault();
        },
        pressMove: function (event) {
          let x = this.translateX + event.deltaX;

          if (x > 0) {
            x = 0;
          } else if (x < -212) {
            x = -212;
          }

          this.translateX = x;
        },
        swipe: function (event) {
          if (getTitleToggleStatus()) {
            switch (event.direction) {
              case 'Up':
                headerElement.toggleHeaderTitle();
                break;
              case 'Down':
                headerElement.toggleHeaderTitle();
                break;
              default :
                break;
            }
          }
        },
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
          let x = this.translateX || 0;
          const swipe = Math.abs(startPosition - x) > 30;

          if (
            (startPosition === OPEN_TRANSFORM_X_VALUE && swipe) ||
            (startPosition === 0 && !swipe) ||
            (x === startPosition && x === OPEN_TRANSFORM_X_VALUE)
          ) {
            closeActionPanel();
          } else if (
            (startPosition === OPEN_TRANSFORM_X_VALUE && !swipe) ||
            (startPosition === 0 && swipe)
          ) {
            openActionPanel();
          }
        },
      });
    } else {
      window.Transform(this.upperBlockRef.current);

      const closeActionPanel = () => {
        this.setState((prevState, props) => ({openedConfirmDeletion: false}));
        this.upperBlockRef.current.translateX = 0;
        window.removeEventListener('click', toggleHeaderActionEvent);
      };

      const openActionPanel = () => {
        if (this.upperBlockRef.current) {
          this.upperBlockRef.current.translateX = OPEN_TRANSFORM_X_VALUE;
        }
        window.addEventListener('click', toggleHeaderActionEvent);
      };

      const toggleHeaderActionEvent = (event) => {
        getInnerNodeTransformValue() !== OPEN_TRANSFORM_X_VALUE ? openActionPanel() : closeActionPanel();
        window.removeEventListener('click', toggleHeaderActionEvent);
      };

      const mouseMove = (event) => {
        let x = event.movementX + getInnerNodeTransformValue();

        if (x > 0) {
          x = 0;
        } else if (x < OPEN_TRANSFORM_X_VALUE) {
          x = OPEN_TRANSFORM_X_VALUE;
        }

        this.upperBlockRef.current.translateX = x;
      };

      const mouseUp = (event) => {
        let x = getInnerNodeTransformValue();

        if (Math.abs(startPosition - x) > 2) {
          x < OPEN_TRANSFORM_X_VALUE / 2 ? openActionPanel() : closeActionPanel();
        } else if (x === startPosition) {
          x === OPEN_TRANSFORM_X_VALUE ? closeActionPanel() : openActionPanel();
        } else {
          x < OPEN_TRANSFORM_X_VALUE / 2 ? openActionPanel() : closeActionPanel();
        }

        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', mouseUp);
      };

      const swipe = (event) => {
        event.preventDefault();
        event.stopPropagation();
        startPosition = getInnerNodeTransformValue();

        const removeSwipe = (event) => {
          window.removeEventListener('click', removeSwipe);
          window.removeEventListener('mousemove', mouseMove);
        };

        window.addEventListener('click', removeSwipe);
        window.addEventListener('mousemove', mouseMove);
        window.addEventListener('mouseup', mouseUp);
      };

      this.spikeRef.current.addEventListener('mousedown', swipe);

      this.parentTitleRef.current.addEventListener('mousedown', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });

      this.shareRef.current.addEventListener('click', (event) => {
        closeActionPanel();
      });

      this.iconRef.current.addEventListener('mousedown', (event) => {
        event.stopPropagation();
        event.preventDefault();
      });
    }
  };

  handleShareClick = () => {
    this.props.onClickShare();
  };

  handleForwardToMedinfoClick = () => {
    this.props.onClickForwardToMedinfo();
  };

  handleClearPVClick = () => {
    this.props.onClickClearPV();
  };

  handleCloseRequestClick = () => {
    this.props.onClickCloseRequest();
  };

  handleDeleteRequestClick = () => {
    this.props.onClickDeleteRequest();
  };

  handleDeliverAnswerClick = () => {
    this.props.onClickDeliverAnswer();
  }

  handleProvideContentClick = () => {
    this.props.onClickProvideContent()
  }

  checkResize = () => {
    // close header if we resizing and it's open
    if (this.titleRef.current.innerText === this.props.question) {
      this.setState({opened: false});
      this.parentTitleRef.current.setAttribute('class', 'title');
    }
    // set default sized
    this.parentTitleRef.current.removeAttribute('style');
    this.defaultSize = this.parentTitleRef.current.clientHeight;
    this.backgroundMenuRef.current.setAttribute('style', `max-height: ${this.defaultSize + 70}px`);
    this.backgroundMenuConfirmRef.current.setAttribute('style', `top: ${this.defaultSize + 55}px`);
    this.titleRef.current.innerText = this.props.question;
    lineClamp(this.titleRef.current, 3);
  };

  updateConfirmDeleteMessage = () => {
    if (this.state.openedConfirmDeletion) {
      this.backgroundMenuConfirmRef.current.classList.add('open');
      this.backgroundMenuConfirmRef.current.classList.remove('close');
    } else {
      this.backgroundMenuConfirmRef.current.classList.add('close');
      this.backgroundMenuConfirmRef.current.classList.remove('open');
    }
  };

  updateStyle = () => {
    // set default size to backgroundMenu
    if (this.state.opened) {
      this.titleRef.current.innerText = this.props.question;
    } else {
      lineClamp(this.titleRef.current, 3);
      this.defaultSize = this.parentTitleRef.current.clientHeight;
      this.backgroundMenuRef.current.setAttribute('style', `max-height: ${this.defaultSize + 70}px`);
      this.backgroundMenuConfirmRef.current.setAttribute('style', `top: ${this.defaultSize + 55}px`);
    }
    // titleRef and parentTitleRef not equals => we can open it
    // if opened = true => titleRef and parentTitleRef equals, but we should have possibility to close it
    if (this.titleRef.current.innerText !== this.props.question || this.state.opened) {
      this.iconRef.current.setAttribute('style', 'display: block');
    } else {
      this.iconRef.current.setAttribute('style', 'display: none');
    }
  };

  openClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.toggleHeaderTitle();
  };

  toggleHeaderTitle = () => {
    const closeHeaderTitleEvent = (event) => {
      this.backgroundMenuRef.current.setAttribute('style', `max-height: ${this.defaultSize + 70}px`);
      this.backgroundMenuConfirmRef.current.setAttribute('style', `top: ${this.defaultSize + 55}px`);
      this.parentTitleRef.current.setAttribute('style', 'max-height: 84px');
      this.parentTitleRef.current.setAttribute('class', 'title');
      window.removeEventListener('click', closeHeaderTitleEvent);
    };

    if (this.state.opened) {
      // closed header title
      this.backgroundMenuRef.current.setAttribute('style', `max-height: ${this.defaultSize + 70}px`);
      this.backgroundMenuConfirmRef.current.setAttribute('style', `top: ${this.defaultSize + 55}px`);
      this.parentTitleRef.current.setAttribute('style', 'max-height: 84px');
      this.parentTitleRef.current.setAttribute('class', 'title');
      window.removeEventListener('click', closeHeaderTitleEvent);

    } else {
      // open header title
      this.titleRef.current.innerText = this.props.question;
      this.backgroundMenuRef.current.setAttribute('style', `max-height: ${this.titleRef.current.clientHeight + 70}px`);
      this.backgroundMenuConfirmRef.current.setAttribute('style', `top: ${this.titleRef.current.clientHeight + 55}px`);
      this.parentTitleRef.current.setAttribute('class', 'title opened');
      this.parentTitleRef.current.setAttribute('style', `max-height: ${this.titleRef.current.clientHeight}px`);
      this.setState({
        opened: !this.state.opened
      });
      window.addEventListener('click', closeHeaderTitleEvent);
    }
  };

  toggleDeleteConfirm = () => {
    this.toggleConfirmDeletionView();
  };

  confirmDeletion = () => {
    this.props.onClickDelete();
    // this.toggleConfirmDeletionView();
  };

  toggleConfirmDeletionView = () => {
    this.setState((prevState, props) => ({openedConfirmDeletion: !prevState.openedConfirmDeletion}));
  };

  transitionEnd = () => {
    // with this backgroundMenu closing works properly
    if (this.state.opened && this.titleRef.current.clientHeight !== this.parentTitleRef.current.clientHeight) {
      lineClamp(this.titleRef.current, 3);
      this.setState({
        opened: !this.state.opened
      });
    }
  };

  getStatusClassName = () => `xircles-status ${getStatusClass(this.props.status)}`;

  render() {

    const {
      t, isUserOperator, status, operatorStatusFirst, operatorStatusSecond, isTakeOverActive, onClickTakeOver,
      onClickArrow, question
    } = this.props;

    return (
      <div className={isUserOperator ? 'operator' : ''}
           ref={this.headerRef} onClick={event => {
        event.stopPropagation();
        event.preventDefault();
      }}>
        <div ref={this.backgroundMenuConfirmRef} className='xircles-confirm'>
          <span className='xircles-confirm-text'>{t('COMMON_CONFIRM')}</span>
          <span className='xircles-confirm-action confirm-yes' onClick={this.confirmDeletion}
                ref={this.backgroundMenuConfirmRefConfirm}>
            <i className="material-icons">check</i>
          </span>
          <span className='xircles-confirm-action confirm-no' onClick={this.toggleDeleteConfirm}
                ref={this.backgroundMenuConfirmRefClose}>
            <i className="material-icons">close</i>
          </span>
        </div>
        <div ref={this.operatorButtonBar} className="operator-button-bar">
          <div className="operator-button-bar__wrapper">
            <button
              onClick={onClickTakeOver}
              ref={this.buttonTakeOver}
              className={`${isTakeOverActive ? 'button-active' : ''}`}
            >
              {`${isTakeOverActive ? 'Leave Conversation' : t('OPERATOR_TAKE_OVER')}`}
              </button>
            <button ref={this.buttonCLearPV} onClick={this.handleClearPVClick}>{t('OPERATOR_CLEAR_PV')}</button>
            <button ref={this.buttonForwardToMedinfo} onClick={this.handleForwardToMedinfoClick}>{t('OPERATOR_FORWARD_TO_MEDINFO')}</button>
            <button ref={this.buttonDeliverAnswer} onClick={this.handleDeliverAnswerClick}>{t('OPERATOR_DELIVER_ANSWER')}</button>
            <button ref={this.buttonClose} onClick={this.handleCloseRequestClick}>{t('OPERATOR_CLOSE')}</button>
            <button ref={this.buttonDelete} onClick={this.handleDeleteRequestClick}>{t('OPERATOR_DELETE')}</button>
          </div>
        </div>
        <div ref={this.upperBlockRef} className="panel-header conversation-header">
          <div className={`${isUserOperator ? 'header-operator' : ''}`}>
            <div className="back-button">
              <button onClick={onClickArrow} ref={this.arrowBackRef}><i
                className="material-icons">arrow_back</i></button>
            </div>
            <div  className={`${isUserOperator ? 'operator-status__wrapper' : ''}`}>
              <div className={'xircles-operator-status xircles-operator status__first'}>{operatorStatusFirst}</div>
              <div className={'xircles-operator-status xircles-operator status__second'}>{operatorStatusSecond}</div>
              <div className={`${this.getStatusClassName()} xircles-operator-status`}>{status}</div>
            </div>

            <div className={'title'}
                 onTransitionEnd={this.transitionEnd}
                 ref={this.parentTitleRef}>
              <p ref={this.titleRef}>{question}</p>
              <i ref={this.iconRef}
                 className="material-icons"
                 onClick={this.openClick}>
                keyboard_arrow_down</i>
            </div>
          </div>
          <div ref={this.spikeRef} className={'spike'}>
            <div></div>
          </div>
        </div>
        <div ref={this.backgroundMenuRef}
             className={`background-menu ${isUserOperator ? 'background-menu__operator' : ''}`}>
          <ul>
            <li
              onClick={this.toggleDeleteConfirm}
              ref={this.deleteRef}
            ><i className="material-icons">delete</i>{`${t('COMMON_DELETE')}`}</li>
            {/* TODO: should be return back in the future releases */}
            {/* <li><i className="material-icons">outlined_flag</i>{`${t('COMMON_MARK')}`}</li> */}
            <li
              ref={this.shareRef}
              onClick={this.handleShareClick}
            >
              <i className="material-icons">share</i>
              {`${t('COMMON_SHARE')}`}
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default translate()(Header);
import Layer from '@layerhq/web-xdk';

import showMoreImg from '../images/show-more@3x.png';
import {isOperator} from "../utils/user";

const MessageListBeforeMixin = {
  methods: {
    onCreate: function () {
      this.addEventListener('scroll', this._myCheckVisibility.bind(this), {passive: true});
      // used to detect if we adding/paging messages on top or bottom (new arrivals instead scrolling to old ones)
      this.maxPosition = 0;
      // view can be scrolled to that position w/o hiding potentially unread messages
      this.maxScrollToPosition = 0;
      // this.timeoutRef = 0;
      this.orig_animatedScrollTo = this.animatedScrollTo;
      // flag to mark that self scroll maximum was reached
      this.overflow = false;
      // to determine scroll direction
      this.lastScrollTop = 0;
      // is revealing messages on the bottom
      this.isScrollingDown = true;
      // override via monkey patch
      this.animatedScrollTo = function (position) {
        const nextScrollPosition = this.maxScrollToPosition ? Math.min(this.maxScrollToPosition, position) : position;
        if (this.scrollTop < nextScrollPosition) {
          this.orig_animatedScrollTo(nextScrollPosition);
        }
        if (nextScrollPosition > 0 && nextScrollPosition === this.maxScrollToPosition) {
          this.overflow = true;
        }
      };
    },
    onPagedDataDone: function (isDoneSizingContent) {
      isDoneSizingContent && this.isScrollingDown && this._revealAll();
    },
    onRerender: {
      mode: Layer.UI.registerComponent.MODES.BEFORE,
      conditional: function onCanRerender() {
        return Boolean(this.query);
      },
      value: function value() {
        const evt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        if (!this.query.isDestroyed && evt.type === 'insert') {
          const indi = this.querySelector('.xircles-typing-indicator');
          indi && indi.remove();
        }

        // wait one cycle for adding item DOM
        setTimeout(() => {
          if (isOperator(Layer.client.user)) {
            const layerItems = this.getElementsByTagName('layer-message-item-received');
            const layerItemsSent = this.getElementsByTagName('layer-message-item-sent');
            if (layerItems) {
              for (let i = 0; i < layerItems.length; i++) {
                layerItems[i].getElementsByClassName('layer-carousel-message-view').length &&
                layerItems[i].classList.add('layer-message-status-delivered-from-operator');
              }
            }

            if (layerItemsSent) {
              for (let i = 0; i < layerItemsSent.length; i++) {
                layerItemsSent[i].getElementsByClassName('layer-message-type-view__operator').length &&
                layerItemsSent[i].classList.add('layer-message-status-delivered-from-operator');
              }
            }

            const carouselItems = this.getElementsByClassName('layer-message-item-main');
            if (carouselItems) {
              for (let i = 0; i < carouselItems.length; i++) {
                carouselItems[i].classList.add('layer-message-item-main__operator')
              }
            }
          }
        })

      }
    },
    onGenerateListItem: function (widget) {
      // we adding/paging messages on bottom?
      if (this.maxPosition < widget.properties.item.position) {
        this.maxPosition = widget.properties.item.position;
        if (widget.tagName.endsWith('-SENT')) {
          // reveal and update maxScrollToPosition after user entered message
          this._revealAll(false, widget);
        } else {
          if (!widget.properties.item.isRead) {
            // wait one cycle for adding item DOM so we can get its height
            setTimeout(() => {
              // mark new arrived message
              widget.classList.add('new');
              if (this._isVisible(widget)) {
                this._revealAll(true, widget);
              } else {
                widget.classList.add('hidden');
                // if new message is not visible, hide input bar, show show-more
                this.showMore.classList.remove('xircles-hide');
                this.trigger('xircles-set-keyboard', {visibility: 'hidden', conversation: this.conversation});
              }
            })
          }
        }
      }
    },
    _setMaxScrollPosition: function (widget) {
      setTimeout(() => {
        const header = document.getElementsByClassName('conversation-header')[0]
          ? document.getElementsByClassName('conversation-header')[0].clientHeight
          : 98;
        if (widget && this.scrollHeight === this.clientHeight) {
          const r = widget.getBoundingClientRect();
          this.maxScrollToPosition = r.y - 10 - header;
        } else {
          // add chat height as offset as soon as page is full and scrolling has started
          // const scrollStartedOffset = (this.scrollHeight <= this.clientHeight) ? this.clientHeight : 0;
          // this.maxScrollToPosition = this.scrollHeight - (scrollStartedOffset + header);
          this.maxScrollToPosition = this.scrollHeight - header;
          if (this.maxScrollToPosition < 0) {
            this.maxScrollToPosition = 0;
          }
        }
        console.log('SET this.maxScrollToPosition: ' + this.maxScrollToPosition);
      })
    },
    _myCheckVisibility: function () {
      this.isScrollingDown = this.scrollTop > this.lastScrollTop;
      this.lastScrollTop = this.scrollTop;
      const children = Array.prototype.slice.call(this.childNodes);
      children.filter(item => item.classList.contains('hidden')).forEach((child) => {
        if (this._isVisible(child)) {
          child.classList.remove('hidden');
          // update maxScrollToPosition after hidden message was manually revealed
          if (!this.properties.isSelfScrolling) {
            // when self scroll maximum reached there is a timing issue in Layer.UI.components.MessageListPanel.List
            // which leads to false negative this.properties.isSelfScrolling
            // usage of this.overflow is a workaround to prevent accidentally updating MaxScrollPosition
            if (this.overflow) {
              this.overflow = false;
            } else {
              this._updateMaxScrollPosition();
            }
          }
        }
      }, this);
      // if there is nothing to show anymore, hide shore-more button and show input bar
      const bar = document.querySelector('layer-compose-bar');
      const [layerComposeBar] = document.getElementsByTagName('layer-compose-bar');
      if ((this.scrollTop + this.clientHeight + (bar.classList.contains('xircles-hide') ? 0 : 93)) < this.scrollHeight) {
        layerComposeBar.classList.add('layer-compose-bar__border-top');
        this.showMore.classList.remove('xircles-hide');
        this.trigger('xircles-set-keyboard', {visibility: 'hidden', conversation: this.conversation});
      } else {
        layerComposeBar.classList.remove('layer-compose-bar__border-top');
        this.showMore.classList.add('xircles-hide');
        this.trigger('xircles-set-keyboard', {visibility: 'visible', conversation: this.conversation});
      }
    },
    _isVisible: function (child) {
      // taken from MessageList._shouldMarkAsRead
      const errorMarginBottom = 10;
      const errorMarginTop = 10;
      const topVisiblePixel = this.scrollTop;
      const bottomVisiblePixel = this.scrollTop + this.clientHeight;
      const childTopVisiblePixel = child.offsetTop - this.offsetTop;
      const childBottomVisiblePixel = childTopVisiblePixel + child.clientHeight;
      const isTooBig = child.clientHeight + 50 > this.clientHeight;

      const isChildTopVisible = childTopVisiblePixel + errorMarginTop >= topVisiblePixel && childTopVisiblePixel < bottomVisiblePixel;
      const isChildBottomVisible = childBottomVisiblePixel <= bottomVisiblePixel + errorMarginBottom && childTopVisiblePixel < bottomVisiblePixel;
      return (isChildTopVisible && isChildBottomVisible) || isTooBig && (isChildBottomVisible || isChildTopVisible);
    },
    _updateMaxScrollPosition: function () {
      const header = document.getElementsByClassName('conversation-header')[0]
        ? document.getElementsByClassName('conversation-header')[0].clientHeight
        : 98;
      // add chat height as offset as soon as page is full and scrolling has started
      this.maxScrollToPosition = this.scrollTop + this.clientHeight - header;
      console.log('UPD this.maxScrollToPosition: ' + this.maxScrollToPosition);
    },
    _revealAll: function (doNotUpdateMaxScrollPos, widget) {
      const [layerComposeBar] = document.getElementsByTagName('layer-compose-bar');
      layerComposeBar.classList.remove('layer-compose-bar__border-top');

      const news = Array.prototype.slice.call(document.querySelectorAll('layer-message-item-received.new:not(.hidden)'));
      news.forEach((n) => n.classList.remove('new'));
      // console.log('REVEAL');
      (!doNotUpdateMaxScrollPos) && this._setMaxScrollPosition(widget);
      this.scrollToBottom(500);
    },
    onAfterCreate: function () {
      const div = document.createElement('div');
      this.showMore = div;
      const button = document.createElement('button');
      button.classList.add('show-more-button');
      div.classList.add('layer-message-item');
      div.classList.add('show-more');
      div.classList.add('xircles-hide');

      // TODO: translate
      button.innerHTML = `<i class="material-icons">keyboard_arrow_down</i>`;
      button.addEventListener('click', () => this._revealAll(false));
      div.appendChild(button);
      this.appendChild(div);
      this._revealAll(false);
    }
  }
};

export default MessageListBeforeMixin;

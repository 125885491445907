// @flow

/**
 *      Creates UI component with menu panel
 *
 *      isMenuOpen          - I guess It no need to explain. Required
 *      onToggleMenu        - function for opening/closing Menu. Should change isMenuOpen inside. Required
 *      buttonAccountList   - Array with props for MenuButton, placed at the top, NOT required.
 *      buttonContentList   - Array with props for MenuButton, placed at the bottom, NOT required.
 *      className           - className for parent <div>, default value is "menu-panel",
 *                            use for customizing <ul> and inner MenuButtons(with MenuButton className)
 */

import React, { Fragment } from 'react';
import {translate,} from 'react-i18next';
import MenuButton from './MenuButton/index';

import './styles.less'

type Props = {
  isLoggedIn: boolean,
  isMenuOpen: boolean,
  onToggleMenu: Function,
  buttonAccountList?: Array,
  buttonContentList?: Array,
  className: string,
  t: Function
}

const filterButtonAccountList = (props) => {
  const { isLoggedIn, buttonAccountList } = props;

  return !isLoggedIn
    ? buttonAccountList.filter((button) => button.id != 'account-logout-button')
    : buttonAccountList;
};

const Menu = (props: Props) => {
  const t = props.t
  const filteredButtonAccountList = filterButtonAccountList(props);

  return (
    <Fragment>
      <div className={`menu-panel-overlay ${props.isMenuOpen ? 'menu-panel-overlay__shown' : ''}`} onClick={props.onToggleMenu} />
      <div className={props.isMenuOpen ? `${props.className} open` : props.className}>
        <div className="menu-button">
          <button onClick={props.onToggleMenu}><i className="material-icons close">close</i></button>
        </div>
        <ul>
          {
            filteredButtonAccountList.map((button, number) => (
              <MenuButton
                {...button}
                key={number}
              />
            ))
          }
        </ul>
        <footer>
          <ul>
            {
              props.buttonContentList.map((button, number) => (
                <MenuButton
                  {...button}
                  key={number}
                />
              ))
            }
          </ul>
          <div dangerouslySetInnerHTML={{__html: t('MENU_FOOTER_DESCRIPTION')}}/>
        </footer>
      </div>
    </Fragment>
  );
};

Menu.defaultProps = {
  buttonAccountList: [],
  buttonContentList: [],
  className: 'menu-panel',
};

export default translate()(Menu);
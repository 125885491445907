import React, {Component} from 'react';
import {translate} from 'react-i18next';
import { withCookies } from 'react-cookie';
import './styles.less';
import TextField from '../../material-components/TextField';
import xirclesLogo from '../../images/xircleLogo.png';
import Box from "../../material-components/Box/Box";
import Button from "../../material-components/Button";
import AutoFocusInput from "../AutoFocusInput";
import {Input} from "@material/react-text-field/dist/index";

const sh = require("shorthash");
const seed = 'fgdhfgrf4574t5rfriqaaD';

type State = {
  isLatestChromeVersion: Number | null,
  emailValue: String,
  passwordValue: String,
  error: String
};

class SplashScreenPage extends Component<State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLatestChromeVersion: null,
      emailValue: '',
      passwordValue: '',
      error: ''
    };
  }

  cookieParams = {
    path: '/',
    maxAge: 60 * 30 * 24,
    sameSite: 'strict'
  };

  onEmailChange = value => this.setState({ emailValue: value });
  onPasswordChange = value => this.setState({ passwordValue: value });

  onStartTestingClick = ({loginTestData = null}) => {
    const { emailValue, passwordValue } = this.state;
    const correctPassword = sh.unique(`${emailValue.toLowerCase()}${seed}`);
    const { cookies } = this.props;

    if (!emailValue.length) {
      this.setState({ error: `${this.props.t('ERROR_DOCCHECK_WRONG_CREDENTIALS')}` })
    } else if (!passwordValue.length) {
      this.setState({ error: `${this.props.t('ERROR_AUTHENTICATION_MISSING_PASSWORD')}` })
    } else if (passwordValue !== correctPassword) {
      this.setState({ error: `${this.props.t('ERROR_AUTHENTICATION_WRONG_CREDENTIALS')}` })
    } else {
      !loginTestData && cookies.set('xir-test-login-success', { emailValue, passwordValue }, this.cookieParams);
      this.props.history.push('/questions')
    }
  };

  clearError = () => this.state.error.length && this.setState({ error: '' });

  static getDerivedStateFromProps(props, state) {
    const loginTestData = props.cookies.get('xir-test-login-success');
    if (loginTestData) {
      return {
        emailValue: loginTestData.emailValue,
        passwordValue: loginTestData.passwordValue
      }
    }
    return null
  }

  componentDidMount() {
    const { cookies } = this.props;
    const loginTestData = cookies.get('xir-test-login-success');
    const pieces = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/);
    const isChrome = pieces && pieces[0].slice(0, 6) === 'Chrome'
    const majorChromeVersion = pieces && parseInt(pieces[1], 10)
    isChrome && majorChromeVersion && this.setState({ isLatestChromeVersion: majorChromeVersion >=72 })
    if (loginTestData) {
      this.onStartTestingClick(loginTestData)
    }
  }

  render() {
    const { t } = this.props;
    const { isLatestChromeVersion, emailValue, passwordValue, error } = this.state;

    return (
      <div className={'splash-screen'}>
        <div className={'splash-screen__container'}>
          <h2>{t('COMMON_HEADER_PRODUCT_NAME')}</h2>
          <p>{t('SPLASH_WELCOME_TO_PREVIEW')}</p>
          {
            isLatestChromeVersion ?
              <div className={'splash-screen__container--actual-version-chrome'}>
                <TextField dense outlined>
                  <AutoFocusInput
                    id="email"
                    name="email"
                    type="email"
                    placeholder={`${t('LOGIN_EMAIL_PLACEHOLDER')}`}
                    value={emailValue}
                    onChange={e => this.onEmailChange(e.target.value)}
                    onFocus={this.clearError}
                  />
                </TextField>
                <TextField dense outlined style={{ marginTop: '15px' }}>
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    placeholder={`${t('LOGIN_PASSWORD_PLACEHOLDER')}`}
                    value={passwordValue}
                    onChange={e => this.onPasswordChange(e.target.value)}
                    onFocus={this.clearError}
                  />
                </TextField>
                {
                  error && <span className={'splash-screen__container--actual-version-chrome__error'}>{error}</span>
                }
                <Box mt={2}>
                  <Button
                    data-keystroke
                    outlined
                    onClick={this.onStartTestingClick}
                    >
                    {t('SPLASH_START_TESTING')}
                    </Button>
                </Box>

              </div>
              :
              <div className={'splash-screen__container--outdated-chrome'}>
                <p>{t('SPLASH_PLEASE_USE_LATEST_CHROME_VERSION')}</p>
                <p>{t('SPLASH_VERSIONS_FOR_OTHER')}</p>
              </div>
          }
          <img src={xirclesLogo}/>
        </div>
      </div>
    );
  }

}

export default withCookies(translate()(SplashScreenPage));
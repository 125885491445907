// @flow
import * as React from 'react';
import ReactDom from 'react-dom';

import Layer from '@layerhq/web-xdk';
import '@layerhq/web-xdk/ui/adapters/react';
import '@layerhq/web-xdk/ui/messages/status/layer-status-message-view';
import '@layerhq/web-xdk/ui/messages/receipt/layer-receipt-message-view';
import '@layerhq/web-xdk/ui/messages/choice/layer-choice-message-view';
import '@layerhq/web-xdk/ui/messages/carousel/layer-carousel-message-view';
import '@layerhq/web-xdk/ui/messages/buttons/layer-buttons-message-view';
import '@layerhq/web-xdk/ui/messages/file/layer-file-message-view';
import '@layerhq/web-xdk/ui/messages/location/layer-location-message-view';
import '@layerhq/web-xdk/ui/messages/product/layer-product-message-view';
import '@layerhq/web-xdk/ui/messages/feedback/layer-feedback-message-view';
import '@layerhq/web-xdk/ui/components/layer-send-button';
import '@layerhq/web-xdk/ui/components/layer-file-upload-button';
import '@layerhq/web-xdk/ui/components/layer-notifier';
import '@layerhq/web-xdk/ui/components/layer-conversation-list';
import '@layerhq/web-xdk/ui/components/layer-identity-list';


import '@layerhq/web-xdk/ui/messages/text/layer-text-message-model';

import './polyfills';
import striptags from 'striptags';

/**
 * VERSIONING:
 *
 * For backwards compatability for `WebXDK 1.0.0-pre` apps, include the following:
 */
// import '@layerhq/web-xdk/core/models/message-type-response-summary/message-type-response-summmary-v1';

/**
 * PERSISTENCE:
 *
 * Uncomment this line and change `isPersitenceEnabled` to `true` below to enable indexedDB
 * data caching. Note that user must log in with `isTrustedDevice` as `true` as well for
 * indexedDB to be used.
 */
import '@layerhq/web-xdk/core/db-manager';

/**
 *  THEMING:
 *
 * Pick from two themes provided:
 *
 * * The standard layer-basic-blue.css theme is the default
 * * Comment out layer-basic-blue an uncomment the two layer-groups files to enable the layer-groups theme
 */
// import '@layerhq/web-xdk/themes/layer-groups-customizations';
// import '@layerhq/web-xdk/themes/layer-groups.css'
// import '@layerhq/web-xdk/themes/layer-basic-blue.css'
//import './css/xircles-theme.css'
import './themes/xircles/theme.less'

import hideVirtualKeyboard from './hideVirtualKeyboard';

import {isBot, isOperator} from "./utils/user";

import AvatarMixin from './mixins/AvatarMixin';
import ChoiceMessageViewMixin from './mixins/ChoiceMessageViewMixin';
import TextMessageViewMixin from './mixins/TextMessageViewMixin';
import LinkMessageViewMixin from './mixins/LinkMessageViewMixin';
import MessageListMixin from './mixins/MessageListMixin';
import MessageListBeforeMixin from './mixins/MessageListBeforeMixin';
import MessageListAfterMixin from './mixins/MessageListAfterMixin';
import ConversationLastMessageMixin from './mixins/ConversationLastMessageMixin';
import TypingIndicatorMixin from './mixins/TypingIndicatorMixin';
import ConversationItemMixin from './mixins/ConversationItemMixin';
import MessageItemReceivedMixin from './mixins/MessageItemReceivedMixin';
import CarouselMessageViewMixin from './mixins/CarouselMessageViewMixin';
import MessageItemSentMixin from './mixins/MessageItemSentMixin';
import LayerMessageStatusMixin from './mixins/LayerMessageStatusMixin';

//import './custom-message-types/xircles-text-message/text-message-model';

import ControlMessageModel from './custom-message-types/xircles-control-message/control-message-model';
import config from './config.json';
import INTERNAL_STATUS from './const/status/internal-status';
import LayerFileMessageViewMixin from "./mixins/LayerFileMessageViewMixin";

/**
 * INITIALIZATION:
 *
 * Initialize the Layer Client and Libraries.
 *
 * * Pass in your application ID.
 * * Note: A `challenge` event listener is required, but is provided elsewhere
 * * `isPersistenceEnabled` can be left out of typical apps. Most web applications should
 *   treat persisting of data as a security hazard. An example of an exception to this
 *   is a Cordova app installed on a phone.
 */
const layerClient = Layer.init({
  appId: config.app_id,
  isPersistenceEnabled: false,
  mixins: {
    'layer-avatar': AvatarMixin,
    'layer-choice-message-view': ChoiceMessageViewMixin,
    'xircles-doccheck-message-view': ChoiceMessageViewMixin,
    'layer-text-message-view': TextMessageViewMixin,
    'layer-link-message-view': LinkMessageViewMixin,
    'layer-message-list': [MessageListBeforeMixin, MessageListMixin, MessageListAfterMixin],
    'layer-conversation-last-message': ConversationLastMessageMixin,
    'layer-typing-indicator': TypingIndicatorMixin,
    'layer-conversation-item': ConversationItemMixin,
    'layer-message-item-received': MessageItemReceivedMixin,
    'layer-carousel-message-view': CarouselMessageViewMixin,
    'layer-message-item-sent': MessageItemSentMixin,
    'layer-message-status': LayerMessageStatusMixin,
    'layer-file-message-view': LayerFileMessageViewMixin
  }
});

document.body.addEventListener('xircles-choice-send', function (evt) {
  const conversation = layerClient.getConversation(evt.detail.conversationId, true);
  const XirclesConfirmChoiceModel = Layer.Core.Client.getMessageTypeModelClass('XirclesConfirmChoiceModel');
  const model = new XirclesConfirmChoiceModel({
    customData: evt.detail.customData,
    text: evt.detail.text
  });
  model.send({conversation});
});

document.body.addEventListener('xircles-question-send', function (evt) {
  const conversation = layerClient.getConversation(evt.detail.conversationId, true);
  const QuestionModel = Layer.Core.Client.getMessageTypeModelClass('XirclesQuestionModel');
  const model = new QuestionModel({
    text: striptags(evt.detail.text),
    slug: evt.detail.slug,
    data: evt.detail.data,
  });
  model.send({conversation});
});

document.body.addEventListener('xircles-goto-url', function (evt) {
  const data = evt.detail;
  if (data.url.match(/flow-responses|predefined-answers/i)) {
    document.body.dispatchEvent(new CustomEvent('xircles-question-send', { detail: {
      conversationId: data.conversationId,
      text: striptags(data.title),
      slug: data.url
    }}));
  } else {
    window.dispatchEvent(new CustomEvent('change-route', { detail: {
      url: `/documents/${data.url}`
    }}));
  }
});

document.body.addEventListener('xircles-related-question-send', function (evt) {
  const conversation = layerClient.getConversation(evt.detail.conversationId, true);
  const RelatedQuestionModel = Layer.Core.Client.getMessageTypeModelClass('XirclesRelatedQuestionModel');
  const model = new RelatedQuestionModel({
    text: evt.detail.id // evt.detail.id or evt.detail.text
  });
  model.send({conversation});
});

const setKeyboard = function (evt) {
  const data = evt.detail;
  const elem = document.querySelector('layer-compose-bar');
  const [textArea] = document.querySelectorAll('textarea[layer-id="input"]');
  if (   // hide composebar if
    (    // conversation or event demands it
      ((data.conversation && data.conversation.isMessageHideInput))
      && // and conversation is not taken over by operator
      (!data.conversation || (data.conversation.metadata.xircles_status !== INTERNAL_STATUS.TAKEOVER))
      && // and the user is not a bot
      !isBot(layerClient.user)
    )
    ||
    (    // or
          // conversation is not taken over by operator
      (!data.conversation || (data.conversation.metadata.xircles_status !== INTERNAL_STATUS.TAKEOVER))
      && // and oser is operator
      isOperator(layerClient.user)
    )
  ) {
    textArea && textArea.setAttribute('disabled', true);
    elem.classList.add('disable-compose-bar');
    hideVirtualKeyboard();
  } else {
    textArea && textArea.removeAttribute('disabled');
    elem.classList.remove('disable-compose-bar');
  }
};

let setKeyboardDebounced = null;

document.body.addEventListener('xircles-set-keyboard', (evt) => {
  if (setKeyboardDebounced) {
    clearTimeout(setKeyboardDebounced);
  }
  setKeyboardDebounced = setTimeout(() => setKeyboard(evt), 100);
});

Layer.UI.handlers.text.register({
  name: 'html',
  order: 1000,
  handler: function (textData) {
    const txt = document.createElement('textarea');
    txt.innerHTML = textData.text;
    textData.text = txt.value;
  }
});

Layer.UI.UIUtils.registerStatusModel(ControlMessageModel);
Layer.UI.buildAndRegisterTemplate('layer-conversation-item', `
  <div class="layer-conversation-item-wrapper" >
    <div class='layer-list-item' layer-id='innerNode'>
      <layer-replaceable-content class='layer-conversation-left-side' name='conversationRowLeftSide'>
      </layer-replaceable-content>
      <div class='layer-conversation-item-content'>
          <layer-conversation-last-message layer-id='lastMessage'></layer-conversation-last-message>
          <div class='layer-conversation-title-row'>
              <layer-conversation-title layer-id='title'></layer-conversation-title>
              <layer-replaceable-content class='layer-conversation-right-side' name='conversationRowRightSide'>
              </layer-replaceable-content>
          </div>
      </div>
      <div class='xircles-handle' layer-id='handle'><div></div></div>
      <div class="xircles-footer">
      <span class='action-time' layer-id='lastActionTime'></span>
      <div class="operator-status">
        <div class="operator-status__first"></div>
        <div class="operator-status__second"></div>
      </div>
      
    </div>
    </div>

    <div class='xircles-confirm' layer-id='confirmAction'>
      <span class='xircles-confirm-text'>Are you sure?</span><span class='xircles-confirm-action confirm-yes'><i class="material-icons">check</i></span><span class='xircles-confirm-action confirm-no'><i class="material-icons">close</i></span>
    </div>
    <div class='xircles-actions' layer-id='actions'>
      <ul>
        <li layer-id='actions-delete'><i class="material-icons">delete</i>Delete</li>
        <li layer-id='actions-share'><i class="material-icons">share</i>Share</li>
      </ul>
    </div>
    

  </div>
`);

const LayerReactComponents = Layer.UI.adapters.react(React, ReactDom);

export {LayerReactComponents};
export {Layer};
export {layerClient}
export default {Layer, LayerReactComponents, layerClient};

import React from 'react';
import { Input } from '../../material-components/TextField';

type Props = {}

type State = {}

const DELAY_TIME_FOR_ANIMATION = 400;

class AutoFocusInput extends React.Component<Props, State> {
  input = null;

  componentDidMount() {
    const inputElement = this.input.inputElement;

    if (inputElement) {
      setTimeout(() => inputElement.focus(), DELAY_TIME_FOR_ANIMATION);
    }
  }

  render() {
    return (
      <Input
        ref={input => this.input = input}
        {...this.props}
      />
    );
  }
}

export default AutoFocusInput;

export const isQuestionsRoute = (str) => /\/questions\/?$/.test(str);
export const isConversationRoute = (str) =>
  /\/questions\/[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}\/?$/.test(str);
export const isDocumentsRoute = (str) => /\/documents\/[A-Za-z0-9]/.test(str)
export const isVideoRoute = (str) => /\/video\/[A-Za-z0-9]/.test(str)
export const isPhoneNumber = (str) => /(\(?([\d \-\)\–\+\/\(]+){9,}\)?([ .\-–\/]?)([\d]+))/.test(str);
export const isEmail = (str) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(str);
export const isPassword = (str) => /^[a-zA-Z0-9]{7,}$/.test(str);
export const isSecurityCode = (str) => /^[0-9]{5}$/.test(str);

export const debounce = (fn, delay) => {
  let timer = null;
  return function (...args) {
      const context = this;
      timer && clearTimeout(timer);
      timer = setTimeout(() => {
          fn.apply(context, args);
      }, delay);
  };
}

export const isIOS = () => (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform));

export const isMobileDevice = () => (
  (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1)
);

export const isIE = () => {
  const ua = navigator.userAgent;
  const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
  return is_ie;
}

export const isTabletDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
};

export const bytesToSize = (bytes) => {
  const sizes = ['b', 'kb', 'mb', 'gb'];
  if (bytes == 0) return '0 Byte';
  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
}

import PUBLIC_STATUS from '../../const/status/public-status';

export default (status) => {
  switch (status) {
    case PUBLIC_STATUS.OPEN:
      return 'open';
    case PUBLIC_STATUS.IN_PROGRESS:
      return 'in-progress';
    case PUBLIC_STATUS.ANSWER_DELIVERED:
      return 'answer-delivered';
    case PUBLIC_STATUS.RESOLVED:
      return 'solved';
    case PUBLIC_STATUS.CLOSED:
      return 'closed';
    case PUBLIC_STATUS.DELETED:
      return 'deleted';  
    default:
      return '';
      //TODO throw an error
  }
};
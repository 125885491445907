import React from 'react';
import AnswerPage from '../components/AnswerPage';
import LayerContext from '../components/LayerContext';

export default function AnswerPageContainer(props) {
  return (
    <LayerContext.Consumer>
      {({layerClient, userId }) => (
        <AnswerPage
          {...props}
          userId={userId}
          layerClient={layerClient}
        />
      )}
    </LayerContext.Consumer>
  );
}

import React, {Component} from 'react';
import {Layer} from '../../../get-layer';
import '@layerhq/web-xdk/ui/components/layer-file-upload-button';
import {universalSearch} from "../../../utils/api";
import ItemFromSearch from "./ItemFromSearch/ItemFromSearch";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import './styles.less';
import {isOperator} from "../../../utils/user";

const XirclesContentCardModel = Layer.Core.Client.getMessageTypeModelClass('XirclesContentCardModel');

type Props = {
  onCancel: Function
};

type State = {
  searchResult: Array,
  searchTerm: String,
  isLoaded: Boolean
};

class ProvideContentModalFragment extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();

    this.state = {
      searchResult: [],
      searchTerm: '',
      isLoaded: true
    }
  }

  handleTextInputChange(value) {
    this.setState({searchTerm: value})
  }

  handleSubmit = (event) => {
    this.setState({isLoaded: false})
    event.preventDefault()
    const conversation = this.props.conversation;

    universalSearch(this.state.searchTerm)
      .then((res) => {
        this.setState({searchResult: res.data, isLoaded: true})
      })
  }

  handleItemSubmit = ({title, snippet, name, url}, close = false) => {
    const {conversation} = this.props

    const contentCardModel = new XirclesContentCardModel({
      header: title,
      text: snippet,
      linkText: name,
      url: `/documents/${url}`,
      customData: {isOperator: isOperator(Layer.client.user)}
    });
    contentCardModel.send({conversation});
    close && this.props.onCancel()
  }

  render() {
    const {searchResult, isLoaded} = this.state
    return (
      <div className={'provide-content__wrapper'}>
        <form
          className={'provide-content__input'}
          onSubmit={this.handleSubmit}
        >
          <input
            type={'text'}
            ref={this.textInput}
            disabled={!isLoaded}
            placeholder={'Please enter text to search'}
            onChange={e => this.handleTextInputChange(e.target.value)}
          >
          </input>
        </form>
        {!isLoaded && <LoadingSpinner/>}
        <div className={'provide-content__result-section'}>
          {
            !!searchResult.length &&
            searchResult.map((item, i) =>
              <ItemFromSearch
                key={i}
                item={item}
                handleItemSubmit={this.handleItemSubmit}
              />
            )
          }
        </div>
      </div>
    );
  }
}

export default ProvideContentModalFragment;
// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";
import './css/index.less';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import mobileFixes from './mobile-fixes';
import './polyfills';
import LayerProvider from './components/LayerProvider';
import {layerClient} from './get-layer';
import CommonProvider from './components/CommonProvider';
import './themes/material.scss';
import {I18nextProvider} from 'react-i18next';
import i18n from './i18n';

import initReactFastclick from 'react-fastclick';

let isPortait = undefined;
let initialViewport, currentViewport;

const getViewportInfo = () => {
  const w = window, d = document, e = d.documentElement, g = d.getElementsByTagName('body')[0];
  const width = w.innerWidth || e.clientWidth || g.clientWidth;
  const height = w.innerHeight || e.clientHeight || g.clientHeight;

  return {width, height, ratio: width / height, revRatio: height / width};
};

const handleOverlay = () => {
  const minSizeThreshold = 512;
  const maxSizeThreshold = 1200;
  const ratioThreshold = 1.3;

  if (Math.min(currentViewport.width, currentViewport.height) >= minSizeThreshold || Math.max(currentViewport.width, currentViewport.height) >= maxSizeThreshold) {
    document.getElementsByClassName('landscape-overlay-wrap')[0].style.display = 'none';
    return;
  }

  if (currentViewport.ratio <= ratioThreshold && currentViewport.revRatio <= ratioThreshold) {
    document.getElementsByClassName('landscape-overlay-wrap')[0].style.display = 'none';
    return;
  }

  isPortait = currentViewport.ratio <= 1;

  if (isPortait || (window.screen.width !== window.outerWidth && window.screen.width !== window.outerHeight)) {
    document.getElementsByClassName('landscape-overlay-wrap')[0].style.display = 'none';
  } else {
    document.getElementsByClassName('landscape-overlay-wrap')[0].style.display = 'block';
  }
};

window.addEventListener('load', () => {
  initialViewport = getViewportInfo();
  currentViewport = initialViewport;
  handleOverlay();
});

window.addEventListener('resize', () => {
  currentViewport = getViewportInfo();
  handleOverlay();
});

initReactFastclick();
//reauthenticateLastUser();
const rootEl = document.getElementById('root')
if (!(rootEl instanceof Element)) {
  throw new Error('invalid type')
}
ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <CommonProvider>
      <LayerProvider layerClient={layerClient}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </LayerProvider>
    </CommonProvider>
  </I18nextProvider>
  , rootEl)
if (global.location.protocol === 'https:') registerServiceWorker();
mobileFixes();

import * as React from 'react';
import {translate,} from 'react-i18next';

import './styles.less'
import filterList from '../../const/filterList'

type Props = {
  filterList: Array,
  t: Function
}

const Filter = (props: Props) => {
  const t = props.t
  return (
    <div className={'filter-wrapper'}>
      <div className='filter'>
        <div className='item__input item__input--filters'>
          <label htmlFor='filters'>Filter</label>
          <select
            className='select'
            id='filters'
            onChange={(e) => {
              props.changeSortBy(e.target.value)
            }}
          >
            {
              Object.keys(filterList).map(key => <option value={`${filterList[key]}`} key={key}>{key}</option>)
            }
          </select>
        </div>

        <div className='item__input item__input--search'>
          <input
            type='text'
            className='text-input'
            placeholder='Search'
            // value={props.filters.text}
            // onChange={(e) => {
            //   props.setTextFilter(e.target.value)
            // }}
          />
        </div>
      </div>
    </div>
  );
};

export default translate()(Filter);
import Layer from "@layerhq/web-xdk";
const Constants = Layer.Constants;

const LayerMessageStatusMixin = {
  properties: {
    // custom status templates
    presendTemplate: {
      value: '',
    },
    pendingTemplate: {
      value: '<i class="material-icons">access_time</i>',
    },
    sentTemplate: {
      value: '<i class="material-icons">done</i>',
    },
    channelTemplate: {
      value: '<i class="material-icons">done</i>',
    },
    deliveredDMTemplate: {
      value: '<i class="material-icons">done</i>',
    },
    readDMTemplate: {
      value: '<i class="material-icons read">done</i>',
    },
    deliveredGroupTemplate: {
      value: '<i class="material-icons">done</i>',
    },
    readGroupTemplate: {
      value: '<i class="material-icons read">done</i>',
    },
  },
  methods: {
    /**
     * Adopted from Layer.UI.components.MessageStatus to show status of last message after reload.
     *
     * @method
     * @private
     * @param {Event} evt
     */
    onRerender(evt) {
      const message = this.item;

      // execute only when deliveryStatus and readStatus not yet set
      if (message.deliveryStatus || message.readStatus) return;

      const id = Layer.client.user.id;
      const conversation = this.item.getConversation(false);
      const userCount = conversation.participants.length - 1;
      const { readCount, deliveredCount } = this.item._getReceiptStatus(this.item.recipientStatus, id);
      this.item._setReceiptStatus(readCount, deliveredCount, userCount);

      if (this.item && !this.item.isDestroyed &&
        (!evt || evt.hasProperty('recipientStatus') || evt.hasProperty('syncState'))) {
        let html = '';
        if (this.messageStatusRenderer) {
          html = this.messageStatusRenderer(message);
        }

        // App called presend on the message and its not yet queued to be sent but is rendered:
        else if (message.isNew()) {
          html = this.presendTemplate;
        }

        // Message is being sent, but not yet acknowledged by server
        else if (message.isSaving()) {
          html = this.pendingTemplate;
        }

        // Don't yet have a deliveryStatus / readStatus
        else if (!message.deliveryStatus && !message.readStatus) {
          html = '';
        }

        // Message has been acknowledged by the server, but has not been delivered to anyone
        else if (message.deliveryStatus === Constants.RECIPIENT_STATE.NONE) {
          html = this.sentTemplate;
        }

        // Message is a Channel Message where read/delivery is not tracked
        else if (message.channel) {
          html = this.channelTemplate;
        }

        // One-on-One Conversations
        else if (message.getConversation().participants.length === 2) {
          if (message.readStatus === Constants.RECIPIENT_STATE.NONE) {
            html = this.deliveredDMTemplate;
          } else {
            html = this.readDMTemplate;
          }
        }

        // Group Conversations
        else if (message.readStatus === Constants.RECIPIENT_STATE.NONE) {
          const count = Object.keys(message.recipientStatus)
            .filter(id =>
              message.recipientStatus[id] === Constants.RECEIPT_STATE.DELIVERED ||
              message.recipientStatus[id] === Constants.RECEIPT_STATE.READ).length - 1;
          html = this.deliveredGroupTemplate.replace(/(\$\{.*?\})/g, match => count);
        } else {
          const count = Object.keys(message.recipientStatus)
            .filter(id => message.recipientStatus[id] === Constants.RECEIPT_STATE.READ).length - 1;
          html = this.readGroupTemplate.replace(/(\$\{.*?\})/g, match => count);
        }
        this.innerHTML = html;
      }
    },
  },
};

export default LayerMessageStatusMixin;

import Layer from '@layerhq/web-xdk';

import { USER_DISPLAY_NAMES } from '../const/user-display-names';

const AvatarMixin = {
  methods: {
    onAfterCreate: function () {
      if (this.item && this.item.participants) {
        this.item.participants = this.item.participants
          .filter(user => user !== Layer.client.user && user.displayName !== USER_DISPLAY_NAMES.MONITOR_USER);
      }
    },
  }
};

export default AvatarMixin;

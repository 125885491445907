// @flow

import * as React from 'react';
import {translate} from 'react-i18next';
import './styles.less';

const Footer = (props) => (
    <footer>
        <a className={'name'}>
          {props.t('COMMON_FOOTER_SANOFI_GENZYME')}
        </a>
        <a>{props.t('COMMON_FOOTER_CONTACT')}</a>
        <a>{props.t('COMMON_FOOTER_USAGE_AGREEMENT')}</a>
        <a>{props.t('COMMON_FOOTER_DATA_PRIVACY')}</a>
        <a>{props.t('COMMON_FOOTER_IMPRINT')}</a>
    </footer>
);

export default translate()(Footer);
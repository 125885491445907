import React from 'react';
import '../styles.less';
import {translate} from 'react-i18next';

type Props = {
  item: Object,
  handleItemSubmit: Function,
  t: Function,
};

function ItemFromSearch(
  {
    item,
    handleItemSubmit,
    t
  }
    : Props) {
  const { title, snippet } = {...item}

  return (
    <div className={'provide-content__result-section--item'}>
      <div className={'provide-content__result-section--item__body'}>
        <h2>Document title &bull; {title}</h2>
        <p>{snippet}</p>
      </div>
      <div className={'provide-content__result-section--item__button-box'}>
        <button
          onClick={() => handleItemSubmit(item)}
        >
          <i className="material-icons">keyboard_return</i>
          <span>{t('MODAL_SUBMIT')}</span>
        </button>
        <button
          onClick={() => handleItemSubmit(item, true)}
        >
          <i className="material-icons">exit_to_app</i>
          <span>{t('MODAL_SUBMIT_AND_CLOSE')}</span>
        </button>
      </div>
    </div>
  );
}

export default translate()(ItemFromSearch);
// @flow
import React from 'react';
import MaterialIcon from '@material/react-material-icon';
import PullDownItem from '../PullDownItem';
import './style.less';
import IconButton from '../../material-components/IconButton';

type Props = {
  suggestions: Array<string>,
  onClose: Function,
  onItemClick: Function,
  preventBubblingEvent: Function,
};

function PullDown({ suggestions, onClose, onItemClick, preventBubblingEvent }: Props) {
  return (
    <div>
      <div className="pull-down__overlay" onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClose();
      }}/>
      {suggestions.length > 0 && (
        <div className="pull-down">
          <span className="pull-down__title">Do you mean?</span>
          <div className="pull-down__button">
            <IconButton size="small" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClose();
            }}>
              <MaterialIcon icon="clear" />
            </IconButton>
          </div>
          <div className="pull-down__suggestions">
            {suggestions.map(sugg => (
              <PullDownItem
                suggestion={sugg}
                key={sugg}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onItemClick(sugg);
                }}
                preventBubblingEvent={preventBubblingEvent}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default PullDown

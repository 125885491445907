import Layer from '@layerhq/web-xdk';

const { Root, Client, MessageTypeModel, MessagePart } = Layer.Core;

class XirclesResultMessageModel extends MessageTypeModel {

  generateParts(callback) {

    const body = this.initBodyWithMetadata([
      'type',
      'title',
      'snippet',
      'url',
      'tags',
      'matchedTags',
      'score',
      'onlyForHcp',
    ]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });
    callback([this.part]);
  }
}

XirclesResultMessageModel.prototype.type = '';
XirclesResultMessageModel.prototype.title = '';
XirclesResultMessageModel.prototype.snippet = '';
XirclesResultMessageModel.prototype.url = '';
XirclesResultMessageModel.prototype.tags = '';
XirclesResultMessageModel.prototype.matchedTags = '';
XirclesResultMessageModel.prototype.score = 0;
XirclesResultMessageModel.prototype.onlyForHcp = false;

XirclesResultMessageModel.MIMEType = 'application/xircles.result+json';
XirclesResultMessageModel.LabelSingular = 'Result';
XirclesResultMessageModel.messageRenderer = 'xircles-result-message-view';

Root.initClass.apply(XirclesResultMessageModel, [XirclesResultMessageModel, 'XirclesResultMessageModel']);
Client.registerMessageTypeModelClass(XirclesResultMessageModel, 'XirclesResultMessageModel');

export default XirclesResultMessageModel;

import Layer from '@layerhq/web-xdk';

import * as _uiUtils from '@layerhq/web-xdk/ui/ui-utils'

import config from '../config.json';
import {isHcp} from "../utils/user";

const CarouselMessageViewMixin = {
  methods: {
    _triggerHcpCheck(cardModel) {
      const conversationId = this.mainComponent.conversationId;
      this.trigger('xircles-question-send', {
        conversationId,
        text: 'Need HCP Check',
        slug: config.hcp_check_slug,
        data: {
          url: cardModel.url,
          title: cardModel.title,
        }
      });
    },
    onRerender() {
      const items = this.getElementsByTagName('layer-message-viewer');
      if (items.length) {
        for (let i = 0; i < items.length; i++) {
          items.item(i).style.maxWidth = '235px';
          items.item(i).style.minWidth = '235px';
          items.item(i).style.width = '235px';
        }
      }
    },
    onCreate: function onCreate() {
      this.properties.touching = false;
      this.dragged = false;
      let mouseDownEventForMouseMove = null;
      this.addClickHandler('click-next', this.nodes.next, _scrollForward.bind(this));
      this.addClickHandler('click-prev', this.nodes.prev, _scrollBackward.bind(this));
      this.addEventListener('touchend', _touchEnd.bind(this), true);
      this.addEventListener('mouseleave', _touchEnd.bind(this), true);
      this.addEventListener('mousedown', (evt) => {
        this.dragged = false;
        this.isMouseDown = true;

        this.messageViewer.nodes.ui.nodes.items.childNodes.forEach(v => {
          v.removeClickHandler('open-link', v);
          v.removeClickHandler('card-click', v);
        });

        evt.preventDefault();
        evt.stopPropagation();
        mouseDownEventForMouseMove = evt;
      }, true);
      this.addEventListener('mouseup', (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.isMouseDown = false;
        if (this.dragged) {
          _touchEnd.call(this, e);
        } else {
          this.messageViewer.nodes.ui.nodes.items.childNodes.forEach(v => {
            const needHcpCheck = v.model.onlyForHcp && !isHcp(Layer.client.user);

            v.addClickHandler('open-link', v, () => {
              needHcpCheck
                ? this._triggerHcpCheck(v.model)
                : dispatchEvent(new CustomEvent('change-route', {detail: {url: v.model.url || v.model.source.url || v.querySelector('[layer-id="link"]').innerText}}));
            });
          });
        }
      });
      this.addEventListener('mousemove', (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (this.isMouseDown) {
          if (!this.dragged) {
            this._touchStart.call(this, mouseDownEventForMouseMove);
          }
          this.dragged = true;
        }
        this._touchMove.call(this, evt)
      });
    }
  }
};


function stop(node, evt) {
  if (!isVisible(node, this.nodes.items)) {
    evt.stopPropagation();
  }
}

const isVisible = (node, root) => {
  const optionalScroll = root.scrollLeft;
  return node.offsetLeft + node.clientWidth <= root.offsetLeft + root.clientWidth + optionalScroll && node.offsetLeft >= root.offsetLeft + optionalScroll;
};

function _scrollForward(evt) {
  evt.preventDefault();
  evt.stopPropagation();

  var root = this.nodes.items;
  var nodes = root.childNodes;

  var lastVisible = this._findLastFullyVisibleItem() || this._findFirstPartiallyVisibleItem();
  var lastVisibleIndex = Array.prototype.indexOf.call(root.childNodes, lastVisible);

  if (lastVisible && lastVisibleIndex !== -1 && lastVisibleIndex < root.childNodes.length - 1) {
    var scrollToNode = nodes[lastVisibleIndex];
    var scrollTo = scrollToNode.offsetLeft;
    (0, _uiUtils.animatedScrollLeftTo)(root, scrollTo, 200, this._updateScrollButtons.bind(this));

    this.classList.remove('layer-carousel-start');
  }
}

function _scrollBackward(evt) {
  evt.preventDefault();
  evt.stopPropagation();

  var root = this.nodes.items;
  var nodes = root.childNodes;
  this.classList.remove('layer-carousel-end');
  var firstVisible = this._findFirstFullyVisibleItem();
  var firstVisibleIndex = Array.prototype.indexOf.call(nodes, firstVisible);

  if (firstVisibleIndex > 0) {
    var rightMostCard = nodes[firstVisibleIndex];
    var minScrollLeft = rightMostCard.offsetLeft - root.clientWidth + rightMostCard.clientWidth;
    var found = false;

    for (var i = 0; i <= firstVisibleIndex; i++) {
      var node = nodes[i];
      var scrollTo = node.offsetLeft;
      if (scrollTo > minScrollLeft) {
        (0, _uiUtils.animatedScrollLeftTo)(root, scrollTo, 200, this._updateScrollButtons.bind(this));
        this.toggleClass('layer-carousel-start', scrollTo <= nodes[0].offsetLeft);
        found = true;
        break;
      }
    }

    if (!found) {
      var _scrollTo = nodes[firstVisibleIndex].offsetLeft;
      (0, _uiUtils.animatedScrollLeftTo)(root, _scrollTo, 200, this._updateScrollButtons.bind(this));
      this.toggleClass('layer-carousel-start', _scrollTo <= nodes[0].offsetLeft);
    }
  }
}

function _touchEnd(evt) {
  if (!this.properties.touching) return;
  var animationDuration = 200
  var root = this.nodes.items;

  var touch = evt.changedTouches ? evt.changedTouches[0] : evt;

  // If finger ended on a larger X than it started, then it moved right
  // If finger moved right, we are decreasing our scrollLeft value
  var fingerDirection = touch.pageX - this.properties.startX > 0 ? 'right' : 'left';

  var firstPartialCard = this._findFirstPartiallyVisibleItem();
  var cardWidth = firstPartialCard.clientWidth;
  var visibleItemWidth = firstPartialCard.offsetLeft + firstPartialCard.clientWidth - root.scrollLeft;
  var percentShown = visibleItemWidth / cardWidth;
  var distanceToEnd = root.scrollWidth - root.scrollLeft - root.clientWidth;
  var percentDistanceToEnd = distanceToEnd / cardWidth;

  // Items scroll to the left to reveal the right most items at the end of the carousel
  if (fingerDirection === 'left') {
    if (percentDistanceToEnd < 0.6) {
      // Revealing items to the right, but only a fraction of a card width from the end, so just scroll to the last (right-most) Carousel Item
      (0, _uiUtils.animatedScrollLeftTo)(root, root.lastChild.offsetLeft, animationDuration, this._updateScrollButtons.bind(this));
    } else if (percentShown > 0.6) {
      // Revealing items to the right, but stopped with an item more than 60% visible on the left?
      // Scroll right so as to fully show that item.
      (0, _uiUtils.animatedScrollLeftTo)(root, firstPartialCard.offsetLeft, animationDuration, this._updateScrollButtons.bind(this));
    } else {
      // Else just snap to the item immediately right of the partially visible item.
      (0, _uiUtils.animatedScrollLeftTo)(root, firstPartialCard.nextElementSibling.offsetLeft, animationDuration, this._updateScrollButtons.bind(this));
    }
  }

  // Scrolling items to the right to reach the start of the carousel
  else {
    // const caroselLength = root.getElementsByTagName('layer-message-viewer').length <= 3;
    /* eslint-disable no-lonely-if */
    if (percentShown < 0.4) {
      // If less than 40% of the left-most partially visible item is showing snap to the item to the right of it
      (0, _uiUtils.animatedScrollLeftTo)(root, firstPartialCard.nextElementSibling.offsetLeft, animationDuration, this._updateScrollButtons.bind(this));
    } else if (percentDistanceToEnd < 0.4 && firstPartialCard !== root.childNodes[0]) {
      // If close to the end (far right) while moving towards the start, snap to the last Carousel Item
      (0, _uiUtils.animatedScrollLeftTo)(root, root.lastChild.offsetLeft, animationDuration, this._updateScrollButtons.bind(this));
    } else {
      // Snap to the left-most partially visible item.  Will also trigger if the left-most item
      // is fully visible but should not do anything... or only adjust it slightly
      (0, _uiUtils.animatedScrollLeftTo)(root, firstPartialCard.offsetLeft, animationDuration, this._updateScrollButtons.bind(this));
    }
  }
  this.properties.touching = false;
}

function _touchMove(evt) {
  evt.preventDefault();
  evt.stopPropagation();
  if (!this.properties.touching) return;
  var touch = evt.touches ? evt.touches[0] : evt;
  var dx = touch.pageX - this.properties.startX;
  var dy = touch.pageY - this.properties.startY;
  if (Math.abs(dx) < Math.abs(dy)) return; // vertical scroll

  evt.preventDefault(); // prevent vertical scroll of document.body
  evt.stopPropagation();

  var scrollLeft = -dx;
  this.nodes.items.scrollLeft = this.properties.startScrollX + scrollLeft;

  // If the user is dragging a carousel, and our composer has focus, blur it
  // so that the on-screen keyboard goes away and the carousel items are fully visible
  if (document.activeElement.tagName === 'TEXTAREA') document.activeElement.blur();
};

function _touchStart(evt) {
  evt.preventDefault();
  evt.stopPropagation();
  this.properties.touching = true;
  var touch = evt.touches ? evt.touches[0] : evt;
  this.properties.dx = 0;
  this.properties.startScrollX = this.nodes.items.scrollLeft;
  this.properties.startX = touch.pageX;
  this.properties.startY = touch.pageY;
}

export default CarouselMessageViewMixin;

// @flow
import React, { Component } from 'react';
import _isEmpty from 'lodash/isEmpty';
import _every from 'lodash/every';
import ContentEditable from 'react-contenteditable';
import PullDown from '../PullDown';
import { isConversationRoute, isIE, isIOS, isMobileDevice } from '../../utils/common';
import { SUGGEST_MODES } from '../../const/suggest-modes';
import config from '../../config.json';
import './style.less';

type Props = {
  contentEditableRef: any,
  tags: Array<string>,
  suggestions: Array<string>,
  value: string,
  onChange: Function,
  onFocus: Function,
  onClick: Function,
  onKeyDown: Function,
  onSuggestionSelect: Function,
  innerRef: any,
};

type State = {
  showSuggestions: boolean,
  showPlaceholder: boolean,
};

// known issue: when typing new line, the coursor stays on the same line (in case if the latest word is highlighted)
// this is caused by `findLastTextNode` in `react-contenteditable` looking for the latest text node when a new html is received
// when we highlight the tags, it sends a new html to the child, so this is the case
// adjusting `node.nodeType === Node.TEXT_NODE` won't help
export default class ContentEdit extends Component<Props, State> {
  state = {
    showSuggestions: true,
    showPlaceholder: true,
  };

  componentDidMount() {
    if (isIE) {
      const { innerRef } = this.props;
      innerRef.current.addEventListener('textinput', this.handleChange);
    }
  }

  componentWillUnmount() {
    if (isIE) {
      const {innerRef} = this.props;
      innerRef.current.removeEventListener('textinput', this.handleChange);
    }
  }

  getCheckMethod = () => {
    const { suggest_mode } = this.props;

    return suggest_mode === SUGGEST_MODES.KEYWORDS
      ? this.hasMatchingKeywords
      : this.hasMatchingAutocomplete;
  };

  checkMethod = this.getCheckMethod();

  applyLetterCase = (text, tag) => {
    const tagIndex = text.toLowerCase().indexOf(tag);

    if (tagIndex >= 0) {
      const tagOriginal = text.substring(tagIndex, tagIndex + tag.length);
      return tagOriginal;
    }

    return tag;
  }

  applyHighlight(textOriginal, textWithTags) {
    return this.props.tags.reduce(
      (html, tag) => html.replace(
        new RegExp(tag, 'gi'),
        `<span class="content-editable__highlight">${this.applyLetterCase(textOriginal, tag)}</span>`
      ),
      textWithTags,
    );
  };

  applyTags(textOriginal) {
    const { suggest_mode } = config;
    const text = isIOS() ? textOriginal.replace(/\n/g, '') : textOriginal;
    const textWithTags = text.split(/\n/g).map(line => `<div>${line || '<br>'}</div>`).join('');

    return suggest_mode === SUGGEST_MODES.KEYWORDS
      ? this.applyHighlight(text, textWithTags)
      : textWithTags;
  }

  removeHighlight(html) {
    const div = document.createElement('div');
    // retaining new lines
    div.innerHTML = html.replace(/<\/div>(?!$)/g, '</div>\n');
    return div.innerText;
  }

  hasMatchingKeywords(value) {
    return this.props.tags.some((tag) => {
      const lowerCaseValue = value.toLowerCase();
      return lowerCaseValue.includes(tag);
    });
  }

  hasMatchingAutocomplete(value) {
    const minLengthForSearch = 3;
    return value.length >= minLengthForSearch;
  };

  handleChange = evt => {
    const { onChange } = this.props;
    const html = evt.target.value || evt.target.innerHTML;
    const value = html && this.removeHighlight(html);
    const event = {
      target: {
        value,
      },
    };
    // value is changed, we can show suggestions again
    this.setState({ showSuggestions: true });
    onChange(event);
  };

  hideSuggestions = () => {
    this.setState({ showSuggestions: false });
    // blur happened, re-focusing on the next execution frame
    setTimeout(() => this.props.innerRef.current.focus(), 0);
  }

  handleFocus = (e) => {
    const { innerRef, onFocus, value } = this.props;
    innerRef.current.setAttribute('style', 'opacity: 0;');

    if (!isMobileDevice()) {
      innerRef.current.removeAttribute('style');
    } else {
      if (value.length > 0) {
        innerRef.current.removeAttribute('style');
      }
    }

    this.setState({ showPlaceholder: false });
    return onFocus(e);
  };

  handleBlur = (e) => {
    const { onBlur } = this.props;
    this.setState({ showPlaceholder: true });

    const isConversationNext = isConversationRoute(window.location);
    if (!isConversationNext) {
      onBlur(e);
    }
  };

  preventBubblingEvent = (e) => e.stopPropagation();

  render = () => {
    const { showPlaceholder, showSuggestions } = this.state;
    const { contentEditableRef, value, onSuggestionSelect, onClick, onKeyDown, innerRef } = this.props;
    const html = this.applyTags(value);
    return (
      <div ref={contentEditableRef} className="content-editable questionInput">
        {showPlaceholder && !value && (
          <div className="content-editable__placeholder">
            <div>Enter your Question</div>
            <div>or Request</div>
          </div>
        )}
        <ContentEditable
          id="editable"
          html={html}
          disabled={false}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
          onClick={onClick}
          onKeyDown={onKeyDown}
          tagName='div'
          className="content-editable__input"
          innerRef={innerRef}
        />
        {this.checkMethod(value) && showSuggestions && !_isEmpty(this.props.suggestions) && (
          <PullDown suggestions={this.props.suggestions} onItemClick={onSuggestionSelect} onClose={this.hideSuggestions} preventBubblingEvent={this.preventBubblingEvent}/>
        )}
        {this.props.children}
      </div>
    );
  };
};

import React from 'react';
import TextField, { Input } from '../../../material-components/TextField';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

type Props = {
  phoneNumberOrEmail: string | null,
  onPhoneNumberOrEmailChange: Function,
  onShare: Function,
};

export default function ShareModalFragment({
  phoneNumberOrEmail,
  onPhoneNumberOrEmailChange,
  onShare,
}: Props) {
  return (
    <React.Fragment>
      <Box textAlign="center">
        <small>Please enter your recipient's mobile number or email address.</small>
      </Box>
      <TextField dense outlined style={{ width: '100%' }}>
        <Input
          id="email"
          name="email"
          type="text"
          style={{ textAlign: 'center' }}
          placeholder="Mobile number or email address"
          value={phoneNumberOrEmail}
          onChange={e => onPhoneNumberOrEmailChange(e.target.value)}
        />
      </TextField>
      <Box mt={2}>
        <Button data-keystroke outlined style={{ width: '100%' }} onClick={onShare}>Next</Button>
      </Box>
    </React.Fragment>
  );
}

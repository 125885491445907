/**
 *      Base view for all customized choice message types
 */

import Constants from "@layerhq/web-xdk/ui/constants";
import settings from "@layerhq/web-xdk/settings";
import _ from "lodash";

const baseView =  {
  properties: {

    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-titled-message-view-container',
    },

    // See parent definition
    widthType: {
      value: Constants.WIDTH.FLEX,
    },
  },
  methods: {

    getIconClass() {
      return 'layer-poll-message-view-icon';
    },

    getTitle() {
      return this.model.title;
    },

    onAfterCreate() {
      this.nodes.label.innerText = this.model.label;
      this.model.choices.forEach((choice) => {
        this.createElement('layer-action-button', {
          text: choice.text,
          tooltip: choice.tooltip,
          event: 'layer-choice-select',
          data: {id: choice.id},
          // icon: choice.icon,
          parentNode: this.nodes.choices,
        });
      });
    },

    onRerender() {
      if (!this.model.allowReselect) {
        this.toggleClass('layer-choice-message-view-complete', this.model.selectedAnswer);
      }

      this.model.choices.forEach((choice, index) => {
        const button = this.nodes.choices.childNodes[index];
        button.text = this.model.getText(index);
        button.tooltip = this.model.getTooltip(index);
        button.selected = this.model.isSelectedIndex(index);
        button.disabled = !this.model.isSelectionEnabledFor(index);
      });
    },

    onChoiceSelect(data) {
      this.model.selectAnswer(data);
    },

    runAction({event, data}) {
      if (event === 'layer-choice-select') {
        if (!this.model.isSelectionEnabled()) return;
        this.onChoiceSelect(data);

        const rootPart = this.model.message.getPartsMatchingAttribute({role: 'root'})[0];
        const rootModel = settings.client.getMessageTypeModel(rootPart.id);
        this.trigger(this.model.responseName, {
          model: this.model,
          data: this.model,
          rootModel,
        });
      }
    },
  }
};

const extendBaseView = (view) => {
  const clonedView = _.cloneDeep(baseView);
  Object.keys(clonedView).forEach((key) => {
    if (view[key]) {
      clonedView[key] = Object.assign(clonedView[key], view[key]);
    }
  });
  return Object.assign(view, clonedView)
};

export default extendBaseView;

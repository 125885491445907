import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import A from '../../A';

type Props = {
  conversationUrl: string,
  phoneNumberOrEmail: string,
  onAcknowledge: Function,
};

export default function ShareConfirmationModalFragment({
  conversationUrl,
  phoneNumberOrEmail,
  onAcknowledge,
}: Props) {
  return (
    <React.Fragment>
      <Box textAlign="center" mb={2}>
        <A href={`${conversationUrl}`}>{conversationUrl}</A>
      </Box>
      <Box textAlign="center" mb={2}>
        <Box fontSize="h5.fontSize">We've sent a link to</Box>
        <Box fontSize="h5.fontSize" wordBreak="break-all">{phoneNumberOrEmail}</Box>
      </Box>
      <Box textAlign="center">
        <Button data-keystroke outlined onClick={onAcknowledge}>OK</Button>
      </Box>
    </React.Fragment>
  );
}

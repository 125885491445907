// @flow
import React from 'react';
import A from '../../A';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import {translate} from 'react-i18next';

type Props = {
  phoneNumberOrEmail: string | null,
  onRequestCode: Function,
  onCreatePassword: Function,
  t: Function
};

function SendCodeModalFragment({
  phoneNumberOrEmail,
  onRequestCode,
  onCreatePassword,
  t
}: Props) {
  return (
    <React.Fragment>
      <Box fontSize="htmlFontSize" textAlign="center" mt={0.75} mb={1.25}>{phoneNumberOrEmail}</Box>
      <Box textAlign="center" mb={1.5}>
        {t('LOGIN_SEND_CODE_FIRST_ROW')} <br/>
        {t('LOGIN_SEND_CODE_SECOND_ROW')}
      </Box>
      <Box mb={1}>
        <Button data-keystroke outlined style={{ width: '100%' }} onClick={onRequestCode}>{t('LOGIN_SEND_LOGIN_CODE')}</Button>
      </Box>
      <Box textAlign="center" mt={7}>
        <A
          href="#"
          onClick={(e) => {
            e.preventDefault();
            return onCreatePassword(e);
          }}
        >
          {t('LOGIN_SEND_CODE_CREATE_A')}
        </A>{' '}
        <span>{`${t('LOGIN_PASSWORD_PLACEHOLDER')}.`}</span>
      </Box>
    </React.Fragment>
  );
}

export default translate()(SendCodeModalFragment)
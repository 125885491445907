export default {
  NOT_FINISHED: `Not finished`,
  REFRAMED: `Reframed`,
  FORWARD_REQUESTED: `Forward requested`,
  FORWARDED: `Forwarded`,
  ANSWER_DELIVERED: `Answer delivered`,
  HELPFUL: `Helpful`,
  CLOSED: `Closed`,
  DELETED: `Deleted`,
  TAKEOVER: `Takeover`,
};
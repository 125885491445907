// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

type Props = {
  isRegister: string,
  greetingMessage: string,
  welcomeBackMessage: string,
  acknowledgeButton: string,
  onAcknowledge: Function,
};

function WelcomeModalFragment({
  isRegister,
  greetingMessage,
  welcomeBackMessage,
  acknowledgeButton,
  onAcknowledge,
}: Props) {
  return (
    <React.Fragment>
      <Box textAlign="center" mt={2} mb={4}>
        <Box fontSize="h5.fontSize">{greetingMessage}</Box>
        <Box fontSize="h5.fontSize">{welcomeBackMessage}</Box>
      </Box>
      <Box mt={isRegister ? 6 : 0} textAlign="center">
        <Button data-keystroke outlined onClick={onAcknowledge}>{acknowledgeButton}</Button>
      </Box>
    </React.Fragment>
  );
}

export default WelcomeModalFragment;
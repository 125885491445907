// @flow
/**
 * This UI Component manages the Layer Conversations.
 *
 * It provides a Conversation List on the left, and a Conversation View on the right.
 *
 * It also uses an Identity List via the EditCOnversationDialog.js file to create new Conversations.
 */

import React, {Component} from 'react';

import {layerClient, LayerReactComponents, Layer} from '../../get-layer';
import {animateScroll as scroll, Events} from "react-scroll";
import from from "array.from";
import {translate} from 'react-i18next';

import logoLarge from '../../images/xircleLogo@3x.png';

import Suggestions from '../Suggestions/index';
import BigButtonBar from '../BigButtonBar/index';
import ContentPanel from '../ContentPanel/index';
import Footer from '../Footer/index';
import ContentEdit from '../ContentEdit';

import './styles.less';
import HeaderContainer from '../../containers/HeaderContainer';
import HeaderOperatorContainer from '../../containers/HeaderOperatorContainer';
import MenuContainer from '../../containers/MenuContainer';
import config from '../../config.json'
import IconButton from '../../material-components/IconButton';

import Filter from '../Filter/index';
import {isOperator} from "../../utils/user";
import { getSuggestionsByAutocomplete, getSuggestionsByTags, getAllTags } from '../../utils/api';
import { debounce, isMobileDevice, isTabletDevice } from '../../utils/common';
import { SUGGEST_MODES } from '../../const/suggest-modes';
import { NAVIGATION_TYPE } from '../../const/navigation-type';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _random from 'lodash/random';

const {uuid} = Layer.Utils;

// Extract the Layer XDK UI Components that are to be used in this Project
const {
  Notifier,
  ConversationList,
} = LayerReactComponents;

type Props = {
  history: any,
  location: any,
  match: any,
  firstTime: boolean,
  onLogout: Function,
  t: Function,
  userId: string,
};

type State = {
  conversation: any,
  conversationName: string,
  isLoaded: boolean,
  conversationId: string,
  showEditConversationDialog: boolean,
  isSuggestionsShown: boolean,
  helpList: Array<any>,
  shortCutList: Array<any>,
  suggestionList: Array<string>,
  editConversationId: string,
  sortBy: string
};

class QuestionPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      conversationId: '',
      conversation: null,
      conversationName: '',
      isLoaded: false,
      showEditConversationDialog: false,
      isSuggestionsShown: false,
      helpList: [],
      shortCutList: [],
      suggestionList: [],
      tags: [],
      editConversationId: '',
      text: "",
      bigButtonBarVisible: isMobileDevice(),
      sortBy: ''
    };
    this.textareaRef = React.createRef();
    // always displaying
    this.mainMessage = React.createRef();
    // displaying only on first enter
    this.additionalMessage = React.createRef();
    // for dividing this.mainMessage and this.additionalMessage
    this.contentEditable = React.createRef();
    this.welcome = React.createRef();
    this.header = React.createRef();
    this.bigButtonBar = React.createRef();
    this.question = React.createRef();
    this.lineBreak = React.createRef();
    this.headerBackground = React.createRef();
    this.scrollPane = React.createRef();
    this.leftPanelFixed = false;
    this.suggestionsElem = null;
    this.conversationList = null;
    //
    this.headerAnimation = null;
    this.headerDisplayAnimation = null;
    this.questionMoveAnimation = null;
    this.questionHeightAnimation = null;
    this.questionFixedAnimation = null;
    this.recognizedTags = [];
    this.autoCompleteSearchLength = 0;
    this.isBlurred = false;
    this.isMobileModeActive = false;
  }

  t = this.props.t;

  // Handle reauthentication
  componentDidMount() {
    const welcomeMessage = this.generateRandomWelcomeMessage(this.props.firstTime);
    this.setState({ welcomeMessage });

    if (isMobileDevice()) {
      this.updateQuestionHeight(welcomeMessage);
    }

    const { shortCutList, helpList } = this.prepareBigButtonBar();
    this.setState({ shortCutList, helpList });

    const conversationName = _get(this.props.location, 'state.detail.conversationName');

    if (conversationName) {
      this.setState({ text: conversationName }, () => this.focusOnTextAreaDelayed());
    }

    this.leftPanel = document.querySelector('.left-panel');
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.onBlur);
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('click', this.onClick);
    // should happen always to corellate w/ onBlur
    getAllTags().then(({ data: tags }) => this.setState({ tags }));
  }

  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.onBlur);
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('click', this.onClick);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.location.pathname === nextProps.location.pathname;
  }

  updateQuestionHeight = (welcomeMessage) => {
    const [tinyScreenWidth, smallScreenWidth, mediumScreenWidth] = [321, 376, 768];

    if (document.documentElement.clientWidth < mediumScreenWidth) {
      const questionLengthLineBreaks = {
        [tinyScreenWidth]: 34,
        [smallScreenWidth]: 38,
        [mediumScreenWidth]: 42,
      };

      const screenWidth = [tinyScreenWidth, smallScreenWidth, mediumScreenWidth]
        .find((width) => document.documentElement.clientWidth < width);

      const questionLengthLineBreak = questionLengthLineBreaks[screenWidth];

      if (questionLengthLineBreak < welcomeMessage.length) {
        this.question && this.question.classList.add('question--first-time');
        this.scrollPane && this.scrollPane.current.classList.add('scrollPane--first-time');
      }
    }
  };

  prepareBigButtonBar = () => {
    const t = this.t;
    const AMOUNT_OF_BUTTONS_AND_LISTS = 9;

    const { shortCutList, helpList } =  Array
      .apply(null, { length: AMOUNT_OF_BUTTONS_AND_LISTS })
      .reduce(
        ({ shortCutList, helpList }, _value, index ) => {
          const itemNum = index + 1;
          return {
            shortCutList: t(`HELP_BUTTON_${itemNum}_QUESTION`) !==  `HELP_BUTTON_${itemNum}_QUESTION`
              ? shortCutList.concat({
                text: t(`HELP_BUTTON_${itemNum}_SHORT_TEXT`),
                className: 'short-cut',
                onClick: () => this.clickShortCut(
                  t(`HELP_BUTTON_${itemNum}_QUESTION`) || t(`HELP_BUTTON_${itemNum}_SHORT_TEXT`)
                ),
              })
              : shortCutList,
            helpList: t(`HELP_LIST_${itemNum}_QUESTION`) !== `HELP_LIST_${itemNum}_QUESTION`
              ? helpList.concat({
                question: t(`HELP_LIST_${itemNum}_QUESTION`),
                text: t(`HELP_LIST_${itemNum}_SHORT_TEXT`) || t(`HELP_LIST_${itemNum}_QUESTION`),
              })
              : helpList,
          }
        },
        {
          shortCutList: [],
          helpList: [],
        }
      );

      const shortCutListWithHelpList = [
        ...shortCutList,
        {
          text: '?',
          className: 'short-cut',
          onClick: this.showSuggestions,
        },
      ];

    return {
      shortCutList: shortCutListWithHelpList,
      helpList,
    };
  };

  /**
   * Now that we have a Conversation, setup event handlers on it to detect when its loaded,
   * and when its `metadata` property has changed; metadata changes typically mean a Conversation Name change
   * that needs to be rerendered.
   *
   * Note: This works off of `this.conversation` as its input, and this value may be `null`
   */
  setupConversation(con) {
    const conversation = con || this.state.conversation;

    // If the conversation is still loading, wait for it to finish, and then set isLoaded to true
    if (conversation && conversation.isLoading) {
      conversation.once("conversations:loaded", () => {
        this.setState({
          isLoaded: true
        });
      });
    }

    // Setup our inital state
    this.setState({
      conversationId: conversation ? uuid(conversation.id) : "",
      conversation,
      isLoaded: conversation && !conversation.isLoading
    });
  }

  postSuggestion = (text) => {
    const prevText = this.state.text;
    this.setState({ text }, () => this.hideSuggestions(prevText));
  };

  handleSuggestionsClick = () => {
    this.hideSuggestions();
  };

  handleCloseSuggestionsClick = () => {
    this.hideSuggestions();
  };

  postQuestion = () => {
    if (layerClient.isReady) {
      this.createConversationAndSendMessageToBot(this.state.text);
    } else {
      layerClient.once('ready', () => {
        this.createConversationAndSendMessageToBot(this.state.text);
      })
    }
  };

  createConversationAndSendMessageToBot = (text) => {
    const bot = layerClient.getIdentity(
      config.bot_id,
      true
    );
    if (bot.isLoading) {
      bot.on('identities:loaded', () => this._createConversationAndSendMessage(text, bot));
    } else {
      this._createConversationAndSendMessage(text, bot);
    }
  };

  _createConversationAndSendMessage = (text, bot) => {
    this.setState({
      conversation: layerClient.createConversation({
        participants: [layerClient.user, bot],
        distinct: false,
        metadata: { conversationName: text }
      })
    }, () => {
      // but now
      const XirclesQuestionModel = Layer.Core.Client.getMessageTypeModelClass('XirclesQuestionModel');
      const model = new XirclesQuestionModel({text});
      model.send({conversation: this.state.conversation});
      this.onCreateConversation(this.state.conversation, true);
    });
  };

  /**
   * Whenever a conversation is selected in the Conversation List, navigate to that Conversation.
   * This will cause `render` to be called, and the new Conversation ID to be passed to the Conversation View.
   */
  onConversationSelected(evt: CustomEvent) {
    if (!evt.detail.item || this.state.startState) return;
    let component = evt.target.querySelector(`[layer-item-id = "conversations_${evt.detail.item.id.replace('layer:///conversations/', '')}"]`);

    if (component !== null) {
      component.nodes.innerNode.style.transform = 'perspective(500px) matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)';
      component.setAttribute('style', `top: ${component.getBoundingClientRect().top}px;
     left: ${component.getBoundingClientRect().left}px; max-width: none;
     `);
      component.style.position = 'fixed';
      setTimeout(() => {
        component.setAttribute('style', 'position: fixed; top: 0; left: 0; max-width: none;');
      }, 0)
    }

    const conversation = evt.detail.item.toObject();
    this.props.history.push(`/questions/${uuid(conversation.id)}`);
  }

  showSuggestionsAnimation = () => {
    setTimeout(() => {
      if (this.suggestionsElem) {
        this.suggestionsElem.getElementsByTagName('header')[0].style.transitionDelay = '';
        const ul = this.suggestionsElem.getElementsByTagName('ul')[0];
        const height = this.suggestionsElem.offsetHeight - 20;
        this.suggestionsElem.style.height = 0;
        from(ul.getElementsByTagName('li')).forEach((li, i) => li.style.transitionDelay = i * 100 + 300 + 'ms');
        this.suggestionsElem.className = this.suggestionsElem.className + ' move';
        this.headerBackground.current.setAttribute('style', 'z-index: 1');
        this.suggestionsElem.style.transition = 'opacity 200ms ease-in-out, height 200ms 200ms ease-in-out';

        setTimeout(() => {
          this.suggestionsElem.style.height = isMobileDevice()
            ? window.innerHeight - 20 - 5 - 5 + 'px'
            : height + 'px';

          setTimeout(() => {
            if (this.suggestionsElem) {
              this.suggestionsElem.className = this.suggestionsElem.className + ' open';
            }
          }, 1000);
        }, 10);
      }
    }, 400);
  };

  showSuggestions = () => {
    setTimeout(() => this.suggestionsElem && this.suggestionsElem.classList.toggle('suggestions--fixed', isMobileDevice()), 0);

    this.setState({ isSuggestionsShown: true }, () => {
      this.showSuggestionsAnimation();

      if (this.state.text) {
        this.focusOnTextAreaDelayed();
      }
    });
  };

  applyStylesToHideSuggestions = () => {
    if (this.suggestionsElem && this.suggestionsElem.classList.contains('open')) {
      this.suggestionsElem.classList.remove('open');
      this.suggestionsElem.classList.remove('move');
    }
  };

  hideSuggestions = (prevText) => {
    this.setState({ isSuggestionsShown: false }, () => {
      this.applyStylesToHideSuggestions();

      if (this.state.text) {
        this.focusOnTextAreaDelayed();
      }

      if (_isEmpty(prevText) && !_isEmpty(this.state.text)) {
        this.focusOnTextArea();
      }
    });
  };

  hideSuggestionsWithoutFocusSetting = () => {
    this.setState({ isSuggestionsShown: false }, () => this.applyStylesToHideSuggestions());
  };

  handleAnimationQuestionInput = () => {
    this.header.current && this.header.current.setAttribute('style', 'opacity: 0;');
    this.welcome.current && this.welcome.current.setAttribute('style', 'opacity: 0;');

    this.bigButtonBar.current && this.bigButtonBar.current.setAttribute('style', 'display: none;');
    this.question && this.question.setAttribute('style', 'margin-top: 0px;');
    this.question.classList.add('question-mobile-view');

    this.questionMoveAnimation = setTimeout(() => {
      const headerHeight = this.header.current.offsetHeight;
      this.header.current.classList.add('question-mobile-view-transition-header');
      this.header.current.setAttribute('style', `height: ${headerHeight}px; min-height: ${headerHeight}px; opacity: 0;`);

      const welcomeHeight = this.welcome.current.offsetHeight;
      this.welcome.current.classList.add('question-mobile-view-transition');
      this.welcome.current.setAttribute('style', `height: ${welcomeHeight}px; opacity: 0;`);

      this.headerAnimation = setTimeout(() => {
        this.header.current.setAttribute('style', 'height: 0px; min-height: 0px; opacity: 0;');
        this.welcome.current.setAttribute('style', 'height: 0px; opacity: 0;');
      }, 0);

      this.headerDisplayAnimation = setTimeout(() => {
        this.header.current.setAttribute('style', 'display: none;');
      }, 400);

      this.question.classList.add('question-mobile-view-transition');

      this.questionHeightAnimation = setTimeout(() => {
        const top = window.pageYOffset;
        this.question.setAttribute('style', `margin-top: ${top}px; height: 110px`);
      }, 0);

      this.questionFixedAnimation = setTimeout(() => {
        this.textareaRef.current.removeAttribute('style');
        this.question.classList.remove('question-mobile-view-transition');
        this.question.classList.add('question-mobile-view-relative');

        window.scrollTo(0, 0);
      }, 400);
    }, 200);
  };

  handleAnimationQuestionInputQuestionFixed = () => {
    this.bigButtonBar.current && this.bigButtonBar.current.setAttribute('style', 'display: none;');

    this.header.current && this.header.current.setAttribute('style', 'opacity: 0;');
    this.welcome.current && this.welcome.current.setAttribute('style', 'opacity: 0;');

    this.contentEditable.current && this.contentEditable.current.setAttribute('style', 'top: 69px;');
    this.question && this.question.setAttribute('style', 'margin-top: 0px;');
    this.question.classList.add('question-mobile-view');

    this.questionMoveAnimation = setTimeout(() => {
      const headerHeight = this.header.current.offsetHeight;
      this.header.current.classList.add('question-mobile-view-transition-header');
      this.header.current.setAttribute('style', `height: ${headerHeight}px; min-height: ${headerHeight}px; opacity: 0;`);

      const welcomeHeight = this.welcome.current.offsetHeight;
      this.welcome.current.classList.add('question-mobile-view-transition');
      this.contentEditable.current.classList.add('question-mobile-view-transition');
      this.welcome.current.setAttribute('style', `height: ${welcomeHeight}px; opacity: 0;`);

      this.headerAnimation = setTimeout(() => {
        this.header.current.setAttribute('style', 'height: 0px; min-height: 0px; opacity: 0;');
        this.welcome.current.setAttribute('style', 'height: 0px; opacity: 0;');
        this.contentEditable.current.setAttribute('style', 'top: 0px;');
      }, 0);

      this.headerDisplayAnimation = setTimeout(() => {
        this.header.current.setAttribute('style', 'display: none;');
      }, 400);

      this.question.classList.add('question-mobile-view-hide-send-button');
      this.question.classList.add('question-mobile-view-transition');

      this.questionHeightAnimation = setTimeout(() => {
        const top = window.pageYOffset;
        this.question.setAttribute('style', `margin-top: ${top}px; height: 110px`);
      }, 0);

      this.questionFixedAnimation = setTimeout(() => {
        this.question.classList.remove('question-mobile-view-transition');
        this.question.classList.add('question-mobile-view-fixed');

        const questionHeight = this.question.offsetHeight;
        this.scrollPane.current.setAttribute('style', `padding-top: ${questionHeight}px`);

        window.scrollTo(0, 0);
        setTimeout(() => this.textareaRef.current.removeAttribute('style'), 100);
      }, 400);
    }, 200);
  };

  handleAnimationQuestionInputFixed = () => {
    this.bigButtonBar.current && this.bigButtonBar.current.setAttribute('style', 'display: none;');

    this.header.current && this.header.current.setAttribute('style', 'opacity: 0;');
    this.welcome.current && this.welcome.current.setAttribute('style', 'opacity: 0;');

    this.contentEditable.current && this.contentEditable.current.setAttribute('style', 'top: 69px;');
    this.question && this.question.setAttribute('style', 'top: -126px;');
    this.question.classList.add('question-mobile-view');

    this.questionMoveAnimation = setTimeout(() => {
      const headerHeight = this.header.current.offsetHeight;
      this.header.current.classList.add('question-mobile-view-transition-header');
      this.header.current.setAttribute('style', `height: ${headerHeight}px; min-height: ${headerHeight}px; opacity: 0;`);

      const welcomeHeight = this.welcome.current.offsetHeight;
      this.welcome.current.classList.add('question-mobile-view-transition');
      this.contentEditable.current.classList.add('question-mobile-view-transition');
      this.welcome.current.setAttribute('style', `height: ${welcomeHeight}px; opacity: 0;`);

      this.headerAnimation = setTimeout(() => {
        this.header.current.setAttribute('style', 'height: 0px; min-height: 0px; opacity: 0;');
        this.welcome.current.setAttribute('style', 'height: 0px; opacity: 0;');
        this.contentEditable.current.setAttribute('style', 'top: 0px;');
      }, 0);

      this.headerDisplayAnimation = setTimeout(() => {
        this.header.current.setAttribute('style', 'display: none;');
      }, 400);

      this.question.classList.add('question-mobile-view-hide-send-button');
      this.question.classList.add('question-mobile-view-transition');

      this.questionHeightAnimation = setTimeout(() => {
        this.question.setAttribute('style', `top: 0px; height: 110px`);

        setTimeout(() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }, 0);

        this.scrollPane.current.setAttribute('style', `padding-top: 110px`);
      }, 0);

      this.questionFixedAnimation = setTimeout(() => {
        this.textareaRef.current.removeAttribute('style');
      }, 400);
    }, 200);
  };

  handleContentEditFocusOnMobile = () => {
    if (!this.isBlurred && !this.isMobileModeActive) {
      this.isMobileModeActive = true;
      this.clearAnimationTimers();

      if (!this.leftPanel.classList.contains('questionFixed') && !this.leftPanel.classList.contains('fixed')) {
        this.handleAnimationQuestionInput();
      }

      if (this.leftPanel.classList.contains('questionFixed') && !this.leftPanel.classList.contains('fixed')) {
        this.handleAnimationQuestionInputQuestionFixed();
      }

      if (this.leftPanel.classList.contains('fixed')) {
        this.handleAnimationQuestionInputFixed();
      }

      const [layerConversationList] = document.getElementsByTagName('layer-conversation-list');
      layerConversationList.classList.add('layer-conversation-list--disabled');

      this.scrollPane.current.addEventListener('click', this.handleContentEditBlur);
    } else {
      this.textareaRef.current.removeAttribute('style');
    }
  };

  clearAnimationTimers = () => {
    clearTimeout(this.questionMoveAnimation);
    clearTimeout(this.headerAnimation);
    clearTimeout(this.headerDisplayAnimation);
    clearTimeout(this.questionHeightAnimation);
    clearTimeout(this.questionFixedAnimation);
  };

  isDocumentHidden = () => document.hidden || document.webkitHidden;

  handleContentEditBlur = () => {
    if (!this.isDocumentHidden()) {
      this.isMobileModeActive = false;

      const { text } = this.state;
      this.setState({ text: text.trim() });

      this.clearAnimationTimers();

      const [layerConversationList] = document.getElementsByTagName('layer-conversation-list');
      layerConversationList.classList.remove('layer-conversation-list--disabled');

      this.scrollPane.current.removeEventListener('click', this.handleContentEditBlur);

      this.lineBreak.current && this.lineBreak.current.removeAttribute('style');
      this.additionalMessage.current && this.additionalMessage.current.removeAttribute('style');
      this.leftPanel.classList.remove('questionFixed');

      this.leftPanelFixed = false;
      this.leftPanel.classList.remove('fixed');

      this.question.removeAttribute('style');
      this.question.classList.remove('question-mobile-view-hide-send-button');
      this.question.classList.remove('question-mobile-view-transition');
      this.question.classList.remove('question-mobile-view-relative');
      this.question.classList.remove('question-mobile-view-fixed');
      this.question.classList.remove('question-mobile-view');

      this.contentEditable.current.removeAttribute('style');
      this.contentEditable.current.classList.remove('question-mobile-view-transition');

      this.header.current && this.header.current.removeAttribute('style');
      this.header.current && this.header.current.classList.remove('question-mobile-view-transition-header');
      this.welcome.current && this.welcome.current.removeAttribute('style');
      this.welcome.current && this.welcome.current.classList.remove('question-mobile-view-transition');

      this.scrollPane.current && this.scrollPane.current.removeAttribute('style');
      this.bigButtonBar.current && this.bigButtonBar.current.removeAttribute('style');

      window.scrollTo(0, 0);
    }
  };

  handleContentEditFocus = () => {
    if (isMobileDevice() && !isTabletDevice()) {
      this.handleContentEditFocusOnMobile();
    } else {
      this.setState({ bigButtonBarVisible: true });
    }
  };

  /**
   * Whenever properties change, determine if the Conversation ID has changed, and if so:
   *
   * * Unsubscribe to all events from the prior conversation
   * * Call setupConversation() with the new conversation
   */
  componentWillReceiveProps(props: Props) {
    if (
      !props.match.params.welcomeId &&
      this.props.match.params.conversationId !== props.match.params.conversationId
    ) {
      const conversationId = props.match.params.conversationId;
      const newConversation = conversationId
        ? layerClient.getConversation(conversationId)
        : null;
      if (this.state.conversation) this.state.conversation.off(null, null, this);
      this.setState({conversation: newConversation});
      this.setupConversation();
    }
  }

  changeSortBy = (value: string) => {
    const { sortBy } = this.state
    value !== sortBy && this.setState({ sortBy: value })
  }

  filterConversations = (conversation: any) => {
    const { sortBy } = this.state;
    if(!sortBy.length) {
      return true
    } else if (sortBy === 'pending' || sortBy === 'reported' || sortBy === 'cleared' ) {
      return conversation && conversation.metadata.pv_status === sortBy;
    } else {
      return conversation && conversation.metadata.xircles_status === sortBy;
    }
  };

  /**
   * Once the EditConversationDialog reports back that the Conversation has been created,
   * update our state and our URL
   */
  onCreateConversation = (conversation: any, redirect: boolean = true) => {
    this.setState({
      conversationId: uuid(conversation.id),
      showEditConversationDialog: false,
      editConversationId: ""
    });
    redirect && this.props.history.push(`/questions/${uuid(conversation.id)}`);
  };

  /**
   * Logout of the client and navigate to the Login page
   */
  logout = (e) => {
    e.preventDefault();
    const {onLogout} = this.props;
    onLogout();
  };

  /**
   * When user clicks on a Toast or Desktop notification, update the selected Conversation
   */
  onNotificationClick = (event: any) => {
    this.props.history.push(
      `/questions/${uuid(event.detail.item.conversationId)}`
    );
  };

  /**
   * When a new message arrives, notifiy the user if the Window/tab is in the background,
   * or the ConversationView is showing a different Conversation.
   */
  onMessageNotification = (event: any) => {
    if (
      event.detail.item.conversationId === this.state.conversationId &&
      !event.detail.isBackground
    ) {
      event.preventDefault();
    }
  };

  isTextSelectionMouseEvent = () => {
    const { type } = window.getSelection();
    return type === 'Range';
  };

  onClick = (e) => {
    const id = _get(e, 'target.id', undefined);
    const textSelectionEvent = this.isTextSelectionMouseEvent();

    if (!id.includes('shortcut') && !textSelectionEvent && !isMobileDevice()) {
      this.setState({ bigButtonBarVisible: false });
    }
  };

  onFocus = () => {
    if (this.isBlurred) {
      setTimeout(() => {
        this.textareaRef.current.removeAttribute('style');
        this.isBlurred = false;
      }, 0);
    }
  };

  onBlur = () => {
    if (this.isDocumentHidden()) {
      this.isBlurred = true;
    }
  };

  onScroll = () => {
    this.hideSuggestionsWithoutFocusSetting();

    if (this.question.classList && !this.question.classList.contains('question-mobile-view')) {
      const top = window.pageYOffset;
      const largeScreenWidth = 1035;
      const mediumScreenWidth = 768;
      // the first message has 2 lines when the screen width is too small
      const firstMessageTwoLinesScreenWidth = 434;
      // if (top > 100) {
      //   if (!this.textAreaHadFocus) {
      //     const ta = this.leftPanel.querySelector('textarea:focus');
      //     if (ta) {
      //       ta.blur();
      //       this.textAreaHadFocus = true;
      //     }
      //   }
      // } else if (this.textAreaHadFocus) {
      //   const ta = this.leftPanel.querySelector('textarea:not(:focus)');
      //   ta.focus();
      //   this.textAreaHadFocus = false;
      // }
      let questionFixed, fixedTop;
      if (document.documentElement.clientWidth >= largeScreenWidth) {
        questionFixed = 128;
        fixedTop = 255;
      } else if (document.documentElement.clientWidth >= mediumScreenWidth) {
        questionFixed = 108;
        fixedTop = 230;
      } else {
        questionFixed = 104;
        fixedTop = 230;
      }
      // to avoid "jumping", we have to consider the first chat bubble when user visits the page the first time
      if (this.props.firstTime) {
        const firstChatBubbleHeight = document.documentElement.clientWidth <= firstMessageTwoLinesScreenWidth ? 51 : 31;
        const chatBubbleMargin = 5;
        questionFixed += firstChatBubbleHeight + chatBubbleMargin;
      }

      if (top >= questionFixed) {
        this.mainMessage.current && this.mainMessage.current.removeAttribute('style');
        this.lineBreak.current && this.lineBreak.current.setAttribute('style', 'display: none;');
        this.additionalMessage.current && this.additionalMessage.current.setAttribute('style', 'display: none;');
        this.leftPanel.classList.add('questionFixed');
      } else {
        this.mainMessage.current && this.mainMessage.current.setAttribute('style', 'margin-top: 5px');
        this.lineBreak.current && this.lineBreak.current.removeAttribute('style');
        this.additionalMessage.current && this.additionalMessage.current.removeAttribute('style');
        this.leftPanel.classList.remove('questionFixed');
      }
      if (top >= fixedTop && !this.leftPanelFixed) {
        this.leftPanelFixed = true;
        this.leftPanel.classList.toggle('fixed');

      } else if (top < fixedTop && this.leftPanelFixed) {
        this.leftPanelFixed = false;
        this.leftPanel.classList.toggle('fixed');
      }
    }
  };

  prepareSuggestions = ({ data: { suggestions } }) => {
    const preparedSuggestions = suggestions.reduce(
      (acc, { title }) => acc.indexOf(title) === -1 ? acc.concat(title) : acc,
      [],
    );

    this.setState({ suggestionList: preparedSuggestions });
  };

  handleSuggestionsByAutocomplete(title) {
    const minLengthStartSearch = 3;
    const titleLength = title ? title.trim().length : 0;
    const isSearch = title && title.length >= minLengthStartSearch && this.autoCompleteSearchLength !== titleLength;

    this.autoCompleteSearchLength = titleLength;
    return isSearch && getSuggestionsByAutocomplete(title).then(this.prepareSuggestions);
  };

  handleSuggestionsByTag(title) {
    const tags = this.state.tags.filter(tag => title.toLowerCase().indexOf(tag.toLowerCase()) >= 0);
    const isSearchSuggestionsByTag = !_isEmpty(tags) && this.recognizedTags.length !== tags.length;

    this.recognizedTags = [...tags];
    return isSearchSuggestionsByTag && getSuggestionsByTags(tags).then(this.prepareSuggestions);
  };

  handleGetSuggestions(title) {
    const { suggest_mode } = config;

    return suggest_mode === SUGGEST_MODES.AUTOCOMPLETE
      ? this.handleSuggestionsByAutocomplete(title)
      : this.handleSuggestionsByTag(title);
  };

  handleChange = (event: any) => {
    const value = _get(event, 'target.value', '');
    debounce(this.handleGetSuggestions(value), 1000);
    this.setState({text: value.slice(0, 400)});

    const minLengthForSearch = 3;

    if (value.length < minLengthForSearch) {
      this.setState({suggestionList: []});
    }
  };

  clickShortCut = (text) => {
    this.focusOnTextArea();

    if (isMobileDevice() && !isTabletDevice()) {
      if (!_isEmpty(this.state.text)) {
        this.setState({ text: ''});
      }

      setTimeout(() => this.setState({ text }), 600);
    } else {
      this.setState({ text });
    }
  };

  focusOnTextArea = () => this.textareaRef.current.focus();

  focusOnTextAreaDelayed = () => setTimeout(() => {
    const el = document.getElementById("editable");
    const [childNodes] = el.childNodes;

    const range = document.createRange();
    const selection = window.getSelection();

    range.setStart(childNodes, childNodes.childNodes.length);
    range.collapse(true);

    selection.removeAllRanges();
    selection.addRange(range);

    el.focus();
  }, 0);

  handleContentEditClick = (e) => {
    this.setState({ bigButtonBarVisible: true });
    // to avoid refocusing
    e.stopPropagation();
  };

  handleKeyDown = (e) => {
    if (this.validateSubmit()) {
      if (e.keyCode === 13 && (e.ctrlKey || e.metaKey)) {
        this.postQuestion();
      }
    }
  };

  validateSubmit = () => {
    let lineWithoutSpaces = this.state.text.replace(/\s/g, '');
    return lineWithoutSpaces.length > 2;
  };

  checkIsPageReloaded = () => {
    const navigationType = _get(window, 'performance.navigation.type');
    return navigationType === NAVIGATION_TYPE.TYPE_RELOAD;
  };

  generateRandomWelcomeMessage = (isFirstTime) => {
    const t = this.t;
    const numberOfQuestions = 5;

    const index = _random(1, numberOfQuestions);
    const randomQuestion = `QUESTION_WELCOME_LONG_${index}`;
    const defaultQuestion = 'QUESTION_WELCOME_LONG';

    const isPageReloaded = this.checkIsPageReloaded();

    const selectedQuestion = isFirstTime && !isPageReloaded
      ? defaultQuestion
      : randomQuestion;

    return t(selectedQuestion);
  };

  renderLeftPanel() {
    const t = this.t;
    // const isOperator = this.state.isOperator
    // props for Menu component
    const buttonAccountList = [
      {
        id: 'account-info-button',
        text: `${t('MENU_ABOUT_XIRCLES')}`,
        href: 'about-xircles',
        iconClassName: 'material-icons',
        iconName: 'info',
        className: 'menu-account-button',
      },
      {
        id: 'account-logout-button',
        text: `${t('MENU_LOGOUT')}`,
        iconClassName: 'material-icons',
        iconName: 'power_settings_new',
        onClick: this.logout,
        className: 'menu-account-button',
      },
    ];

    // props for Menu component
    const buttonContentList = [
      {
        text: `${t('MENU_IMPRINT')}`,
        href: 'imprint',
        className: 'menu-content-button'
      },
      {
        text: `${t('MENU_DATA_PRIVACY_STATEMENT')}`,
        href: 'data-privacy',
        className: 'menu-content-button'
      },
      {
        text: `${t('MENU_TERMS_AND_CONDITIONS')}`,
        href: 'terms',
        className: 'menu-content-button'
      },
    ];

    return (
      !isOperator(Layer.client.user) ?
        <div className="left-panel">
          <HeaderContainer
            ref={this.header}
            titleProps={<h2>{t('COMMON_HEADER_PRODUCT_NAME')}</h2>}
            onLogout={() => this.props.history.push()}
          />

          <MenuContainer
            buttonAccountList={buttonAccountList}
            buttonContentList={buttonContentList}
          />

          <div
            className="header-background"
            ref={this.headerBackground}
            style={{
              boxShadow: '0 4px 4px -1px rgba(0, 0, 0, 0.19)',
              zIndex: 1,
            }}>
            <div className="question" ref={(r) => {
              r && (this.suggestionsElem = r.getElementsByClassName('suggestions')[0]);
              r && setTimeout(() => r.classList.add('open'));
              r && (this.question = r);
            }}>
              <div ref={this.welcome} className="welcome">
                <div className="logo"><img src={logoLarge} width="24" height="24" alt="Logo"/></div>
                <div className="text" ref={this.additionalMessage}>{this.state.welcomeMessage}</div>
                <br ref={this.lineBreak}/>
                <div ref={this.mainMessage} className="text">{t('QUESTION_WELCOME_SHORT')}</div>
              </div>

              <ContentEdit
                contentEditableRef={this.contentEditable}
                tags={this.state.tags}
                suggestions={this.state.suggestionList}
                value={this.state.text}
                onChange={this.handleChange}
                onFocus={this.handleContentEditFocus}
                onClick={this.handleContentEditClick}
                onBlur={this.handleContentEditBlur}
                onKeyDown={this.handleKeyDown}
                className="questionInput"
                placeholder={`${t('QUESTION_PLACEHOLDER')}`}
                innerRef={this.textareaRef}
                onSuggestionSelect={this.postSuggestion}
              >
                {this.state.isSuggestionsShown && <Suggestions
                  isSuggestionsShown={this.state.isSuggestionsShown}
                  headerText={`${t('SUGGESTIONS_HEADER_TEXT')}`}
                  helpList={this.state.helpList}
                  onSuggestionsClick={this.handleSuggestionsClick}
                  onCloseSuggestionsClick={this.handleCloseSuggestionsClick}
                  suggestionMouseDownHandler={this.postSuggestion}
                />}
              </ContentEdit>

              <BigButtonBar
                innerRef={this.bigButtonBar}
                send={this.postQuestion}
                buttons={this.state.shortCutList}
                showSubmit={this.validateSubmit()}
                className={this.state.bigButtonBarVisible ? 'bigButtonBarVisible' : ''}
              />
              <IconButton
                color="primary"
                className={`fixed-send ${this.validateSubmit() ? 'visible' : ''}`}
              >
                <i className="xircles-icons icon-send" onMouseDown={this.postQuestion} />
              </IconButton>
            </div>
          </div>

          <div ref={this.scrollPane} className="scrollPane">
            <ConversationList
              key={this.state.sortBy} // force update after sortBy change
              filter={this.filterConversations}
              onConversationSelected={e => this.onConversationSelected(e)}
            />
            <ContentPanel/>
            <Footer/>

          </div>
        </div> :

        <div className="left-panel">
          <HeaderOperatorContainer
            titleProps={<h2>{t('COMMON_HEADER_PRODUCT_NAME')}</h2>}
            onLogout={() => this.props.history.push()}
          />

          <MenuContainer
            buttonAccountList={buttonAccountList}
            buttonContentList={buttonContentList}
          />

          <Filter
            changeSortBy={this.changeSortBy}
          />

          <div className="scrollPane">
            <ConversationList
              key={this.state.sortBy} // force update after sortBy change
              filter={this.filterConversations}
              onConversationSelected={e => this.onConversationSelected(e)}
            />
          </div>
        </div>
    );
  }

  render() {
    // Setup the CSS Classes for the root element
    let rootClasses = 'questionPage';
    if (this.state.conversationId || this.state.showEditConversationDialog) rootClasses += ' has-conversation';

    return <div className={rootClasses}>
      <Notifier
        notifyInForeground="toast"
        onMessageNotification={this.onMessageNotification}
        onNotificationClick={this.onNotificationClick}/>
      {this.renderLeftPanel()}
    </div>
  }
}

export default translate()(QuestionPage);

// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

type Props = {
  fullName: string,
  onAcknowledge: Function,
};

export default function DocCheckWelcomeModalFragment({
   fullName,
   onAcknowledge,
 }: Props) {
  return (
    <React.Fragment>
      <Box mt={2} textAlign="center" className={'doccheck-welcome'}>
        <Box>Hello {fullName}!</Box>
        <Box>You are logged in at DocCheck.</Box>
      </Box>
      <Box mt={2} textAlign="center">
        <Button data-keystroke outlined style={{ width: '100%' }} onClick={onAcknowledge}>Continue</Button>
      </Box>
      <Box mt={7.5} textAlign="center">
        <span>DocCheck</span>{' '}
        <a style={{ color: '#009B77' }} href="http://info.doccheck.com/com/privacy/" target="blank">Privacy</a>
      </Box>
    </React.Fragment>
  );
}

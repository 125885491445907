import {isBot} from '../utils/user';

const MessageItemReceivedMixin = {
  methods: {
    onAfterCreate() {
      const sender = this.properties.item.sender;
      if (isBot(sender)) {
        this.nodes.sender.classList.add('bot');
      }
    },
    onRender() {
      ( this.getElementsByClassName('layer-message-type-view__operator').length ||
        this.getElementsByClassName('xircles-content-card-message-view-swipe__operator').length ||
        this.getElementsByClassName('xircles-content-card-message-view__operator').length) &&
      this.classList.add('layer-message-status-delivered-from-operator')
    },
    onRerender: function() {
       this.nodes.date.innerHTML = '';
    }
  }
};

export default MessageItemReceivedMixin;

import React from 'react';
import { Route } from 'react-router-dom';
import { translate } from 'react-i18next';
import _camelCase from 'lodash/camelCase';
import _values from 'lodash/values';
import _get from 'lodash/get';

import OperatorModal from '../components/Modal/OperatorModal';
import { forwardMedinfo, reportPV, updateTicket } from '../utils/api';

type Props = {
  t: Function,
};

type State = {
  operatorError: any,
  operatorState: 'loading' | 'loaded' | 'error',
};

export const OPERATOR_MODAL_PATHS = {
  CLEAR_PV: '/questions/:conversationId/clear-pv',
  FORWARD_CONVERSATION_TO_MEDINFO: '/questions/:conversationId/forward-to-medinfo',
  DELIVER_ANSWER: '/questions/:conversationId/deliver-answer',
  CLOSE_REQUEST: '/questions/:conversationId/close-request',
  DELETE_REQUEST: '/questions/:conversationId/delete-request',
  PROVIDE_CONTENT: '/questions/:conversationId/provide-content'
};

export const OPERATOR_MODAL_PATHS_ARRAY = _values(OPERATOR_MODAL_PATHS);

class OperatorModalContainer extends React.Component<Props, State> {
  state = {
    operatorError: null,
    operatorState: null,
  };

  onHttpEvent = (apiCall, params) => {
    this.setState({ operatorState: 'loading', operatorError: null });
    return apiCall(params)
      .then(data => {
        this.setState({ operatorState: 'loaded' });
        return data;
      })
      .catch(operatorError => {
        const errorMessage = _get(operatorError, 'response.data.error.id', 'SOMETHING_WENT_WRONG');

        this.setState({
          operatorState: 'error',
          operatorError: this.props.t('ERROR_' + errorMessage),
        });

        throw operatorError;
      });
  };

  isForwardToMedinfoUrl = (url) => /\/questions\/.{36}\/(forward-to-medinfo)/.exec(url);
  isClearPVUrl = (url) => /\/questions\/.{36}\/(clear-pv)/.exec(url);
  isDeliverAnswer = (url) => /\/questions\/.{36}\/(deliver-answer)/.exec(url);
  isCloseRequestUrl = (url) => /\/questions\/.{36}\/(close-request)/.exec(url);
  isDeleteRequestUrl = (url) => /\/questions\/.{36}\/(delete-request)/.exec(url);
  isProvideContentUrl = (url) => /\/questions\/.{36}\/(provide-content)/.exec(url);

  isOperatorUrl = (url) => (
    this.isClearPVUrl(url) ||
    this.isForwardToMedinfoUrl(url) ||
    this.isDeliverAnswer(url) ||
    this.isCloseRequestUrl(url) ||
    this.isDeleteRequestUrl(url) ||
    this.isProvideContentUrl(url)
  );

  resetError = () => this.setState({ operatorError: null });

  render() {
    const {
      operatorError,
      operatorState,
    } = this.state;

    return (
      <Route path={OPERATOR_MODAL_PATHS_ARRAY} render={({ match, history }) => {
        const regexMatch = this.isOperatorUrl(match.url);
        const currentStep = regexMatch ? _camelCase(regexMatch[1]) : null;

        return (
          <OperatorModal
            {...this.props}
            isOpen={!!currentStep}
            conversationId={match.params.conversationId}
            error={operatorError}
            resetError={this.resetError}
            operatorState={operatorState}
            onForwardMedinfo={details => this.onHttpEvent(forwardMedinfo, details)}
            onPVStatusUpdate={details => this.onHttpEvent(updateTicket, details)}
            onReportPV={details => this.onHttpEvent(reportPV, details)}
            onCloseRequest={details => this.onHttpEvent(updateTicket, details)}
            onDeleteRequest={details => this.onHttpEvent(updateTicket, details)}
            onToggle={() => history.push(`/questions/${match.params.conversationId}`)}
          />
        );
      }}/>
    );
  }
}

export default translate()(OperatorModalContainer);

import Layer from '@layerhq/web-xdk';

import './related-questions-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const Widths = Layer.UI.Constants.WIDTH;

registerComponent('xircles-related-questions-message-view', {
  mixins: [MessageViewMixin],
  style: `
    layer-text-message-view {
      display: block;
    }
    .layer-root-viewer.layer-text-message-view > * > .layer-card-top {
      display: block;
    }
  `,
  properties: {
    widthType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata ? Widths.FLEX : Widths.ANY;
      }
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container'
    }
  },
  methods: {
    onRerender() {
      const view = this;
      const conversationId = view.messageViewer.message.conversationId;
      const listItem = view.closest('.layer-list-item');
      view.createElement('layer-text-message-view', {
        model: this.model,
        messageViewContainerTagName: false,
        cardBorderStyle: 'none',
        parentNode: this
      });
      view.model.questions.forEach((question) => {
        const elem = view.createElement('xircles-question-message-view', {
          model: question,
          messageViewContainerTagName: false,
          cardBorderStyle: 'none',
          parentNode: this
        });
        elem.addEventListener('ontouchstart' in document.documentElement ? 'touchstart' : 'click', () => {
          view.trigger('xircles-related-question-send', { conversationId, text: question.text, id: question.text });
          listItem.classList.add('xircles-hide');
        });
      });
    }
  }
});
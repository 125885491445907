// @flow

import * as React from 'react';

import './styles.less';

import { isConversationRoute } from '../../utils/common';
import AlloyFinger from "alloyfinger";
import {translate} from 'react-i18next';

type Props = {
  prevLocation: string;
  history: any;
  t: Function
}

type State = {
  loadingVideo: boolean;
}

class Video extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.headerRef = React.createRef();
    this.containerRef = React.createRef();
    this.textRef = React.createRef();
    this.iframeRef = React.createRef();
    this.backgroundMenuRef = React.createRef();
    this.spikeRef = React.createRef();
    this.shareRef = React.createRef();
    this.arrowBackRef = React.createRef();
    this.smallHeaderRef = React.createRef();
    this.state = {
      loadingVideo: true,
      prevLocation: undefined,
    }
  }

  static getDerivedStateFromProps(nextProps) {
    const prevLocation = nextProps.prevLocation;
    const isConversationUrl = isConversationRoute(prevLocation);

    if (isConversationUrl) {
      return { prevLocation };
    }

    return null;
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.onScroll);
    this.updateStyle();
    window.addEventListener('resize', this.updateStyle);
    this.addAlloy();

    setTimeout(() => {
      this.defaultSize = this.headerRef.current.offsetHeight;
      this.backgroundMenuRef.current.setAttribute('style', `height: ${this.defaultSize}px`);
    }, 1)

  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
    window.removeEventListener('resize', this.updateStyle);
  }


  addAlloy = () => {
    const OPEN_TRANSFORM_X_VALUE = -162;

    const getInnerNodeTransformValue = () => this.headerRef.current && this.headerRef.current.translateX || 0;
    const getTitleToggleStatus = () => this.iconRef.current && this.iconRef.current.style.display !== 'none';

    let startPosition = 0;

    if ('ontouchstart' in document.documentElement) {
      window.Transform(this.headerRef.current);
      const headerElement = this;

      const openActionPanel = () => {
        this.headerRef.current.translateX = OPEN_TRANSFORM_X_VALUE;
        windowEventHandler.on('touchEnd', closeHeaderActionEvent);
      };

      const closeActionPanel = () => {
        this.setState((prevState, props) => ({openedConfirmDeletion: false}));
        this.headerRef.current.translateX = 0;
        windowEventHandler.off('touchEnd', closeHeaderActionEvent);
      };
      const toggleActionPanel = () => {
        const isOpen = this.headerRef.current.translateX === OPEN_TRANSFORM_X_VALUE;
        isOpen ? closeActionPanel() : openActionPanel();
      };

      const closeHeaderActionEvent = (event) => {
        closeActionPanel();
      };

      const windowEventHandler = new AlloyFinger(window, {});

      new AlloyFinger(this.spikeRef.current, {
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
          let x = headerElement.headerRef.current.translateX || 0;
          const swipe = Math.abs(startPosition - x) > 30;

          if (
            (startPosition === OPEN_TRANSFORM_X_VALUE && (swipe || x === startPosition)) ||
            (startPosition === 0 && !swipe && x !== startPosition)
          ) {
            closeActionPanel();
          } else if (
            (startPosition === -212 && !swipe) ||
            (startPosition === 0 && swipe) ||
            (x === startPosition && x === 0)
          ) {
            openActionPanel();
          }

        }
      });

      new AlloyFinger(this.backgroundMenuRef.current, {
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
        }
      });
      new AlloyFinger(this.arrowBackRef.current, {
        touchEnd: function (event) {
          event.preventDefault();
          event.stopPropagation();
          headerElement.clickBack();
        }
      });
      new AlloyFinger(this.shareRef.current, {
        tap: function (event) {
          closeActionPanel();
          headerElement.handleShareClick();
        }
      });

      new AlloyFinger(this.headerRef.current, {
        touchStart: function (event) {
          startPosition = this.translateX;
        },
        tap: function (event) {
          event.stopPropagation();
          event.preventDefault();
        },
        pressMove: function (event) {
          let x = this.translateX + event.deltaX;

          if (x > 0) {
            x = 0;
          } else if (x < -212) {
            x = -212;
          }

          this.translateX = x;
        },
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
          let x = this.translateX || 0;
          const swipe = Math.abs(startPosition - x) > 30;

          if (
            (startPosition === OPEN_TRANSFORM_X_VALUE && swipe) ||
            (startPosition === 0 && !swipe) ||
            (x === startPosition && x === OPEN_TRANSFORM_X_VALUE)
          ) {
            closeActionPanel();
          } else if (
            (startPosition === OPEN_TRANSFORM_X_VALUE && !swipe) ||
            (startPosition === 0 && swipe)
          ) {
            openActionPanel();
          }
        },
      });
    } else {
      window.Transform(this.headerRef.current);

      const closeActionPanel = () => {
        this.setState((prevState, props) => ({openedConfirmDeletion: false}));
        if (this.headerRef.current) {
          this.headerRef.current.translateX = 0;
        }
        window.removeEventListener('mousedown', toggleHeaderActionEvent);
      };

      const openActionPanel = () => {
        this.headerRef.current.translateX = OPEN_TRANSFORM_X_VALUE;
        window.addEventListener('mousedown', toggleHeaderActionEvent);
      };

      const toggleHeaderActionEvent = (event) => {
        getInnerNodeTransformValue() !== OPEN_TRANSFORM_X_VALUE ? openActionPanel() : closeActionPanel();
        window.removeEventListener('mousedown', toggleHeaderActionEvent);
      };

      const mouseMove = (event) => {
        let x = event.movementX + getInnerNodeTransformValue();

        if (x > 0) {
          x = 0;
        } else if (x < OPEN_TRANSFORM_X_VALUE) {
          x = OPEN_TRANSFORM_X_VALUE;
        }

        this.headerRef.current.translateX = x;
      };

      const mouseUp = (event) => {
        let x = getInnerNodeTransformValue();

        if (Math.abs(startPosition - x) > 2) {
          x < (OPEN_TRANSFORM_X_VALUE / 2) ? openActionPanel() : closeActionPanel();

        } else if (x === startPosition) {
          x === OPEN_TRANSFORM_X_VALUE ? closeActionPanel() : openActionPanel();
        } else {
          x < OPEN_TRANSFORM_X_VALUE / 2 ? openActionPanel() : closeActionPanel();
        }

        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', mouseUp);
      };

      const swipe = (event) => {
        event.preventDefault();
        event.stopPropagation();
        startPosition = getInnerNodeTransformValue();

        const removeSwipe = (event) => {
          window.removeEventListener('click', removeSwipe);
          window.removeEventListener('mousemove', mouseMove);
        };

        window.addEventListener('click', removeSwipe);
        window.addEventListener('mousemove', mouseMove);
        window.addEventListener('mouseup', mouseUp);
      };

      this.spikeRef.current.addEventListener('mousedown', swipe);

      this.shareRef.current.addEventListener('click', (event) => {
        closeActionPanel();
      });
    }
  }

  updateStyle = () => {
    // set default size to backgroundMenu
    if (!this.state.opened) {
      // lineClamp(this.titleRef.current, 3);
      this.defaultSize = this.headerRef.current.offsetHeight;
      this.backgroundMenuRef.current.setAttribute('style', `height: ${this.defaultSize}px`);
    }
  };

  hideSpinner = () => {
    setTimeout(() => {
      this.iframeRef.current.classList.add('load');
    }, 600);

  };

  clickBack = () => {
    this.props.history.push(this.state.prevLocation);
  };

  clickShareContent = () => {
    this.props.history.push(this.props.history.location.pathname + '/share');
  };

  onScroll = () => {
    this.setState({scrollTop: window.pageYOffset})
    if (this.textRef.current.clientHeight >= document.documentElement.clientHeight + 200 + this.headerRef.current.clientHeight) {
      if (window.pageYOffset > this.headerRef.current.clientHeight + 200) {
        this.smallHeaderRef.current.style.top = '0';
      } else if (window.pageYOffset < this.headerRef.current.clientHeight + 100) {
        this.smallHeaderRef.current.style.top = '-' + this.smallHeaderRef.current.clientHeight + 5 + 'px';
      } else if ((window.pageYOffset > this.headerRef.current.clientHeight + 100) && (window.pageYOffset < this.headerRef.current.clientHeight + 200)) {
        this.smallHeaderRef.current.style.top = '-' + (this.smallHeaderRef.current.clientHeight * (1 - (window.pageYOffset - 100 - this.headerRef.current.clientHeight) / 100)) + 'px';
      }
    }
    else {
      this.smallHeaderRef.current.style.top = '-' + this.smallHeaderRef.current.clientHeight + 5 + 'px';
    }
  };

  render() {
    const {t} = this.props

    return (
      <div ref={this.containerRef} className={'video-container'}>
        <header
          ref={this.smallHeaderRef}
          className={'small-header'}>
          <div>
            <button
              className={'back'}
              onClick={this.clickBack}
            >
              <i className="material-icons">arrow_back</i>
            </button>
            <h1>STRIVE Self Administered Injection Instructions</h1>
          </div>
        </header>
        <header ref={this.headerRef}>
          <div>
            <button
              ref={this.arrowBackRef}
              className={'back'}
              onClick={this.clickBack}>
              <i className="material-icons">arrow_back</i>
            </button>
            <h1>STRIVE Self Administered Injection Instructions</h1>
          </div>
          <div ref={this.spikeRef} className={'spike'}>
            <div></div>
          </div>
        </header>
        <div ref={this.backgroundMenuRef}
             className={`background-menu `}>
          <ul>
            <li><i className="material-icons">outlined_flag</i>
              {`${t('COMMON_MARK')}`}
            </li>
            <li
              ref={this.shareRef}
              onClick={this.handleShareClick}
            >
              <i className="material-icons">share</i>
              {`${t('COMMON_SHARE')}`}
            </li>
          </ul>
        </div>
        <main>
          <div ref={this.textRef} className={"content"}>
            <div className={"iframe-video-wrapper"}>
              <iframe ref={this.iframeRef} src="https://player.vimeo.com/video/246323995?title=0&byline=0&portrait=0"
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      onLoad={this.hideSpinner}
                      allowFullScreen/>
            </div>

            <button type="button" value="Submit" className={'xircles-button'} onClick={this.clickShareContent}>
              <span>Share video</span>
            </button>

            <p><strong>Paragraph</strong> - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus
              dolorem
              molestiae necessitatibus officiis provident qui veniam voluptate voluptatibus? Aspernatur culpa error
              est
              harum possimus quaerat tenetur voluptate. Illo, incidunt, voluptate.</p>

            <button type="button" value="Submit" onClick={this.clickBack}
                    className={'xircles-button xircles-button-primary'}>
              <span>Ok</span>
            </button>
          </div>
        </main>
      </div>
    )
  }
}

export default translate()(Video);
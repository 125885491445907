import React from 'react';
import camelCase from 'lodash/camelCase';
import { Route, withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import LoginModal from '../components/Modal/LoginModal';
import { sendVerificationCode, codeVerify, authenticate, checkUserStatus, authenticateDocCheck, upsertPassword } from '../utils/api';
import {isEmail, isPhoneNumber, isSecurityCode} from '../utils/common';
import logout from '../common/logout';

const mapPrevStepByStep = {
  login: null,
  sendCode: 'login',
  resetPassword: 'password',
  createPassword: null,
  passwordCode: null,
  setPassword: null,
  code: 'sendCode',
  password: 'login',
  passwordSubmit: null,
  welcome: null,
};

type Props = {
  t: Function,
};

type State = {
  pathname: string,
  loginError: any,
  loginState: 'loading' | 'loaded' | 'error',
  identity: any,
};

class LoginModalContainer extends React.Component<Props, State> {
  state = {
    pathname: null,
    loginState: null,
    loginError: null,
    identity: null,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { pathname } = nextProps.location;

    if (pathname !== prevState.pathname) {
      return {
        pathname,
        loginState: null,
        loginError: null,
      };
    }

    return null;
  }

  onHttpEvent = (apiCall, credentials) => {
    this.setState({ loginState: 'loading', loginError: null, identity: credentials.identity });
    return apiCall(credentials)
      .then(data => {
        console.log(data);
        this.setState({ loginState: 'loaded' });
        return data;
      })
      .catch(loginError => {
        const errorMessage = _get(loginError, 'response.data.error.id', 'SOMETHING_WENT_WRONG');

        this.setState({
          loginState: 'error',
          loginError: this.props.t('ERROR_' + errorMessage),
        });

        throw loginError;
      });
  };

  validatePhoneNumberOrEmail = (phoneNumberOrEmail) => {
    const isValidPhoneNumberOrEmail = isEmail(phoneNumberOrEmail)
      || isPhoneNumber(phoneNumberOrEmail);

    const updatedState = !isValidPhoneNumberOrEmail
      ? { loginState: 'error', loginError: this.props.t('ERROR_ENTER_VALID_NUMBER_OR_EMAIL') }
      : { loginError: null };

    this.setState(updatedState);
    return isValidPhoneNumberOrEmail;
  };

  validatePassword = (password) => {
    const isValidPassword = !_isEmpty(password);

    const updatedState = !isValidPassword
      ? { loginState: 'error', loginError: this.props.t('ERROR_ENTER_PASSWORD') }
      : { loginError: null };

    this.setState(updatedState);
    return isValidPassword;
  };

  validateSecurityCode = (securityCode) => {
    const isValidSecurityCode = isSecurityCode(securityCode);

    const updatedState = !isValidSecurityCode
      ? { loginState: 'error', loginError: this.props.t('ERROR_CODE_NOT_VALID') }
      : { loginError: null };

    this.setState(updatedState);
    return isValidSecurityCode;
  };

  // TODO: move into LayerProvider
  connectWithLayerClient = (popUpBasePath, data) => {
    const { layerClient } = this.props;
    const { user_id, session_token, username, password_code } = data;
    let conversationID = popUpBasePath.match(/questions\/(.{36})/);
    let conversation;
    let oldUserID = layerClient.userId;

    const doLogout = (resolve) => {
      logout().then(() => {
        layerClient.connectWithSession(user_id, session_token);
        this.setState({password_code}, () => resolve({ username, password_code }));
      });
    };

    const doHandover = (resolve) => {
      conversation = layerClient.getConversation(conversationID[1], true);
      conversation.addParticipants([user_id]);
      conversation.removeParticipants([oldUserID]);

      doLogout(resolve);
    };

    return new Promise((resolve) => {
      if (oldUserID !== user_id) {
        if (conversationID !== null) {
          if (layerClient.isReady) {
            doHandover(resolve);
          } else {
            layerClient.once('ready', () => {
              doHandover(resolve);
            });
          }
        } else {
          doLogout(resolve);
        }
      } else {
        this.setState({password_code}, () => resolve({ username, password_code }));
      }
    });
  };

  switchUserMode = (toProxyUser: boolean = false) => {
    const { layerClient } = this.props;
    const { identity, password_code } = this.state;
    codeVerify({
      identity,
      verification_code: password_code,
      action: toProxyUser ? 'Login' : 'LoginNoProxy',
    }).then(({data}) => {
      const {user_id, session_token, password_code} = data;
      logout().then(() => {
        layerClient.connectWithSession(user_id, session_token);
        this.setState({password_code});
      });
    })
  };

  resetError = () => this.setState({ loginError: null });

  render() {
    return (
      <Route path="*" render={({ match, history }) => {
        // TODO: Maybe not smartest approach and some non greedy regex could also do the job
        // using two regexes to cope with matching order ('send-code' vs. 'code')
        const regexMatchA = /(\/questions\/[^\/]*)\/?(send-code|password-code|create-password|password-submit|doccheck-welcome)+?/.exec(match.url);
        const regexMatchB = /(\/questions\/[^\/]*)\/?(login|logout|code|password|welcome|doccheck)+?/.exec(match.url);
        let popUpBasePath = null;
        let currentStep = null;
        if (regexMatchA) {
          popUpBasePath = regexMatchA[1];
          currentStep = camelCase(regexMatchA[2]);
        } else if (regexMatchB) {
          popUpBasePath = regexMatchB[1];
          currentStep = camelCase(regexMatchB[2]);
        }
        const canGoBack = !!mapPrevStepByStep[currentStep];

        const _connectWithLayerClient = ({data}) => this.connectWithLayerClient(popUpBasePath, data);

        return (
          <LoginModal
            {...this.props}
            isOpen={!!currentStep}
            onCodeSend={credentials => this.onHttpEvent(sendVerificationCode, credentials)}
            onCodeVerify={credentials => this.onHttpEvent(codeVerify, credentials).then(_connectWithLayerClient)}
            onLogin={credentials => this.onHttpEvent(authenticate, credentials).then(_connectWithLayerClient)}
            onLogout={() => logout(this.props.userId).then(this.props.onLoginAnonymously)}
            onDocCheckLogin={credentials => this.onHttpEvent(authenticateDocCheck, credentials)}
            onUserCheck={credentials => this.onHttpEvent(checkUserStatus, credentials)}
            onUpsertPassword={credentials => this.onHttpEvent(upsertPassword, credentials)}
            onToggle={() => popUpBasePath && history.push(popUpBasePath)}
            onGoBack={canGoBack ? () => history.goBack() : null}
            error={this.state.loginError}
            resetError={this.resetError}
            loginState={this.state.loginState}
            validatePhoneNumberOrEmail={phoneNumberOrEmail => this.validatePhoneNumberOrEmail(phoneNumberOrEmail)}
            validatePassword={password => this.validatePassword(password)}
            validateSecurityCode={securityCode => this.validateSecurityCode(securityCode)}
          />
        );
      }}/>
    );
  }
}

export default translate()(withRouter(LoginModalContainer));
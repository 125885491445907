// @flow
/* eslint-disable no-restricted-globals */
/**
 * Root level of the React application.
 *
 * For a typical application, this would contain access to a lot of your own UI components, one or more of which might contain Layer XDK Components.
 */
import React from 'react'
import {Switch, Route, withRouter} from 'react-router-dom'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import {CookiesProvider} from 'react-cookie'
import DocCheckTarget from './components/DocCheck/DocCheckTarget'
import Document from './components/Document'
import Video from './components/Video'
import LongCopy from './components/LongCopy/index'
import LayerContext from './components/LayerContext';
import _includes from 'lodash/includes';
import 'normalize.css/normalize.css'

import '@material/react-switch/dist/switch.min.css'

import './App.less';
import QuestionPageContainer from './containers/QuestionPageContainer';
import AnswerPageContainer from './containers/AnswerPageContainer';
import LoginModalContainer from './containers/LoginModalContainer';
import ShareModalContainer from './containers/ShareModalContainer';
import ScanEFNModalContainer from './containers/ScanEFNModalContainer';
import OperatorModalContainer from './containers/OperatorModalContainer';
import SplashScreenPage from './components/SplashScreen/index';
import logoLarge from "./images/xircleLogo@3x.png";
import DeviceOrientation, {Orientation} from './modules/orientation';
import {isConversationRoute, isDocumentsRoute, isQuestionsRoute, isVideoRoute} from './utils/common';
import {CONVERSATION_ACTIONS} from './const/conversation-actions';
import {ENTER_KEYSTROKE_PATHS} from './const/enter-keystroke-paths';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      firstTime: true,
      prevLocation: '/questions',
    };
    this.lastLocationKey = null;
  }

  KEY_CODE_ENTER = 13;

  // We can't use history.push at layer mixin so customEvent "change-route" was created (see LinkMessageViewMixin)
  changeRoute = (evt) => {
    if (evt.detail.url.substring(0, 4) === 'http' || evt.detail.url.substring(0, 4) === 'blob') {
      const win = window.open(evt.detail.url, '_blank');
      win.focus();
    } else {
      this.props.history.push(evt.detail.url);
    }
  };

  showShareConversation = (evt) => {
    const {conversationId} = evt.detail;
    this.props.history.push({
      pathname: `/questions/conversation/share`,
      state: {detail: {conversationId}},
    });
  };

  componentWillMount(): void {
    const isLocalhost = window.location.href.includes('localhost');
    const isCloudfront = window.location.href.includes('d2kxinsd3tu4rd.cloudfront.net');

    if (isLocalhost || isCloudfront) {
      this.props.location.pathname === '/' && this.props.history.push('/questions');
    } else {
      this.state.firstTime && this.props.history.push('/')
    }
  }

  handleEnterKeystrokePopUps = (keyCode) => {
    if (keyCode === this.KEY_CODE_ENTER) {
      const path = this.props.location.pathname.split('/').pop();

      if (_includes(ENTER_KEYSTROKE_PATHS, path)) {
        const submitButton = document.querySelector('[data-keystroke="true"]');

        if (submitButton) {
          submitButton.focus();
        }
      }
    }
  };

  handleKeydown = ({keyCode}) => {
    this.handleEnterKeystrokePopUps(keyCode);
  };

  componentDidMount(): void {
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('change-route', this.changeRoute);
    window.addEventListener(CONVERSATION_ACTIONS.share, this.showShareConversation);
  }

  componentWillUnmount(): void {
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('change-route', this.changeRoute);
    window.removeEventListener(CONVERSATION_ACTIONS.share, this.showShareConversation);
  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
    // router redirect gives warning with transitions, so redirect here on back navigation
    if (nextProps.location.pathname === '/' && !this.state.firstTime) {
      nextProps.history.push('/questions');
      return;
    }
    let routeChanged = nextProps.location.pathname !== this.props.location.pathname;

    const stateUpdate = {firstTime: !routeChanged};
    if (routeChanged) {
      stateUpdate.prevLocation = this.props.location.pathname;
    }
    this.setState(stateUpdate);
  }

  handleRenderQuestionPage = (props) => {
    return <QuestionPageContainer {...props} firstTime={this.state.firstTime}/>;
  };

  needsPageAnimation() {
    return (isQuestionsRoute(this.state.prevLocation) && isConversationRoute(this.props.location.pathname)) ||
      (isConversationRoute(this.state.prevLocation) && isQuestionsRoute(this.props.location.pathname)) ||
      (isConversationRoute(this.state.prevLocation) && isDocumentsRoute(this.props.location.pathname)) ||
      (isDocumentsRoute(this.state.prevLocation) && isConversationRoute(this.props.location.pathname)) ||
      (isDocumentsRoute(this.state.prevLocation) && isQuestionsRoute(this.props.location.pathname)) ||
      (isConversationRoute(this.state.prevLocation) && isVideoRoute(this.props.location.pathname)) ||
      (isVideoRoute(this.state.prevLocation) && isConversationRoute(this.props.location.pathname)) ||
      (isVideoRoute(this.state.prevLocation) && isQuestionsRoute(this.props.location.pathname))
  }

  render() {
    let key = this.props.location.key;
    if (this.needsPageAnimation()) {
      this.lastLocationKey = this.props.location.key;
    } else {
      key = this.lastLocationKey;
    }
    return (
      <CookiesProvider>
        <DeviceOrientation lockOrientation={'portrait'} className={'landscape-overlay-wrap'}>
          <Orientation orientation={'landscape'}>
            <div id={'landscape-overlay'}>
              <div className={'lo-content'}>
                <h2>Dupixent® (Dupilumab)</h2>
                <img src={logoLarge} width="24" height="24" alt="Logo"/><br/>
                Please use your smartphone in portrait mode to use our service
              </div>
            </div>
          </Orientation>
        </DeviceOrientation>
        <div className='wrapper'>
          <TransitionGroup>
            <CSSTransition
              key={key}
              timeout={{enter: 600, exit: 600}}
              classNames={'fade'}
            >
              <Switch location={this.props.location}>
                <Route exact path='/' component={SplashScreenPage}/>
                <Route
                  path='/questions/(login|logout|send-code|password-code|password-submit|login-unregister|code|password|set-password|create-password|reset-password|welcome|legacy-login|doccheck|scan-efn|conversation/share|conversation/shared|forward-to-medinfo|clear-pv|close-request|delete-request|deliver-answer)?'
                  exact component={this.handleRenderQuestionPage}/>
                <Route path='/questions/:conversationId(.{36})/:hash?' component={AnswerPageContainer}/>
                <Route path='/documents/*'
                       render={(props) => <Document {...props} prevLocation={this.state.prevLocation}/>}/>
                <Route path='/video/:videoName'
                       render={(props) => <Video {...props} prevLocation={this.state.prevLocation}/>}/>
                <Route path='/long-copy/:contentName'
                       render={(props) => <LongCopy {...props} prevLocation={this.state.prevLocation}/>}/>
                <Route exact path='/dchcpv' component={withRouter(DocCheckTarget)}/>
              </Switch>
            </CSSTransition>
          </TransitionGroup>
          <LayerContext.Consumer>
            {(props) => (
              <LoginModalContainer
                {...props}
              />
            )}
          </LayerContext.Consumer>
          <ShareModalContainer/>
          <ScanEFNModalContainer/>
          <OperatorModalContainer/>
        </div>
      </CookiesProvider>
    )
  }
}

export default withRouter(App);

// @flow

/**
 *      Creates stateless UI component for Question page
 *
 *      onToggleMenu     - callback for toggle menu ()=>{}, NOT required
 *      titleProps       - props for title, NOT required
 */

import * as React from 'react';
import {translate} from 'react-i18next';

import './styles.less'

type Props = {
  onToggleMenu?: Function,
  isLoggedIn: boolean,
  userName?: string,
  isReady: string,
  onLogin: Function,
  onDisplayName: Function,
  titleProps?: Array,
  t: any,
  isOperator: boolean
};

const Header = (props: Props) => {
  const {t, displayName, isOperator, forwardedRef} = props;

  return (
    <div ref={forwardedRef} className={`panel-header conversations-header`}>
      {
        isOperator &&
          <div className={'grey-top'}></div>
      }
      {
        !isOperator && <h6 className={'phrase'}>{t('COMMON_HEADER_FOR_HCPS')}</h6>
      }
      {(props.isLoggedIn ?
        <h6 className={`login ${isOperator ? 'login__operator' : ''}`} onClick={props.onDisplayName}>{displayName}</h6> :
        <h6 className={`login ${isOperator ? 'login__operator' : ''}`} onClick={props.onLogin}>{t('COMMON_MENU_LOGIN')}</h6>)
      }
      <h6 className={`menu ${isOperator ? 'menu__operator' : ''}`} onClick={props.onToggleMenu}>{t('COMMON_MENU_MENU')}</h6>
      <div className={`menu-button ${isOperator ? 'menu-button__operator' : ''}`}>
        <button onClick={props.onToggleMenu}><i className="material-icons">menu</i></button>
      </div>
      <div className={`title ${isOperator ? 'title__operator' : ''}`} children={props.titleProps}/>
    </div>
  )
};

Header.defaultProps = {
  onToggleMenu: null,
  imgProps: {},
};

export default translate()(Header);
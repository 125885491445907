import React from 'react';
import IconButtonBase from '@material/react-icon-button';
import './style.scss';

type Props = {
  color?: string,
  className?: string
};

export default function IconButton(props: Props) {
  const { color, className, ...buttonProps } = props;
  if (color === "primary") {
    return <IconButtonBase className={`mdc-icon-button--primary ${className}`} {...buttonProps} />;
  }
  return <IconButtonBase className={className} {...buttonProps} />;
}

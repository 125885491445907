import Layer from '@layerhq/web-xdk';

const { Root, MessagePart, Client } = Layer.Core;

const LinkModel = Client.getMessageTypeModelClass('LinkModel');

class XirclesLinkModel extends LinkModel {

  generateParts(callback) {
    const body = this.initBodyWithMetadata([
      'imageUrl',
      'author',
      'title',
      'description',
      'url',
      'matchedTags',
      'score',
      'onlyForHcp',
    ]);

    this.part = new MessagePart({
      mimeType: this.constructor.MIMEType,
      body: JSON.stringify(body),
    });
    callback([this.part]);
  }
}

XirclesLinkModel.prototype.imageUrl = '';

XirclesLinkModel.prototype.author = '';
XirclesLinkModel.prototype.title = '';
XirclesLinkModel.prototype.description = '';
XirclesLinkModel.prototype.url = '';
XirclesLinkModel.prototype.html = '';
XirclesLinkModel.prototype.matchedTags = '';
XirclesLinkModel.prototype.score = 0;
XirclesLinkModel.prototype.onlyForHcp = false;

XirclesLinkModel.SummaryTemplate = '${url}'; // eslint-disable-line no-template-curly-in-string
XirclesLinkModel.LabelSingular = 'Link';
XirclesLinkModel.LabelPlural = 'Links';
XirclesLinkModel.defaultAction = 'open-url';

XirclesLinkModel.MIMEType = 'application/vnd.layer.link+json';
XirclesLinkModel.messageRenderer = 'layer-link-message-view';

Root.initClass.apply(XirclesLinkModel, [XirclesLinkModel, 'LinkModel']);
Client.registerMessageTypeModelClass(XirclesLinkModel, 'LinkModel');

export default XirclesLinkModel;

import { layerClient } from '../get-layer';

export default function logout() {
  return new Promise((resolve) => {
    layerClient.logout(() => {
      // it never fails
      resolve();
    });
  });
}

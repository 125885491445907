// @flow
import React from 'react';
import TextField from '../../../material-components/TextField';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import AutoFocusInput from '../../AutoFocusInput';
import A from '../../A';
import {translate} from 'react-i18next';

type Props = {
  phoneNumberOrEmail: string | null,
  onPhoneNumberOrEmailChange: Function,
  onNext: Function,
  onSignUp: Function,
  t: Function
};

function LoginModalFragment({
  phoneNumberOrEmail,
  onPhoneNumberOrEmailChange,
  onNext,
  onSignUp,
  t
}: Props) {
  return (
    <React.Fragment>
      <TextField dense outlined style={{ width: '100%' }}>
        <AutoFocusInput
          id="email"
          name="email"
          type="text"
          style={{ textAlign: 'center' }}
          placeholder={`${t('LOGIN_MOBILE_OR_EMAIL_PLACEHOLDER')}`}
          value={phoneNumberOrEmail}
          onChange={e => onPhoneNumberOrEmailChange(e.target.value)}
        />
      </TextField>
      <Box mt={2}>
        <Button data-keystroke outlined style={{ width: '100%' }} onClick={onNext}>{t('LOGIN_NEXT_BUTTON')}</Button>
      </Box>
      <Box mt={1} textAlign="center">
        {t('LOGIN_TEXT_FIRST_ROW')} <br/>
        {t('LOGIN_TEXT_SECOND_ROW')}
      </Box>
      <Box mt={6} textAlign="center">
        <span>{t('LOGIN_DONT_HAVE_AN_ACCOUNT')}</span>{' '}
        <A
          href="#"
          onClick={(e) => {
            e.preventDefault();
            return onSignUp();
          }}
        >
          {t('LOGIN_SIGN_UP')}
        </A>
      </Box>
    </React.Fragment>
  );
}

export default translate()(LoginModalFragment);

/* global DSScanner */
import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom'
import { CHOICE_ACTION_EVENTS } from '../../../const/choice-actions';
import Dialog from '../../../material-components/Dialog';
import Box from '../../../material-components/Box';
import config from '../../../config.json';

import efnLarge from '../../../images/efn@3x.png';

type Props = {
  isOpen: boolean,
  onToggle: Function,
  onDetected: Function,
  conversationId: string;
};

type State = {};

class ScanEFNModal extends React.Component<Props, State> {

  successEvent: string = CHOICE_ACTION_EVENTS.scanefn_success;
  canceledEvent: string = CHOICE_ACTION_EVENTS.scanefn_cancel;

  handleOnCloseMobile = () => {
    this.node = ReactDOM.findDOMNode(this);
    this.child = this.node.querySelector('.mdc-dialog__scrim');
    this.child.addEventListener('click', (ev) => this.props.isOpen && this.onClose(ev));
  };

  onBarcodeReady = (barcodeResult) => {
    const [codedData] = barcodeResult;

    const bytesView = new Uint8Array(codedData.data);
    const decodedData = new TextDecoder().decode(bytesView);

    if (!decodedData.includes('*')) {
      this.props.history.push(`/questions/${this.props.conversationId}`);
      window.dispatchEvent(new CustomEvent(this.successEvent, { detail: decodedData }));
    }
  };

  createScanner = () => {
    const scannerSettings = {
      scanner: {
        key: config.barcode_scanner_key,
        beep: false,
      },
      viewport: {
        id: 'datasymbol-barcode-viewport',
        width: 640,
        height: 360,
      },
      camera: {
        id: null,
        label: null,
        facingMode: 'environment',
        resx: 640,
        resy: 360,
      },
      barcode: {
        barcodeTypes: ['Code128', 'Code39'],
      },
    };

    DSScanner.addEventListener('onBarcode', this.onBarcodeReady);
    DSScanner.addEventListener('onScannerReady', DSScanner.StartScanner);

    DSScanner.Create(scannerSettings);
  };

  componentDidMount() {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(() => this.createScanner());

    this.handleOnCloseMobile();
  }

  componentWillUnmount() {
    DSScanner.Destroy();
  }

  onClose = (ev) => {
    const { onToggle } = this.props;

    window.dispatchEvent(new Event(this.canceledEvent));
    onToggle(ev);
  }

  render() {
    const { isOpen } = this.props;

    return (
      <Dialog className="xircles-scan-efn-dialog" open={isOpen} onClose={this.onClose}>
        <div id="datasymbol-barcode-viewport" tabIndex={0} />
        <Box textAlign="center">
          Place the EFN Barcode on your <br />
          physician ID in front of your camera.
        </Box>
        <img src={efnLarge} width="230" height="144" alt="efn" />
      </Dialog>
    );
  }
}

export default withRouter(ScanEFNModal);
import i18n from "i18next";
import {reactI18nextModule} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import config from './config.json';

const backendOpts = {
  loadPath: `${config.cdn_url}/translations/translation.${config.language || 'en'}.json`
};

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    useLocalStorage: true,
    debug: true,
    fallbackLng: 'en',
    load: 'languageOnly',
    backend: backendOpts,
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true,
    },
  });

export default i18n;
import Layer from '@layerhq/web-xdk';

import i18next from 'i18next';

import config from '../../config.json';
import { isHcp } from '../../utils/user';

import './result-message-model';

const registerComponent = Layer.UI.registerComponent;
const MessageViewMixin = Layer.UI.mixins.MessageViewMixin;
const processText = Layer.UI.handlers.text.processText;
const Widths = Layer.UI.Constants.WIDTH;

const xSlateLightColor = '#aaacb2';
let pressTime;

registerComponent('xircles-result-message-view', {
  mixins: [MessageViewMixin],
  style: `
    xircles-result-message-view {
      display: block;
      clear: both;
      cursor: pointer;
      padding-right: 20px !important;
      background-color: white !important;
    }
    xircles-result-message-view > div {
      padding: 10px 70px 3px 0 !important;
    }
    xircles-result-message-view > span {
      font-weight: 400;
      font-size: 16px;
      margin-top: 10px;
      display: block;
      line-height: 1.35;
    }
    xircles-result-message-view.only-for-hcp > div, 
    xircles-result-message-view.only-for-hcp > span {
      color: ${xSlateLightColor};
    }
    .layer-root-viewer.xircles-result-message-view > * > .layer-card-top {
      display: block;
    }
  `,
  template: `
        <div>
          <span layer-id="title"></span>
          <i class="xircles-icons icon-send"></i>
        </div>  
        <span layer-id="snippet"></span>
  `,
  properties: {
    withType: {
      get: function get() {
        return this.parentComponent.isShowingMetadata ? Widths.FLEX : Widths.ANY;
      }
    },
    messageViewContainerTagName: {
      noGetterFromSetter: true,
      value: 'layer-standard-message-view-container'
    }
  },
  methods: {
    _goToUrl() {
      const view = this;
      const conversationId = view.mainComponent.conversationId;
      if (this.needHcpCheck) {
        view.trigger('xircles-question-send', {
          conversationId,
          text: 'Need HCP Check',
          slug: config.hcp_check_slug,
          data: {
            url: view.model.url,
            title: view.model.title,
          }
        });
      } else {
        view.trigger('xircles-goto-url', {
          conversationId,
          title: view.model.title,
          url: view.model.url,
        });
      }
    },
    _onTouchStart() {
      pressTime = new Date().getTime();
    },
    _onTouchEnd() {
      const averageTouchDelay = 100;
      const releaseTime = new Date().getTime();

      if (releaseTime - pressTime < averageTouchDelay) {
        this._goToUrl();
      }
    },
    _updateContent() {
      let dbg = this.model.score;
      if ( this.model.matchedTags &&  this.model.matchedTags.length) {
        dbg = this.model.matchedTags.join(', ');
      }
      this.classList.toggle('only-for-hcp', this.needHcpCheck);
      this.nodes.title.innerHTML = processText(`${this.model.title} ${ config.debug ? `<sup><em>${dbg}</em></sup>` : ''}`);
      this.nodes.snippet.innerHTML = processText(this.needHcpCheck ? `<em>${i18next.t('ANSWER_FOR_HCP_ONLY')}</em>` : this.model.snippet);
      this.model.url && this.addEventListener('click', this._goToUrl);

      this.model.url && 'ontouchstart' in document.documentElement && this.addEventListener('touchstart', this._onTouchStart);
      this.model.url && 'ontouchend' in document.documentElement && this.addEventListener('touchend', this._onTouchEnd);
    },
    onAfterCreate() {
      this.needHcpCheck = this.model.onlyForHcp && !isHcp(Layer.client.user);
      this._updateContent();
    },
    onRerender() {
      this._updateContent();
    }
  }
});
// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import {translate} from 'react-i18next';

type Props = {
  phoneNumberOrEmail: string | null,
  onSignUp: Function,
  t: Function
};

function LoginUnregisterModalFragment(
  {
    phoneNumberOrEmail,
    onSignUp,
    t
  }: Props) {
  return (
    <React.Fragment>
      <Box fontSize="htmlFontSize" textAlign="center" mt={0.75} mb={1.25}>{phoneNumberOrEmail}</Box>
      <Box textAlign="center" mb={1.5}>
        {t('LOGIN_UNREGISTER_FIRST_ROW')} <br/>
        {t('LOGIN_UNREGISTER_SECOND_ROW')}
      </Box>
      <Box mb={1}>
        <Button data-keystroke outlined style={{width: '100%'}} onClick={onSignUp}>
          {t('LOGIN_SIGN_UP')}
        </Button>
      </Box>
    </React.Fragment>
  );
}

export default translate()(LoginUnregisterModalFragment)
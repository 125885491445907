// @flow
import React from 'react';
import Box from '../../material-components/Box';
import './style.less';

type Props = {
  suggestion: String,
  onClick: Function,
  preventBubblingEvent: Function,
};

function PullDownItem({ suggestion, onClick, preventBubblingEvent }: Props) {
  return (
    <div
      className="pull-down-item"
      onClick={onClick}
      onMouseDown={preventBubblingEvent}
      onMouseUp={preventBubblingEvent}
      onTouchStart={preventBubblingEvent}
      onTouchEnd={preventBubblingEvent}
    >
      <Box my={1} className="pull-down-item__suggestion">
        <div className="pull-down-item__suggestion-text">{suggestion}</div>
        <button className="pull-down-item__go-button">
          <i className="xircles-icons icon-select-suggestion" />
        </button>
      </Box>
    </div>
  );
}

export default PullDownItem;


import Layer from '@layerhq/web-xdk';
import _get from 'lodash/get';
import MESSAGE_MODEL_TYPES from '../const/message-model-types';

const MessageListMixin = {
  properties: {
    conversation: {
      set: function set(value) {
        if (value) {
          value.isDoneSizingContent = false;
        }
      }
    }
  },
  methods: {
    onGenerateListItem: function (widget) {
      this._toggleInput(widget);

      if (this.conversation.isDoneSizingContent) {
        this._hideBackButton();
      }
    },
    onAfterCreate: function () {
      this.trigger('xircles-set-keyboard', {visibility: 'hidden', conversation: this.conversation});
    },
    onPagedDataDone: function (isDoneSizingContent) {
      if (isDoneSizingContent) {
        this.conversation.isDoneSizingContent = isDoneSizingContent;
        Layer.Settings.client.on('conversations:change', function (evt) {
          if (evt.target.isDoneSizingContent) {
            const change = evt.changes.filter((c) => c.property === 'lastMessage').pop();

            if (change && change.newValue.hasOwnProperty('receivedAt') && change.newValue.sender.displayName === 'Bot') {
              const endTyping = change.newValue._messageTypeModel.customData.endTyping;
              const text = (change.newValue._messageTypeModel.text || '').toLowerCase().trim();

              if (text !== 'hidden' && text !== 'end typing' && !endTyping) {
                Layer.Settings.client.trigger('typing-indicator-change', {
                  conversationId: evt.target.id,
                  typing: [Layer.Settings.client.user],
                  paused: []
                })
              } else if (text === 'end typing' || endTyping) {
                Layer.Settings.client.trigger('typing-indicator-change', {
                  conversationId: evt.target.id,
                  typing: [],
                  paused: [Layer.Settings.client.user]
                })
              }
            }
          }
        });
      }
    },
    _toggleInput: function (widget) {
      const widgetId = widget.item.id;
      const conversationLastMessageId = this.conversation.lastMessage && this.conversation.lastMessage.id;

      if (widgetId === conversationLastMessageId) {
        let lastMessageType = _get(this.conversation, 'lastMessage._messageTypeModel.currentMessageRenderer');
        let isMessageHideInput = MESSAGE_MODEL_TYPES.indexOf(lastMessageType) >= 0;

        if (isMessageHideInput) {
          this.conversation.isMessageHideInput = true;
          this.trigger('xircles-set-keyboard', {visibility: 'hidden', conversation: this.conversation});
          widget.querySelector('.layer-list-item').classList.remove('xircles-hide');
          this.conversation.lastVisibleMessage = widget;
        } else {
          this.conversation.isMessageHideInput = false;
          this.trigger('xircles-set-keyboard', {visibility: 'visible', conversation: this.conversation});
          const backBtn = widget.querySelector('.icon-up');
          backBtn && backBtn.classList.remove('xircles-hide');
        }
      }
    },
    _hideBackButton: function () {
      // next tick, to ensure new list item is in DOM
      setTimeout(() => {
        const sent = this.querySelector('layer-message-item-sent:last-child');
        if (sent) {
          const backBtn = this.querySelector(
            'layer-message-item-received.layer-message-item-layer-text-message-view:not(.xircles-mode-hidden) .icon-up:not(.xircles-hide)'
          );
          backBtn && backBtn.classList.add('xircles-hide');
        }
      })
    },
    _renderInsertedData: function (evt) {
      console.log('rid');
    },
  }
};

export default MessageListMixin;

// @flow
import React from 'react';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import {translate} from 'react-i18next';


type Props = {
  onLogout: Function,
  onCancel: Function,
  t: Function
};

function LogoutModalFragment({
  onLogout,
  onCancel,
  t,
}: Props) {
  return (
    <React.Fragment>
      <Box mt={1} textAlign="center">
        {t('LOGOUT_TEXT')}
      </Box>
      <Box mt={2}>
        <Button outlined style={{ width: '100%' }} onClick={onCancel} color="secondary" focused='false'>{t('LOGOUT_CANCEL_BUTTON')}</Button>
      </Box>
      <Box mt={1}>
        <Button data-keystroke outlined style={{ width: '100%' }} onClick={onLogout}>{t('LOGOUT_BUTTON')}</Button>
      </Box>
    </React.Fragment>
  );
}

export default translate()(LogoutModalFragment);

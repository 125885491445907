// @flow
import React from 'react';
import TextField from '../../../material-components/TextField';
import A from '../../A';
import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';
import AutoFocusInput from '../../AutoFocusInput';
import {translate} from 'react-i18next';

type Props = {
  phoneNumberOrEmail: string | null,
  password: string | null,
  onPasswordChange: Function,
  onLogin: Function,
  onResetPassword: Function,
  t: Function
};

function PasswordModalFragment({
  phoneNumberOrEmail,
  password,
  onPasswordChange,
  onLogin,
  onResetPassword,
  t
}: Props) {
  return (
    <React.Fragment>
      <Box fontSize="htmlFontSize" textAlign="center" mt={0.75} mb={1.75}>{phoneNumberOrEmail}</Box>
      <TextField dense outlined style={{ width: '100%' }}>
        <AutoFocusInput
          id="password"
          name="password"
          type="password"
          style={{ textAlign: "center" }}
          placeholder={`${t('LOGIN_PASSWORD_PLACEHOLDER')}`}
          value={password}
          onChange={e => onPasswordChange(e.target.value)}
        />
      </TextField>
      <Box mt={2}>
        <Button data-keystroke outlined style={{ width: '100%' }} onClick={(e) => {
          e.preventDefault();
          return onLogin(e);
        }}>
          {t('LOGIN_PASSWORD_LOGIN')}
        </Button>
      </Box>
      <Box mt={6} textAlign="center">
        <div>
          {t('LOGIN_PASSWORD_FORGOTTEN')}{' '}
          <A
            href="#"
            onClick={e => {
              e.preventDefault();
              return onResetPassword(e);
            }}
          >
            {t('LOGIN_PASSWORD_SEND_CODE')}
          </A>.
        </div>
      </Box>
    </React.Fragment>
  );
}

export default translate()(PasswordModalFragment)
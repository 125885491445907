import React, { Fragment } from 'react';
import { translate } from 'react-i18next';

import Button from '../../../material-components/Button';
import Box from '../../../material-components/Box';

function CloseRequestModalFragment({
  onSubmit,
  onCancel,
  t,
}: Props) {
  return (
    <Fragment>
      <Box textAlign="center">
        {t('MODAL_SUBMIT_CLOSE_REQUEST')}
      </Box>
      <Box mt={4}>
        <Button outlined style={{ width: '100%' }} onClick={onSubmit}>{t('MODAL_CLOSE_REQUEST')}</Button>
      </Box>
      <Box mt={1}>
        <Button outlined style={{ width: '100%' }} color="secondary" onClick={onCancel}>{t('COMMON_CANCEL')}</Button>
      </Box>
    </Fragment>
  );
}

export default translate()(CloseRequestModalFragment);

// @flow

/**
 *      Creates stateless UI component for QuestionPage with list of questions
 *
 *      suggestions                 - String[] with questions, NOT required(but it has no sense without suggestions)
 *      suggestionMouseDownHandler  - (text: string) => {}, callback for suggestion, NOT required
 *      headerText                  - text for header, NOT required
 */

import React from 'react';

import './styles.less'
import MaterialIcon from '@material/react-material-icon';

import Suggestion from "./Suggestion";
import IconButton from '../../material-components/IconButton';

type Props = {
    isSuggestionsShown: boolean;
    helpList?: Array<any>,
    onCloseSuggestionsClick: Function,
    suggestionMouseDownHandler?: Function,
    onSuggestionsClick: Funstion,
    headerText?: string,
}

const Suggestions = (props: Props) => (
    <div onMouseDown={props.onSuggestionsClick} onTouchStart={props.onSuggestionsClick}>
        <div className={`suggestions-overlay ${props.isSuggestionsShown ? 'suggestions-overlay-shown' : ''}`} />
        <div className="suggestions">
            <header>{props.headerText}</header>
            <div className="close-button-wrapper">
                <IconButton
                    size="small"
                    onMouseDown={props.onCloseSuggestionsClick}
                    onTouchStart={props.onCloseSuggestionsClick}
                >
                    <MaterialIcon icon="clear" />
                </IconButton>
            </div>
            <ul>
                {props.helpList.map(({ text, question }, i) =>
                    <Suggestion
                        text={text}
                        question={question}
                        mouseDownHandler={props.suggestionMouseDownHandler}
                        key={i}
                    />
                )}
            </ul>
        </div>
    </div>
);

Suggestions.defaultProps = {
    helpList: [],
    suggestionMouseDownHandler: null,
    headerText: '',
};

export default Suggestions;

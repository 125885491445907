import Layer from '@layerhq/web-xdk';
import i18next from 'i18next';

import {bytesToSize} from "../utils/common";
import {isHcp} from "../utils/user";
import config from "../config.json";

const LayerFileMessageViewMixin = {
  methods: {
    onAfterCreate: function () {
      this.needHcpCheck = this.model.onlyForHcp && !isHcp(Layer.client.user);

      const view = this;
      const titleText = view.properties.model.title
      view.messageViewer.querySelector('.layer-card-body-outer').style.display = 'none';
      view.classList.add('layer-message-type-view__operator');

      let dbg = this.model.score;
      if (this.model.matchedTags &&  this.model.matchedTags.length) {
        dbg = this.model.matchedTags.join(', ');
      }

      const title = document.createElement('h3');
      const titleInnerText = `${titleText.length < 15 ? titleText : titleText.split('.')[0].slice(0, 7) + "..." + titleText.split('.')[0].slice(-5) + "." + titleText.split('.')[1]}`
      title.innerHTML = `${titleInnerText} ${config.debug ? `<sup><em>${dbg}</em></sup>` : ''}`;

      const downloadIcon = document.createElement('i')
      downloadIcon.classList.add('material-icons')
      downloadIcon.innerText = 'cloud_download'
      view.appendChild(downloadIcon)

      const descriptionContainer = document.createElement('div')
      descriptionContainer.classList.add('description-container')

      const description = view.properties.model.customData.description;
      const size = view.properties.model.size;
      const downloadText = document.createElement('span')
      downloadText.classList.add('download-text')
      downloadText.classList.toggle('only-for-hcp', this.needHcpCheck);
      downloadText.innerText = this.needHcpCheck
        ? `${i18next.t('ANSWER_FOR_HCP_ONLY')}`
        : `${(description && description.length) ? description : `${i18next.t('COMMON_DOWNLOAD_TO_OPEN')} ${bytesToSize(size)}`}`;

      descriptionContainer.appendChild(downloadText)
      view.appendChild(descriptionContainer)
    }
  }
};

export default LayerFileMessageViewMixin;

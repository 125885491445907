// @flow
import Parser from 'html-react-parser';
import * as React from 'react';
import Contents from './Contents/index';
import forEach from '../../polyfills/forEach';

import {retrieveDocument} from './../../utils/api';

import './styles.less';
import AlloyFinger from "alloyfinger";
import {translate} from 'react-i18next';
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {isIE} from "../../utils/common";

type Props = {
  prevLocation: string;
  history: any;
  t: Function;
}

type State = {
  slug: string,
  title: string,
  content: string,
  opened: boolean,
  openedConfirmDeletion: boolean,
  prevLocation: string,
  isDocumentLoaded: boolean,
};

// TODO: remove after test
const demoDocument = {
  title: 'Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis?',
  content: '<h1>Heading 1 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. At, cupiditate dignissimos dolorum eligendi enim excepturi harum iure magnam mollitia voluptas. Adipisci aperiam distinctio doloremque neque nihil omnis possimus, vel voluptatum!</h1>\n' +
  '<h2>Heading 2 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos ipsa, maxime! Aut, consequatur, debitis? Accusantium animi consectetur dicta dolore et ex, facere hic laboriosam libero molestias mollitia nesciunt praesentium suscipit.</h2>\n' +
  '<h3>Heading 3 - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus consectetur culpa, delectus distinctio dolore eius expedita facere id ipsa ipsum itaque labore maiores officia quaerat quam quo similique ullam vero.</h3>\n' +
  '<p><strong>Paragraph</strong> - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus dolorem molestiae necessitatibus officiis provident qui veniam voluptate voluptatibus? Aspernatur culpa error est harum possimus quaerat tenetur voluptate. Illo, incidunt, voluptate.</p>\n' +
  '<strong>Strong - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias aperiam beatae consequatur, culpa dolores eaque eos esse, et facere libero nulla praesentium, quis ratione reprehenderit sint sit tenetur ullam velit?</strong>\n' +
  '<em>Em - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit incidunt odio vitae voluptas! Alias, aliquid animi beatae dolorem, eum facere fugit iste quasi rem reprehenderit unde veniam! Expedita, officiis, sequi?</em>\n' +
  '<blockquote>\n' +
  '    quote - Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, adipisci architecto consequuntur cum dolore dolorum error, est expedita nisi officia praesentium quam, sequi similique sint sit tempora veritatis? Ipsa, quod?\n' +
  '</blockquote>\n' +
  '<p class="caption">Caption - Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus alias aliquid, culpa, cumque dolorem, dolorum id inventore maxime minima mollitia nemo nesciunt numquam qui quos reiciendis repellat sapiente similique sunt.</p>\n' +
  '<a><i class="material-icons">link</i>Link</a>',
};

class Document extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      slug: this.getOriginalPathname(this.props.match.params[0]),
      content: '',
      navLinksArray: [],
      contentMenu: null,
      scrollTop: 0,
      title: '',
      opened: false,
      openedConfirmDeletion: false,
      isDocumentLoaded: false,
      anchorFromUrl: null
    };
    this.smallHeaderRef = React.createRef();
    this.headerRef = React.createRef();
    this.contentsRef = React.createRef();
    this.containerRef = React.createRef();
    this.textRef = React.createRef();
    this.spikeRef = React.createRef();
    this.shareRef = React.createRef();
    this.arrowBackRef = React.createRef();
    this.backgroundMenuRef = React.createRef();
  }

  componentDidMount(): void {
    const { prevLocation } = this.props;
    this.setState({ prevLocation });

    let hash;
    if (!!window.location.hash) {
      hash = window.location.hash.substr(1);
    }

    if (this.state.slug === 'document') {
      this.setState(demoDocument, this.onScroll);
      this.defaultSize = this.headerRef.current.offsetHeight;
      this.backgroundMenuRef.current.setAttribute('style', `max-height: ${this.defaultSize}px`);
      this.setState({isDocumentLoaded: true})
    } else {
      retrieveDocument(this.state.slug)
        .then((res) => {
          const title = res.data.html.match(/<h1>(.*?)<\/h1>/)[1];
          let content = res.data.html.replace(title, '')
          this.setState({title, content, isDocumentLoaded: true}, this.onScroll)
          this.defaultSize = this.headerRef.current.offsetHeight;
          this.backgroundMenuRef.current.setAttribute('style', `max-height: ${this.defaultSize}px`);
          const coords = document.getElementById(`${hash}`) && (window.pageYOffset + document.getElementById(`${hash}`).getBoundingClientRect().top);
          this.setState({anchorFromUrlCoordinates: coords})

          const [shareButton, submitButton] = this.textRef.current.getElementsByTagName('button');

          if (shareButton && submitButton) {
            shareButton.addEventListener('click', this.onClickShare);
            submitButton.addEventListener('click', this.clickOk);
          }
        })
    }
    window.addEventListener('scroll', this.onScroll);
    window.addEventListener('resize', this.onScroll);
    if (!this.checkClassName(this.containerRef.current.classList, 'fade-enter') && !this.checkClassName(this.containerRef.current.classList, 'fade-enter-active')) {
      this.contentsRef.current.className = `${this.contentsRef.current.className} first-page`;

      if (this.isContentsHidden()) {
        this.contentsRef.current.className = `${this.contentsRef.current.className} hide-contents`;
      }
    }

    this.updateStyle();
    window.addEventListener('resize', this.updateStyle);
    this.addAlloy();
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onScroll);
    window.removeEventListener('resize', this.updateStyle);
  }


  addAlloy = () => {
    const OPEN_TRANSFORM_X_VALUE = -162;

    const getInnerNodeTransformValue = () => this.headerRef.current && this.headerRef.current.translateX || 0;
    const getTitleToggleStatus = () => this.iconRef.current && this.iconRef.current.style.display !== 'none';

    let startPosition = 0;

    if ('ontouchstart' in document.documentElement) {
      window.Transform(this.headerRef.current);
      const headerElement = this;

      const openActionPanel = () => {
        this.headerRef.current.translateX = OPEN_TRANSFORM_X_VALUE;
        windowEventHandler.on('touchEnd', closeHeaderActionEvent);
      };

      const closeActionPanel = () => {
        this.setState((prevState, props) => ({openedConfirmDeletion: false}));
        if (this.headerRef.current) {
          this.headerRef.current.translateX = 0;
        }
        windowEventHandler.off('touchEnd', closeHeaderActionEvent);
      };
      const toggleActionPanel = () => {
        const isOpen = this.headerRef.current.translateX === OPEN_TRANSFORM_X_VALUE;
        isOpen ? closeActionPanel() : openActionPanel();
      };

      const closeHeaderActionEvent = (event) => {
        closeActionPanel();
      };

      const windowEventHandler = new AlloyFinger(window, {});

      new AlloyFinger(this.spikeRef.current, {
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
          let x = headerElement.headerRef.current.translateX || 0;
          const swipe = Math.abs(startPosition - x) > 30;

          if (
            (startPosition === OPEN_TRANSFORM_X_VALUE && (swipe || x === startPosition)) ||
            (startPosition === 0 && !swipe && x !== startPosition)
          ) {
            closeActionPanel();
          } else if (
            (startPosition === -212 && !swipe) ||
            (startPosition === 0 && swipe) ||
            (x === startPosition && x === 0)
          ) {
            openActionPanel();
          }

        }
      });

      new AlloyFinger(this.backgroundMenuRef.current, {
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
        }
      });
      new AlloyFinger(this.arrowBackRef.current, {
        touchEnd: function (event) {
          event.preventDefault();
          event.stopPropagation();
          headerElement.clickBack();
        }
      });
      new AlloyFinger(this.shareRef.current, {
        tap: function (event) {
          closeActionPanel();
          headerElement.handleShareClick();
        }
      });

      new AlloyFinger(this.headerRef.current, {
        touchStart: function (event) {
          startPosition = this.translateX;
        },
        tap: function (event) {
          event.stopPropagation();
          event.preventDefault();
        },
        pressMove: function (event) {
          let x = this.translateX + event.deltaX;

          if (x > 0) {
            x = 0;
          } else if (x < -212) {
            x = -212;
          }

          this.translateX = x;
        },
        touchEnd: function (event) {
          event.stopPropagation();
          event.preventDefault();
          let x = this.translateX || 0;
          const swipe = Math.abs(startPosition - x) > 30;

          if (
            (startPosition === OPEN_TRANSFORM_X_VALUE && swipe) ||
            (startPosition === 0 && !swipe) ||
            (x === startPosition && x === OPEN_TRANSFORM_X_VALUE)
          ) {
            closeActionPanel();
          } else if (
            (startPosition === OPEN_TRANSFORM_X_VALUE && !swipe) ||
            (startPosition === 0 && swipe)
          ) {
            openActionPanel();
          }
        },
      });
    } else {
      window.Transform(this.headerRef.current);

      const closeActionPanel = () => {
        this.setState((prevState, props) => ({openedConfirmDeletion: false}));
        this.headerRef.current.translateX = 0;
        window.removeEventListener('mousedown', toggleHeaderActionEvent);
      };

      const openActionPanel = () => {
        this.headerRef.current.translateX = OPEN_TRANSFORM_X_VALUE;
        window.addEventListener('mousedown', toggleHeaderActionEvent);
      };

      const toggleHeaderActionEvent = (event) => {
        getInnerNodeTransformValue() !== OPEN_TRANSFORM_X_VALUE ? openActionPanel() : closeActionPanel();
        window.removeEventListener('mousedown', toggleHeaderActionEvent);
      };

      const mouseMove = (event) => {
        let x = event.movementX + getInnerNodeTransformValue();

        if (x > 0) {
          x = 0;
        } else if (x < OPEN_TRANSFORM_X_VALUE) {
          x = OPEN_TRANSFORM_X_VALUE;
        }

        this.headerRef.current.translateX = x;
      };

      const mouseUp = (event) => {
        let x = getInnerNodeTransformValue();

        if (Math.abs(startPosition - x) > 2) {
          x < (OPEN_TRANSFORM_X_VALUE / 2) ? openActionPanel() : closeActionPanel();

        } else if (x === startPosition) {
          x === OPEN_TRANSFORM_X_VALUE ? closeActionPanel() : openActionPanel();
        } else {
          x < OPEN_TRANSFORM_X_VALUE / 2 ? openActionPanel() : closeActionPanel();
        }

        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('mouseup', mouseUp);
      };

      const swipe = (event) => {
        event.preventDefault();
        event.stopPropagation();
        startPosition = getInnerNodeTransformValue();

        const removeSwipe = (event) => {
          window.removeEventListener('click', removeSwipe);
          window.removeEventListener('mousemove', mouseMove);
        };

        window.addEventListener('click', removeSwipe);
        window.addEventListener('mousemove', mouseMove);
        window.addEventListener('mouseup', mouseUp);
      };

      this.spikeRef.current.addEventListener('mousedown', swipe);

      this.shareRef.current.addEventListener('click', (event) => {
        closeActionPanel();
        this.onClickShare();
      });
    }
  }

  getOriginalPathname = (url) => url.replace(/(shared|share)/, '');

  onClickShare = () => {
    const { pathname, hash } = this.props.location;
    this.props.history.push(`${pathname}/share${hash}`);
  };

  updateStyle = () => {
    // set default size to backgroundMenu
    if (!this.state.opened) {
      // lineClamp(this.titleRef.current, 3);
      this.defaultSize = this.headerRef.current.offsetHeight;
      this.backgroundMenuRef.current.setAttribute('style', `max-height: ${this.defaultSize}px`);
    }
  };

  checkClassName = (classList, className) => {
    let isHere = false;
    forEach(classList, (item) => {
      isHere = item === className ? true : isHere;
    });
    return isHere;
  };

  isContentsHidden = () => {
    const {pathname} = this.props.history.location;
    return pathname.includes('videos');
  };

  clickBack = () => {
    this.props.history.push(this.props.prevLocation);
  };

  clickOk = () => {
    this.props.history.push(this.state.prevLocation);
  };

  onScroll = () => {
    if (this.textRef.current.clientHeight >= document.documentElement.clientHeight + 200 + this.headerRef.current.clientHeight) {
      if (window.pageYOffset > this.headerRef.current.clientHeight + 200) {
        this.smallHeaderRef.current.style.top = '0';
        this.contentsRef.current.style.top = this.smallHeaderRef.current.clientHeight + 20 + 'px';
      } else if (window.pageYOffset < this.headerRef.current.clientHeight + 100) {
        this.smallHeaderRef.current.style.top = '-' + this.smallHeaderRef.current.clientHeight + 5 + 'px';
        this.contentsRef.current.style.top = (this.smallHeaderRef.current.clientHeight + ((this.headerRef.current.clientHeight - this.smallHeaderRef.current.clientHeight) * (this.headerRef.current.clientHeight - window.pageYOffset + 100) / (this.headerRef.current.clientHeight + 100)) + 20) + 'px';
      } else if ((window.pageYOffset > this.headerRef.current.clientHeight + 100) && (window.pageYOffset < this.headerRef.current.clientHeight + 200)) {
        this.contentsRef.current.style.top = this.smallHeaderRef.current.clientHeight + 20 + 'px';
        this.smallHeaderRef.current.style.top = '-' + (this.smallHeaderRef.current.clientHeight * (1 - (window.pageYOffset - 100 - this.headerRef.current.clientHeight) / 100)) + 'px';
      }
    } else {
      this.smallHeaderRef.current.style.top = '-' + this.smallHeaderRef.current.clientHeight + 5 + 'px';
      this.contentsRef.current.style.top = (this.smallHeaderRef.current.clientHeight + ((this.headerRef.current.clientHeight - this.smallHeaderRef.current.clientHeight) * (this.headerRef.current.clientHeight - window.pageYOffset + 100) / (this.headerRef.current.clientHeight + 100)) + 20) + 'px';
    }

    const numberRegEx = /^\d/
    const navLinksArray = []

    const regEx = /[0-9]{1,}.[0-9]{1,}./

    if (!this.state.contentMenu) {
      for (let i = 0; i < this.textRef.current.childNodes.length; i++) {
        (this.textRef.current.childNodes[i].localName === 'h2' ||
          this.textRef.current.childNodes[i].localName === 'h3') &&
        (numberRegEx).test(this.textRef.current.childNodes[i].innerText) &&
        navLinksArray.push(this.textRef.current.childNodes[i])
      }

      const temp = navLinksArray.map((item) => {
          if (regEx.test(item.innerText.replace(/[^\d.-]/g, ''))) {
            return item.innerText
          } else {
            return item.innerText
          }
        }
      )

      const formatted = [];
      let count = 0;
      temp.forEach(elem => {
        const isSubsection = /^\d\.\d\./g.test(elem);
        if (isSubsection) {
          if (Array.isArray(formatted[count])) {
            formatted[count].push(elem);
          } else {
            count++;
            formatted[count] = [elem];
          }
        } else {
          count++;
          formatted.push(elem);
        }
      })

      this.setState({
        contentMenu: formatted
      })
    }

    !this.state.navLinksArray.length && this.setState({navLinksArray: navLinksArray})
  };

  render() {
    const {t} = this.props;
    const {isDocumentLoaded, anchorFromUrl, anchorFromUrlCoordinates} = this.state;
    return (
      <div ref={this.containerRef} className={'document-container'}>
        <header
          ref={this.smallHeaderRef}
          className={'small-header'}>
          <div>
            <button
              className={'back'}
              onClick={this.clickBack}
            >
              <i className="material-icons">arrow_back</i>
            </button>
            <h1>{this.state.title}</h1>
            <Contents
              containerRef={this.contentsRef}
              contentMenu={this.state.contentMenu}
              textRef={this.textRef}
              headerRef={this.headerRef}
              anchorFromUrl={anchorFromUrl}
              anchorFromUrlCoordinates={anchorFromUrlCoordinates}
              navLinksArray={this.state.navLinksArray}
            />
          </div>
        </header>
        <header
          ref={this.headerRef}
        >
          <div>
            <button
              ref={this.arrowBackRef}
              className={'back'}
              onClick={this.clickBack}
            >
              <i className="material-icons">arrow_back</i>
            </button>
            <h1>{this.state.title}</h1>
            {/*<Contents*/}
            {/*containerRef = { this.contentsRef }*/}
            {/*contentMenu = { this.state.contentMenu }*/}
            {/*/>*/}
          </div>
          <div ref={this.spikeRef} className={'spike'}>
            <div></div>
          </div>
        </header>
        <div ref={this.backgroundMenuRef}
             className={`background-menu `}>
          <ul>
            <li><i className="material-icons">outlined_flag</i>
              {`${t('COMMON_MARK')}`}
            </li>
            <li
              ref={this.shareRef}
              onClick={this.handleShareClick}
            >
              <i className="material-icons">share</i>
              {`${t('COMMON_SHARE')}`}
            </li>
          </ul>
        </div>
        {
          // !isDocumentLoaded &&
          <LoadingSpinner
            className={`loading-spinner__document-page ${isDocumentLoaded ? 'loading-spinner__document-page--loaded' : ''}`}
          >
          </LoadingSpinner>
        }
        {
          isIE() ?
            <div className={'main'}>
              <div ref={this.textRef} className={"content"}>{Parser(this.state.content)}</div>
            </div> :
            <main>
              <div ref={this.textRef} className={"content"}>{Parser(this.state.content)}</div>
            </main>
        }

      </div>
    )
  }
}

export default translate()(Document);
import Layer from '@layerhq/web-xdk';
import textHandlers from '@layerhq/web-xdk/ui/handlers/text/text-handlers';
import { CHOICE_ACTIONS, AUTOSTART_ACTIONS, CHOICE_ACTION_EVENTS } from '../const/choice-actions';
import settings from "@layerhq/web-xdk/settings";
import _get from 'lodash/get';

const ChoiceMessageViewMixin = {
  methods: {
    sendSelection: function () {
      // TODO: unify sendSelection and _sendChoice
      const view = this;
      const listItem = view.closest('.layer-list-item');
      const conversationId = view.messageViewer.message.conversationId;
      let choiceMessageID = view.closest('layer-message-item-received');
      if (!choiceMessageID) {
        choiceMessageID = view.closest('layer-message-item-sent');
      }
      choiceMessageID = choiceMessageID.getAttribute('layer-item-id').split('_')[1];
      const model = view.properties.model;
      const ids = model.selectedAnswer.split(',');
      const result =  model.choices.reduce(function (result, next) {
          if (ids.indexOf(next.id) > -1) {
            if (result.id) {
              result.id = `${result.id}; ${next.id}`;
              result.text = `${result.text}; ${next.text}`;
            } else {
              result.id = next.id;
              result.text = next.text;
            }
          }
          return result;
        }, {text: '', id: ''});

      if (result.id) {
        if (model.customData) {
          result.customData = {action: model.customData.action};
        }
        result.conversationId = conversationId;
        result.customData.choiceMessageID = choiceMessageID;
        result.customData.isMultiSelect = model.allowMultiselect;
        listItem.className = `${listItem.className} xircles-hiding`;
        setTimeout(() => {
          view.trigger('xircles-choice-send', result);
          listItem.classList.add('xircles-hide');
        }, 100);
      }
    },
    _sendChoice(result) {
      const view = this;
      // send ResponseMessage
      view.onChoiceSelect({ id: result.id });
      const rootPart = view.model.message.getPartsMatchingAttribute({role: 'root'})[0];
      const rootModel = settings.client.getMessageTypeModel(rootPart.id);
      view.trigger(view.model.responseName, {
        model: view.model,
        data: view.model,
        rootModel,
      });

      // send ConfirmChoiceMessage
      const listItem = view.closest('.layer-list-item');
      const conversationId = _get(view, 'messageViewer.message.conversationId');
      let choiceMessageID = view.closest('layer-message-item-received');
      if (!choiceMessageID) {
        choiceMessageID = view.closest('layer-message-item-sent');
      }
      choiceMessageID = choiceMessageID.getAttribute('layer-item-id').split('_')[1];
      const model = view.properties.model;
      if (model.customData) {
        result.customData = {action: model.customData.action};
      }
      result.conversationId = conversationId;
      result.customData.choiceMessageID = choiceMessageID;
      result.customData.isMultiSelect = model.allowMultiselect;
      listItem.className = `${listItem.className} xircles-hiding`;
      setTimeout(() => {
        view.trigger('xircles-choice-send', result);
        // hide choices list initially. Will be deleted on server too.
        listItem.classList.add('xircles-hide');
      }, 100);
    },
    _searchAutoStartActions(listItem, evtName, choiceId) {
      const searchAutoStartAction = AUTOSTART_ACTIONS.find((action) => action.actionId === choiceId);

      if (searchAutoStartAction) {
        // if only one autostart action id was found, whole choices list will be hidden
        listItem.classList.add('xircles-hide');

        const { disableMessage } = searchAutoStartAction;
        this._triggerAutoStartActions(evtName, disableMessage);
      }
    },
    _triggerAutoStartActions(evtName) {
      const lastMsgId = this.mainComponent.conversation.lastMessage.id;
      const id = this.properties.messageViewer.item.id;
      const isLastMsg = lastMsgId.endsWith(id);
      if (isLastMsg ) {
        // and autostart triggered if it last message
        window.dispatchEvent(new Event(evtName));
      }
    },
    _loginCanceled() {
      this._sendChoice({ text: 'Login canceled', id: `${CHOICE_ACTIONS.LOGIN}_${CHOICE_ACTIONS.CANCEL}` });
    },
    _loginSucceded() {
      this._sendChoice({ text: 'Login succeeded', id: CHOICE_ACTIONS.LOGIN });
    },
    _docCheckCanceled() {
      this._sendChoice({ text: 'DocCheck login canceled', id: `${CHOICE_ACTIONS.DOCCHECK}_${CHOICE_ACTIONS.CANCEL}` });
    },
    _docCheckSucceded() {
      this._sendChoice({ text: 'DocCheck login succeeded', id: CHOICE_ACTIONS.DOCCHECK });
    },
    _scanEfnCanceled() {
      this._sendChoice({ text: 'Scan EFN number canceled', id: `${CHOICE_ACTIONS.SCANEFN}_${CHOICE_ACTIONS.CANCEL}` });
    },
    _scanEfnSucceded(e) {
      this._sendChoice({ text: e.detail, id: CHOICE_ACTIONS.SCANEFN });
    },
    _confirmRegistrationCanceled() {
      this._sendChoice({
        text: 'Confirm registration canceled',
        id: `${CHOICE_ACTIONS.CONFIRM_REGISTER}_${CHOICE_ACTIONS.CANCEL}` });
    },
    _confirmRegistrationSucceded() {
      this._sendChoice({ text: 'Confirm registration succeeded', id: CHOICE_ACTIONS.CONFIRM_REGISTER });
    },
    onAfterCreate: {
      mode: Layer.UI.registerComponent.MODES.OVERWRITE,
      value() {
        const view = this;
        const listItem = view.closest('.layer-list-item');

        this.model.choices.forEach((choice) => {
          let evtName = 'layer-choice-select';
          if (CHOICE_ACTION_EVENTS[choice.id]) {
            evtName = CHOICE_ACTION_EVENTS[choice.id];
            switch (choice.id) {
              case CHOICE_ACTIONS.LOGIN:
                window.addEventListener(CHOICE_ACTION_EVENTS.login_cancel, this._loginCanceled.bind(this));
                window.addEventListener(CHOICE_ACTION_EVENTS.login_success, this._loginSucceded.bind(this));
                break;

              case CHOICE_ACTIONS.DOCCHECK:
                window.addEventListener(CHOICE_ACTION_EVENTS.doccheck_cancel, this._docCheckCanceled.bind(this));
                window.addEventListener(CHOICE_ACTION_EVENTS.doccheck_success, this._docCheckSucceded.bind(this));
                break;

              case CHOICE_ACTIONS.SCANEFN:
                window.addEventListener(CHOICE_ACTION_EVENTS.scanefn_cancel, this._scanEfnCanceled.bind(this));
                window.addEventListener(CHOICE_ACTION_EVENTS.scanefn_success, this._scanEfnSucceded.bind(this));
                break;

              case CHOICE_ACTIONS.CONFIRM_REGISTER:
                window.addEventListener(
                  CHOICE_ACTION_EVENTS.confirm_register_cancel, this._confirmRegistrationCanceled.bind(this)
                );
                window.addEventListener(
                  CHOICE_ACTION_EVENTS.confirm_register_success, this._confirmRegistrationSucceded.bind(this)
                );
                break;

              default:
                const r = new RegExp(`_${CHOICE_ACTIONS.CANCEL}$`);
                if (r.test(choice.id)) {
                  // hide _cancel choices. used only as response
                  evtName = 'hide';
                }
                break;
            }

            this._searchAutoStartActions(listItem, evtName, choice.id);
          }
          const button = view.createElement('layer-action-button', {
            text: choice.text,
            tooltip: choice.tooltip,
            event: evtName,
            data: {id: choice.id},
            parentNode: view.nodes.choices,
          });
          if (evtName === 'hide') {
            button.classList.add('xircles-hide');
          }
        });
        this.nodes.label.innerHTML = textHandlers.processText(this.model.label);
        const conversationId = view.messageViewer.message.conversationId;
        const div = document.createElement('div');
        view.appendChild(div);
        // add send button
        view.nodes.sendButton = document.createElement('button');
        view.nodes.sendButton.classList.add('xircles-icons', 'icon-send');
        view.nodes.sendButton.innerHTML = 'Send answer';
        div.appendChild(view.nodes.sendButton);

        // add back button
        if (view.properties.model.customData.showBack) {
          view.nodes.backButton = document.createElement('button');
          view.nodes.backButton.classList.add('xircles-icons', 'icon-up');
          view.nodes.backButton.innerHTML = 'Back';
          div.appendChild(view.nodes.backButton);
          view.nodes.backButton.addEventListener('click', () => {
            view.trigger('xircles-choice-send', {conversationId, text: 'back', id: 'back'});
            listItem.classList.add('xircles-hide');
          });
        }

        if (view.properties.model.customData.orientation === 'landscape') {
          view.nodes.choices.classList.add('xircles-choice-list-landscape');
        }

        if (view.properties.model.allowMultiselect || view.properties.model.allowReselect) {
          view.nodes.choices.childNodes.forEach((child) => {
            child.firstChild.classList && child.firstChild.classList.add('xircles-icons', 'icon-circle');
          });
          view.nodes.sendButton.addEventListener('click', view.sendSelection.bind(view));
        } else {
          view.nodes.sendButton.classList.add('xircles-hide');
          // single choice on click
          view.addEventListener('layer-choice-select', view.sendSelection.bind(view));
        }
      }
    },
    onDestroy() {
      window.removeEventListener(CHOICE_ACTION_EVENTS.login_cancel, this._loginCanceled.bind(this));
      window.removeEventListener(CHOICE_ACTION_EVENTS.login_success, this._loginSucceded.bind(this));

      window.removeEventListener(CHOICE_ACTION_EVENTS.doccheck_cancel, this._docCheckCanceled.bind(this));
      window.removeEventListener(CHOICE_ACTION_EVENTS.doccheck_success, this._docCheckSucceded.bind(this));

      window.removeEventListener(CHOICE_ACTION_EVENTS.scanefn_cancel, this._scanEfnCanceled.bind(this));
      window.removeEventListener(CHOICE_ACTION_EVENTS.scanefn_success, this._scanEfnSucceded.bind(this));

      window.removeEventListener(
        CHOICE_ACTION_EVENTS.confirm_register_cancel,
        this._confirmRegistrationCanceled.bind(this)
      );
      window.removeEventListener(
        CHOICE_ACTION_EVENTS.confirm_register_success,
        this._confirmRegistrationSucceded.bind(this)
      );
    }
  }
};

export default ChoiceMessageViewMixin;

// @flow
import React from "react";
import ButtonBase from "@material/react-button";
import "./style.scss";

type Props = {
  color?: string
};

export default function Button(props: Props) {
  const { color, ...buttonProps } = props;
  if (color === "secondary") {
    return <ButtonBase className="mdc-button--secondary" {...buttonProps} />;
  }
  return <ButtonBase {...buttonProps} />;
}

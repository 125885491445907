// @flow
/**
 * This UI Component manages the Layer Conversations.
 *
 * It provides a Conversation List on the left, and a Conversation View on the right.
 *
 * It also uses an Identity List via the EditCOnversationDialog.js file to create new Conversations.
 */

import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import _get from 'lodash/get';
import shorthash from 'shorthash';

import {LayerReactComponents, Layer} from '../../get-layer';
import getMenuOptions from "./sample-menu";
import hideVirtualKeyboard from '../../hideVirtualKeyboard';
import {customFileSelector} from '../../custom-message-types';
import {CHOICE_ACTION_EVENTS} from '../../const/choice-actions';
import translateInternalStatus from '../../common/status/translateInternalStatus';
import { addParticipantConversation, getIdentityById } from '../../utils/api';
import { WELCOME_MODAL_TYPES } from '../../const/welcome-modal-types';
import INTERNAL_STATUS from '../../const/status/internal-status';

import './styles.less';
import Header from './Header';
import {isOperator} from "../../utils/user";
import translatePvStatus from "../../common/status/translatePvStatus";
import translateOperatorInternalStatus from "../../common/status/translateOperatorInternalStatus";

const {uuid} = Layer.Utils;

const POPUP_AUTOSTART_DELAY_MS = 2000;

// Extract the Layer XDK UI Components that are to be used in this Project
const {
  ConversationView,
  SendButton,
  FileUploadButton,
  MenuButton,
} = LayerReactComponents;

type Props = {
  history: any,
  location: any,
  match: any
};

type State = {
  conversation: any,
  xirclesStatus: string,
  xirclesOperatorStatusFirst: string,
  xirclesOperatorStatusSecond: string,
  isLoaded: boolean,
  isAppMenuOpen: boolean,
  conversationId: string,
  showEditConversationDialog: boolean,
  editConversationId: string,
  tabSelected: string,
  startState: string
};

class AnswerPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      conversationId: '',
      conversation: null,
      xirclesStatus: '',
      xirclesOperatorStatusFirst: '',
      xirclesOperatorStatusSecond: '',
      isLoaded: false,
      isAppMenuOpen: false,
      showEditConversationDialog: false,
      editConversationId: '',
      tabSelected: 'conversations', // 'conversations', 'bookmarks', 'info'
      startState: '',
      question: '',
      isTakeOverActive: false
    };
    this.leftPanelFixed = false;
    this.query = null;
    this.identityQuery = null;
    this.viewRef = React.createRef();
    this.additionalsRef = React.createRef();
    this.addPhotoRef = React.createRef();
    this.photoRef = React.createRef();
    this.searchRef = React.createRef();
    this.backRef = React.createRef();
  }

  /**
   * As part of loading this Component, verify that we have an authenticated Client.
   *
   * Redirect to the Login page if we do not.
   *
   * Note that your production applications will _not_ send users to a Login page if their Layer Client isn't authenticated,
   * it will authenticate for them based on the session token they have with your own servers. Only if your *own* session is not authenticated
   * would you send users to a Login page.  But... this is a Demo.
   */
  componentWillMount() {
    const { layerClient, history, location } = this.props;
    window.addEventListener('xircles-push-history', (path) => {
      history.push({
        pathname: path,
        previousLocation: {pathname: location.pathname}
      });
    });
    layerClient.on('challenge', e => {
      history.push({
        pathname: '/',
        previousLocation: {pathname: location.pathname}
      });
    }, this);

    const conversationId = _get(this.props.match, 'params.conversationId');
    const hashParam = _get(this.props.match, 'params.hash');

    if (conversationId && !hashParam) {
      this.waitForLayerClient(conversationId, layerClient);
    } 
  }

  componentDidMount() {
    // fix scroll
    document.querySelector('html').setAttribute('style', 'overflow: hidden');

    window.addEventListener(CHOICE_ACTION_EVENTS.login, this.showLogin);
    window.addEventListener(CHOICE_ACTION_EVENTS.doccheck, this.showDocCheckLogin);
    window.addEventListener(CHOICE_ACTION_EVENTS.cancel, this.goToQuestionsPage);
    window.addEventListener(CHOICE_ACTION_EVENTS.reframe, this.goToQuestionsPageReframe);
    window.addEventListener(CHOICE_ACTION_EVENTS.scanefn, this.showScanEfnLogin);
    window.addEventListener(CHOICE_ACTION_EVENTS.confirm_register, this.showConfirmRegistration);
  }

  componentWillUnmount() {
    this.query && this.query.off();
    this.identityQuery && this.identityQuery.off();
    document.querySelector('html').removeAttribute('style');

    window.removeEventListener(CHOICE_ACTION_EVENTS.login, this.showLogin);
    window.removeEventListener(CHOICE_ACTION_EVENTS.doccheck, this.showDocCheckLogin);
    window.removeEventListener(CHOICE_ACTION_EVENTS.cancel, this.goToQuestionsPage);
    window.removeEventListener(CHOICE_ACTION_EVENTS.reframe, this.goToQuestionsPageReframe);
    window.removeEventListener(CHOICE_ACTION_EVENTS.scanefn, this.showScanEfnLogin);
    window.removeEventListener(CHOICE_ACTION_EVENTS.confirm_register, this.showConfirmRegistration);
  }

  waitForLayerClient = () => {
    const { layerClient } = this.props;

    if (layerClient.isReady) {
      this.prepareConversation(layerClient);
    } else {
      layerClient.once('ready', () => {
        this.prepareConversation(layerClient);
      });
    }
  };

  prepareConversation = async (layerClient) => {
    const conversationId = _get(this.props.match, 'params.conversationId');
    this.state.conversation = layerClient.getConversation(conversationId, true);
    this.setupConversation();
  };

  checkHashParam = (conversationId, hashParam) => {
    const checkUrl = `/questions/${conversationId}`;
    return shorthash.unique(checkUrl) === hashParam;
  };

  _showPopover = (pathFragment, detail) => {
    const { history } = this.props;
    if (!history.location.pathname.endsWith(pathFragment)) {
      history.push({
        pathname: history.location.pathname + '/' + pathFragment,
        state: { detail },
      });
    }
  };

  showLogin = () => {
    this._showPopover('login');
  };

  showDocCheckLogin = () => {
    this._showPopover('doccheck');
  };

  goToQuestionsPage = () => {
    this.props.history.push('/questions/');
  };

  goToQuestionsPageReframe = () => {
    this.props.layerClient.syncManager.once({
      'sync:success': () => {
        const { conversationName } = this.state.conversation.metadata;

        this.props.history.push({
          pathname: '/questions',
          state: { detail: { conversationName }},
        });
      },
    });

    this.state.conversation.delete(Layer.Constants.DELETION_MODE.ALL);
  };

  showScanEfnLogin = () => {
    this._showPopover('scan-efn');
  };

  showConfirmRegistration = async () => {
    const { layerClient, location } = this.props;
    try {
      const prevLocation = _get(location, 'state.detail.prevLocation');
      const isWelcomePrevLocation = /\/questions\/.{36}\/(welcome)/.test(prevLocation);

      if (!isWelcomePrevLocation) {
        const { userId: user_id } = layerClient.user;
        const { sessionToken: session_token } = layerClient;

        const { data: { email_address, phone_number } } = await getIdentityById({ user_id, session_token });
        const phoneNumberOrEmail = _isEmpty(email_address) ? phone_number : email_address;

        const detail = {
          modalType: WELCOME_MODAL_TYPES.REGISTER,
          phoneNumberOrEmail,
        };

        setTimeout(() => this._showPopover('code', detail), POPUP_AUTOSTART_DELAY_MS);
      }
    } catch(e) {
      console.error('Error during getting Identity by id:', e);
    }
  };

  /**
   * Now that we have a Conversation, setup event handlers on it to detect when its loaded,
   * and when its `metadata` property has changed; metadata changes typically mean a Conversation Name change
   * that needs to be rerendered.
   *
   * Note: This works off of `this.conversation` as its input, and this value may be `null`
   */
  setupConversation(con) {
    const { layerClient } = this.props;
    const conversation = con || this.state.conversation;
    //console.log(con || this.state.conversation);
    // If the conversation is still loading, wait for it to finish, and then set isLoaded to true
    if (conversation && conversation.isLoading) {
      conversation.once("conversations:loaded", () => {
        this.setState({
          isLoaded: true
        });
      });
    }

    // Watch for any changes to the metadata and update the conversationName
    if (conversation) {
      conversation.on(
        "conversations:change",
        evt => {
          if (evt.hasProperty("metadata")) {
            this.setState({
              xirclesStatus: translateInternalStatus(conversation.metadata.xircles_status),
              xirclesOperatorStatusFirst: translatePvStatus(conversation.metadata.pv_status),
              xirclesOperatorStatusSecond: translateOperatorInternalStatus(conversation.metadata.xircles_status),
              isTakeOverActive: conversation.metadata.xircles_status === INTERNAL_STATUS.TAKEOVER,
            });
          }
        },
        this
      );

      this.identityQuery = layerClient.createQuery({
        model: Layer.Core.Query.Identity,
      });
      this.identityQuery.on('change', () => {
        //console.log(this.identityQuery.data);
      });
    }
    // Setup our inital state
    this.setState({
      conversationId: conversation ? uuid(conversation.id) : "",
      conversation,
      xirclesStatus: conversation && translateInternalStatus(conversation.metadata.xircles_status),
      xirclesOperatorStatusFirst: conversation && translatePvStatus(conversation.metadata.pv_status),
      xirclesOperatorStatusSecond: conversation && translateOperatorInternalStatus(conversation.metadata.xircles_status),
      isTakeOverActive: conversation.metadata.xircles_status === INTERNAL_STATUS.TAKEOVER,
      isLoaded: conversation && !conversation.isLoading
    });
  }

  /**
   * Clear the selected conversation, navigates such that the `render` is called with no Conversation ID.
   */
  onConversationDeselected = () => {
    //window.location.href = "/questions/";
    this.props.history.push("/questions");
    /*this.setState({
      conversationId: ""
    });*/
  };

  /**
   * Navigate to the modal window to share the conversation
   */
  onConversationShared = () => {
    const {conversationId} = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/share`);
  };

  /**
   * Navigate to the modal window to forward the conversation
   */
  onConversationForward = () => {
    const {conversationId} = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/forward-to-medinfo`);
  };

  /**
   * Navigate to the modal window to deliver answer
   */
  onDeliverAnswer = () => {
    const {conversationId} = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/deliver-answer`);
  };

  /**
   * Navigate to the modal window to report PV
   */
  onConversationPVReporting = () => {
    const {conversationId} = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/clear-pv`);
  };

  /**
   * Navigate to the modal window to close request
   */
  onConversationCloseRequest = () => {
    const {conversationId} = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/close-request`);
  };

  /**
   * Navigate to the modal window to delete request
   */
  onConversationDeleteRequest = () => {
    const {conversationId} = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/delete-request`);
  };

  /**
   * Navigate to the modal window to share the conversation
   */
  onConversationDelete = () => {
    this.props.layerClient.syncManager.once({
      'sync:success': () => this.props.history.push('/questions')
    });

    this.state.conversation.delete(Layer.Constants.DELETION_MODE.ALL);
  };

  onClickProvideContent = () => {
    const {conversationId} = this.props.match.params;
    this.props.history.push(`/questions/${conversationId}/provide-content`);
  }

  /**
   * Whenever properties change, determine if the Conversation ID has changed, and if so:
   *
   * * Unsubscribe to all events from the prior conversation
   * * Call setupConversation() with the new conversation
   */
  componentWillReceiveProps(props: Props) {
    if (
      !props.match.params.welcomeId &&
      this.props.match.params.conversationId !== props.match.params.conversationId
    ) {
      const conversationId = props.match.params.conversationId;
      const newConversation = conversationId
        ? this.props.layerClient.getConversation(conversationId)
        : null;
      if (this.state.conversation) this.state.conversation.off(null, null, this);
      this.state.conversation = newConversation;
      this.setupConversation();
    }

    if (!_isEqual(this.props.userId, props.userId)) {
      const conversationId = _get(this.props.match, 'params.conversationId');
      const hashParam = _get(this.props.match, 'params.hash');

      const isHashCompatible = this.checkHashParam(conversationId, hashParam);

      if (isHashCompatible) {
        this.handleDeepLink(conversationId, props.userId);
      }
    }
  }

  handleDeepLink = async (conversationId, userId) => {
    await addParticipantConversation(conversationId, userId);

    this.props.history.push(`/questions/${conversationId}`);
    this.waitForLayerClient();
  };

  /**
   * Certain types of messages can be filtered out of the Conversation View.
   *
   * Note: this does not at this time filter them out of the Conversation List's Last Message.
   * Just return `false` to prevent a message from rendering.
   */
  filterMessages(message: any) {
    const model = message.createModel();
    return (
      !model ||
      !(model.getModelName() === "ResponseModel" && !model.displayModel)
    );

    // Uncomment this to hide Response Messages sent by this user
    // return !(model.getModelName() === 'ResponseModel' && (message.sender === layerClient.user || !model.displayModel));
  }

  getTitle() {
    const { conversation } = this.state;
    let title;

    if (conversation) {
      if (conversation.isLoading) {
        title = "...";
      } else {
        title = conversation.metadata.conversationName;
      }
    }
    return title;
  }

  /**
   * The title of a Conversation is stored in `conversation.metadata.xircles_status`.
   */
  getStatus(type = 'xircle-status') {
    const {
      conversation: activeConversation, xirclesStatus, xirclesOperatorStatusFirst, xirclesOperatorStatusSecond
    } = this.state;
    let status;

    if (activeConversation) {
      // If the conversation is loading from the server, then just hold off rendering anything other than a placeholder
      if (activeConversation.isLoading) {
        status = "...";
      } else {
        if (type === 'xircle-status') {
            // If there is a conversation xirclesStatus, use it
          if (xirclesStatus) {
            status = xirclesStatus;
          } else {
            status = translateInternalStatus(activeConversation.metadata.xircles_status);
          }
        } else if (type === 'operator-first') {
          if (xirclesOperatorStatusFirst) {
            status = xirclesOperatorStatusFirst;
          } else {
            status = translatePvStatus(activeConversation.metadata.pv_status);
          }
        } else {
          if (xirclesOperatorStatusSecond) {
            status = xirclesOperatorStatusSecond;
          } else {
            status = translateOperatorInternalStatus(activeConversation.metadata.xircles_status);
          }
        }
      }

    } else {
      status = "...";
    }
    return status;
  }

  handleAdditionalsClick = () => {
    const [layerComposeBar] = document.getElementsByTagName('layer-compose-bar');
    layerComposeBar.classList.add('layer-compose-bar-transition');
    setTimeout(() => layerComposeBar.style.paddingLeft = '120px', 0);

    this.additionalsRef.current && this.additionalsRef.current.setAttribute('style', 'display: none;');

    this.addPhotoRef.current && this.addPhotoRef.current.setAttribute('style', 'opacity: 1;');
    this.photoRef.current && this.photoRef.current.setAttribute('style', 'opacity: 1;');
    this.searchRef.current && this.searchRef.current.setAttribute('style', 'opacity: 1;');
    this.backRef.current && this.backRef.current.setAttribute('style', 'opacity: 1;');
  };

  handleBackClick = () => {
    this.addPhotoRef.current && this.addPhotoRef.current.setAttribute('style', 'opacity: 0;');
    this.photoRef.current && this.photoRef.current.setAttribute('style', 'opacity: 0;');
    this.searchRef.current && this.searchRef.current.setAttribute('style', 'opacity: 0;');
    this.backRef.current && this.backRef.current.setAttribute('style', 'opacity: 0;');

    setTimeout(() => {
      const [layerComposeBar] = document.getElementsByTagName('layer-compose-bar');
      layerComposeBar.classList.add('layer-compose-bar-transition');
      setTimeout(() => layerComposeBar.style.paddingLeft = '35px', 0);

      this.additionalsRef.current && this.additionalsRef.current.setAttribute('style', 'display: block;');
    }, 200);
  };

  /**
   * The Conversation View allows for various regions of its panels and the Message Items in its list to be customized.
   *
   * Gather those customizations here.
   *
   * Initially we just add a Send Button, File Upload Button and our Samples Menu button to the Compose Bar.
   */
  customizeConversationView() {
    return {
      composerButtonPanelRight: () => {
        if (!isOperator(Layer.client.user)) {
          return (
            <div>
              <div className="composer-button-panel__actions">
                <button id="additionals" ref={this.additionalsRef} onClick={this.handleAdditionalsClick}><i className="material-icons">add</i></button>
                <button id="add_photo" ref={this.addPhotoRef}><i className="material-icons">add_a_photo</i></button>
                <button id="photo" ref={this.photoRef}><i className="material-icons">photo</i></button>
                <button id="back" ref={this.backRef} onClick={this.handleBackClick}><i className="material-icons">keyboard_arrow_left</i></button>
              </div>
              <SendButton text=""><i className="material-icons">send</i></SendButton>
            </div>
          );
        } else {
          return (
            <div>
              <div className="composer-button-panel__actions">
                <button id="additionals" ref={this.additionalsRef} onClick={this.handleAdditionalsClick}><i className="material-icons">add</i></button>
                <button id="photo" ref={this.photoRef}><i className="material-icons">photo</i></button>
                <button id="search" ref={this.searchRef} onClick={this.onClickProvideContent}><i className="material-icons">search</i></button>
                <button id="back" ref={this.backRef} onClick={this.handleBackClick}><i className="material-icons">keyboard_arrow_left</i></button>
              </div>
              <SendButton text=""><i className="material-icons">send</i></SendButton>
            </div>
          );
        }

      },
    };
  }

  generateMenu() {
    const  { conversation } = this.state;
    if (conversation) {
      return getMenuOptions(conversation);
    }
  }

  // Support use of the PDF Custom Message Type by detecting when the File Upload Widget receives a PDF file.
  filesSelected(evt: CustomEvent) {
    customFileSelector(evt, this.state.conversation);
  }

  // Handle Take over button click
  onClickTakeOver = () => {
    const { userId } = this.props;
    const { isTakeOverActive, conversation } = this.state;
    if (isTakeOverActive) {
      conversation.setMetadataProperties({ 'xircles_status': conversation.metadata.last_xircles_status });
      conversation.removeParticipants([userId]);
    } else {
      conversation.setMetadataProperties({
        'last_xircles_status': conversation.metadata.xircles_status,
        'xircles_status': INTERNAL_STATUS.TAKEOVER });
      conversation.addParticipants([userId]);
    }

    this.viewRef.current.node.trigger(
      'xircles-set-keyboard',
      {visibility: isTakeOverActive ? 'hidden' : 'visible', conversation}
    );
    this.setState({ isTakeOverActive: !isTakeOverActive });
  };

  render() {
    const { layerClient } = this.props;
    const {
      question, xirclesStatus, xirclesOperatorStatusFirst, xirclesOperatorStatusSecond, conversationId,
      showEditConversationDialog, isTakeOverActive
    } = this.state;
    // Setup the CSS Classes for the root element
    let rootClasses = 'answerPage';
    const activeConversationId = "layer:///conversations/" + conversationId;

    if (conversationId || showEditConversationDialog) rootClasses += ' has-conversation';
    return layerClient.isAuthenticated && conversationId &&
      <div className={rootClasses} ref={r => r && r.classList.add('open')}>
        <div className="question-panel">
          <Header
            onClickArrow={this.onConversationDeselected}
            onClickShare={this.onConversationShared}
            onClickDelete={this.onConversationDelete}
            onClickForwardToMedinfo={this.onConversationForward}
            onClickClearPV={this.onConversationPVReporting}
            onClickDeliverAnswer={this.onDeliverAnswer}
            onClickCloseRequest={this.onConversationCloseRequest}
            onClickDeleteRequest={this.onConversationDeleteRequest}

            onClickProvideContent={this.onClickProvideContent}

            question={question || this.getTitle()}
            status={xirclesStatus || this.getStatus()}
            operatorStatusFirst={xirclesOperatorStatusFirst || this.getStatus('operator-first')}
            operatorStatusSecond={xirclesOperatorStatusSecond || this.getStatus('operator-second')}
            isUserOperator={isOperator(layerClient.user)}
            onClickTakeOver={this.onClickTakeOver}
            isTakeOverActive={isTakeOverActive}
          />
          {<ConversationView
            ref={this.viewRef}
            queryFilter={(message) => this.filterMessages(message)}
            composePlaceholder={'Write here'}
            replaceableContent={this.customizeConversationView()}
            // Uncomment this line to add date separators that render between messages sent on different dates
            // onRenderListItem={dateSeparator}
            conversationId={activeConversationId}
          />}
        </div>
      </div>
  }
}

export default withRouter(AnswerPage);

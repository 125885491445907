// TODO: unify use of polyfills across the whole app
// TODO: Only include for IE 11
import './array-find';
import './array-from';
import './array-includes';
import './element-closest';
import './element-remove';
import 'promise-polyfill/src/polyfill';
import './string-includes';
import 'es6-shim';
import "core-js/fn/symbol/iterator.js";
import "core-js/es6/symbol.js";
import './nodelist-foreach';
import './classList-add-multiple-arguments'
import cssVars from 'css-vars-ponyfill';

import assign from 'es6-object-assign';

cssVars({
  rootElement: document,
  onlyLegacy: true,
  preserveStatic: true,
  variables: {
    "--mdc-theme-primary": "#009B77",
    "--x-first-lighter": "#B2E1D6",
    "--mdc-theme-secondary": "#494d5b",
    "--mdc-theme-background": '#fff',
    "--x-slate-light": '#aaacb2',
    "--x-slate-lighter": '#f0f1f3',
    "--dialog-content-color": '#494d5b',
    "--shadow-default": 'rgba(0, 0, 0, 0.21)',
    "--mdc-text-field-border-radius": '3px'
  }
})

assign.polyfill();
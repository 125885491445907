// @flow

/**
 *      Creates stateless UI component with buttons for Question page
 *
 *      send            - callback ()=>{} for send button, NOT required
 *      buttons         - array with props for BigButton, NOT required
 *      showSubmit      - boolean value for displaying send button, NOT required
 */

import * as React from 'react';

import './styles.less';
import BigButton from './BigButton/index';
import IconButton from '../../material-components/IconButton';
import { isIE } from '../../utils/common'

type Props = {
    innerRef: any,
    send?: Function,
    buttons?: Array,
    showSubmit?: Boolean,
    className?: string,
}

const BigButtonBar = (props: Props) => {
    return(
        <div className="bigButtonBarWrapper">
            <IconButton
              className="send"
              color="primary"
              style={{ display: props.showSubmit ? '' : 'none' }}
              onMouseDown={isIE() ? props.send : null}
            >
                <i className="xircles-icons icon-send" onMouseDown={props.send}/>
            </IconButton>
            <div ref={props.innerRef} className={`bigButtonBar ${props.className}`}>
                {
                    props.buttons.map((item, number) => (
                        <BigButton
                            id={ `shortcut-${number + 1}` }
                            text= { item.text }
                            mouseDownHandler={ item.onClick }
                            className={ item.className }
                            key = { number }
                        />
                    ))

                }
            </div>
        </div>
    )
};

BigButtonBar.defaultProps = {
    send: null,
    buttons: [],
    showSubmit: true,
};

export default BigButtonBar;

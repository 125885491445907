// @flow

import * as React from 'react';
import {translate} from 'react-i18next';
import DummyComponent from '../DummyComponent/index';


import './styles.less';

const ContentPanel = (props) => {

    return(
        <div className={'content-panel'}>
            <div className={'row'}>
                 <div className={'large-dummy-block'}>
                      <DummyComponent className={'content'}/>
                      <DummyComponent className={'content'}/>
                 </div>
                <DummyComponent className={'medium-dummy-block'}/>
            </div>
            <div className={'row'}>
                <DummyComponent
                className={'dummy-phrase'}
                children={`${props.t('COMMON_CONVERSATION_HEADER')}`}
                 />
            </div>
            <div className={'row'}>
                <DummyComponent className={'small-dummy-block'}/>
                <DummyComponent className={'small-dummy-block'}/>
                <DummyComponent className={'small-dummy-block'}/>
            </div>
        </div>
    )

};

export default translate()(ContentPanel);
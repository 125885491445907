import React from 'react';
import ReactDOM from "react-dom";
import {translate} from 'react-i18next';
import {Switch, Route, withRouter} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import _get from 'lodash/get';

import MaterialIcon from '@material/react-material-icon';

import {layerClient, Layer} from '../../../get-layer';
import IconButton from '../../../material-components/IconButton';
import LoginModalFragment from '../LoginModalFragment';
import LogoutModalFragment from '../LogoutModalFragment';
import LoginUnregisterModalFragment from '../LoginUnregisterModalFragment';
import SendCodeModalFragment from '../SendCodeModalFragment';
import PasswordModalFragment from '../PasswordModalFragment';
import ResetPasswordModalFragment from '../ResetPasswordModalFragment';
import WelcomeModalFragment from '../WelcomeModalFragment';
import CodeModalFragment from '../CodeModalFragment';
import DocCheckModalFragment from "../DocCheckModalFragment";
import DocCheckWelcomeModalFragment from "../DocCheckWelcomeModalFragment";
import {isPhoneNumber} from '../../../utils/common';
import {CHOICE_ACTION_EVENTS} from '../../../const/choice-actions';
import Dialog, {DialogTitle, DialogContent} from '../../../material-components/Dialog';
import CreatePasswordSubmitModalFragment from '../CreatePasswordSubmitModalFragment';
import PasswordCodeModalFragment from '../PasswordCodeModalFragment';
import { WELCOME_MODAL_TYPES } from '../../../const/welcome-modal-types';
import { USER_STATUSES } from '../../../const/status/user-status';
import LoadingSpinner from "../../LoadingSpinner";
import ErrorMessage from "../ErrorMessage";
import config from '../../../config.json';

import './styles.less';

const {uuid} = Layer.Utils;

type Props = {
  location: any,
  isOpen: boolean,
  onToggle: Function,
  onUserCheck: Function,
  onCodeSend: Function,
  onCodeVerify: Function,
  onLogin: Function,
  onLogout: Function,
  onDocCheckLogin: Function,
  onGoBack: Function | null,
  error: string | null,
  t: Function,
  resetError: Function,
  validatePhoneNumberOrEmail: Function,
  validatePassword: Function,
  validateSecurityCode: Function
};

type State = {
  phoneNumberOrEmail: string,
  securityCode: string,
  username: string,
  passwordNew: string,
  passwordRepeat: string,
  errorMessage: string,
  isGoingBack: boolean,
};

class LoginModal extends React.Component<Props, State> {
  state = {
    phoneNumberOrEmail: '',
    securityCode: '',
    dcUsername: '',
    username: '',
    password: '',
    passwordNew: '',
    passwordRepeat: '',
    isGoingBack: false,
    title: 'Login'
  };

  title: string;
  successEvent: string;
  canceledEvent: string;

  static getDerivedStateFromProps(nextProps) {
    const phoneNumberOrEmail = _get(nextProps.location, 'state.detail.phoneNumberOrEmail');

    if (phoneNumberOrEmail) {
      return {phoneNumberOrEmail};
    }

    return null;
  }

  getModalType = () => _get(this.props.location, 'state.detail.modalType');

  getDynamicTitle = () => {
    const modalType = this.getModalType();

    const dynamicTitle = {
      [WELCOME_MODAL_TYPES.RESET_PASSWORD]: 'LOGIN_RESET_PASSWORD_TITLE',
      [WELCOME_MODAL_TYPES.CREATE_PASSWORD]: 'LOGIN_CREATE_PASSWORD_TITLE',
      [WELCOME_MODAL_TYPES.LOGIN]: 'LOGIN_TITLE',
      [WELCOME_MODAL_TYPES.REGISTER]: 'REGISTER_TITLE',
    };

    return this.props.t(dynamicTitle[modalType]);
  };

  getCodeSubmitButtonTitle = () => {
    const modalType = this.getModalType();

    const buttonTitle = {
      [WELCOME_MODAL_TYPES.CREATE_PASSWORD]: 'LOGIN_NEXT_BUTTON',
      [WELCOME_MODAL_TYPES.LOGIN]: 'LOGIN_PASSWORD_LOGIN',
      [WELCOME_MODAL_TYPES.REGISTER]: 'COMMON_SUBMIT',
    };

    return this.props.t(buttonTitle[modalType]);
  };

  getTitle = () => {
    switch (this.props.location.pathname.split('/').filter(p => p !== '').pop()) {
      case 'login':
      case 'login-unregister':
      case 'send-code':
      case 'password':
        return this.props.t('LOGIN_TITLE');
      case 'code':
      case 'create-password':
      case 'password-code':
      case 'password-submit':
      case 'welcome':
        return this.getDynamicTitle();
      case 'doccheck':
      case 'doccheck-welcome':
        return this.props.t('LOGIN_DOCCHECK_TITLE');

      default:
        return '';
    }
  };

  getGreetingMessage = (username, welcomeTitle) => {
    const greetings = {
      [WELCOME_MODAL_TYPES.RESET_PASSWORD]: `${this.props.t('LOGIN_CREATE_PASSWORD_HELLO')}`,
      [WELCOME_MODAL_TYPES.CREATE_PASSWORD]: `${this.props.t('LOGIN_CREATE_PASSWORD_HELLO')}`,
      [WELCOME_MODAL_TYPES.LOGIN]: `${this.props.t('LOGIN_WELCOME_HELLO')} ${username},`,
      [WELCOME_MODAL_TYPES.REGISTER]: `${this.props.t('REGISTER_WELCOME_HELLO')} ${username}.`,
    };

    return greetings[welcomeTitle];
  };

  getWelcomeBackMessage = (welcomeTitle) => {
    const messages = {
      [WELCOME_MODAL_TYPES.RESET_PASSWORD]: `${this.props.t('LOGIN_WELCOME_BACK_CREATE_PASSWORD')}`,
      [WELCOME_MODAL_TYPES.CREATE_PASSWORD]: `${this.props.t('LOGIN_WELCOME_BACK_CREATE_PASSWORD')}`,
      [WELCOME_MODAL_TYPES.LOGIN]: `${this.props.t('LOGIN_WELCOME_BACK')}`,
      [WELCOME_MODAL_TYPES.REGISTER]: '',
    };

    return messages[welcomeTitle];
  };

  createConversationAndSendMessageToBot = (text, bot) => {
    const conversation = layerClient.createConversation({
      participants: [layerClient.user, bot],
      distinct: false,
      metadata: { conversationName: text },
    });

    const XirclesQuestionModel = Layer.Core.Client.getMessageTypeModelClass('XirclesQuestionModel');
    const model = new XirclesQuestionModel({text});
    model.send({conversation});

    this.props.history.push(`/questions/${uuid(conversation.id)}`);
  };

  prepareChatBot = (text) => {
    const bot = layerClient.getIdentity(config.bot_id, true);

    if (bot.isLoading) {
      bot.on('identities:loaded', () => this.createConversationAndSendMessageToBot(text, bot));
    } else {
      this.createConversationAndSendMessageToBot(text, bot);
    }
  };

  handleSignUp = () => {
    const signUpMessage = this.props.t('LOGIN_I_WANT_TO_REGISTER');

    if (layerClient.isReady) {
      this.prepareChatBot(signUpMessage);
    } else {
      layerClient.once('ready', () => this.prepareChatBot(signUpMessage));
    }
  };

  handleRenderLoginFragment = ({history}) => {
    const {phoneNumberOrEmail} = this.state;
    const {onUserCheck, validatePhoneNumberOrEmail} = this.props;
    const loginState = this.props.loginState === 'loading';

    this.successEvent = CHOICE_ACTION_EVENTS.login_success;
    this.canceledEvent = CHOICE_ACTION_EVENTS.login_cancel;

    return (
      <div>
        {
          loginState && <LoadingSpinner/>
        }
        <LoginModalFragment
          phoneNumberOrEmail={phoneNumberOrEmail}
          onPhoneNumberOrEmailChange={value => this.setState({phoneNumberOrEmail: value})}
          onNext={() => {
            validatePhoneNumberOrEmail(phoneNumberOrEmail) && onUserCheck({
              query: phoneNumberOrEmail,
            }).then(({ data }) => {
              const nextRoute = {
                [USER_STATUSES.UNKNOWN]: '/login-unregister',
                [USER_STATUSES.KNOWN]: '/send-code',
                [USER_STATUSES.PASSWORD]: '/password',
              };

              const selectedRoute = nextRoute[data.data];
              history.push(this.path + selectedRoute);
            })
          }}
          onSignUp={this.handleSignUp}
        />
      </div>
    );
  };

  handleRenderLoginUnregisterFragment = ({history}) => {
    const {phoneNumberOrEmail} = this.state;

    this.successEvent = CHOICE_ACTION_EVENTS.login_success;
    this.canceledEvent = CHOICE_ACTION_EVENTS.login_cancel;

    return (
      <LoginUnregisterModalFragment
        phoneNumberOrEmail={phoneNumberOrEmail}
        onSignUp={this.handleSignUp}
      />
    );
  };

  handleRenderLogoutFragment = ({history}) => {

    return (
      <LogoutModalFragment
        onLogout={() => {
          this.props.onLogout();
          history.push(this.path + '/')
        }}
        onCancel={() => {
          history.push(this.path + '/')
        }}
      />
    );
  };

  handleRenderDocCheckFragment = ({history}) => {
    const {dcUsername, password} = this.state;
    const {onDocCheckLogin} = this.props;
    const loginState = this.props.loginState === 'loading';

    this.successEvent = CHOICE_ACTION_EVENTS.doccheck_success;
    this.canceledEvent = CHOICE_ACTION_EVENTS.doccheck_cancel;

    return (
      <div>
        {
          loginState && <LoadingSpinner/>
        }
        <DocCheckModalFragment
          username={dcUsername}
          password={password}
          onUsernameChange={value => this.setState({dcUsername: value})}
          onPasswordChange={value => this.setState({password: value})}
          onNext={() => {
            onDocCheckLogin({
              username: this.state.dcUsername,
              password: this.state.password
            }).then((response) => {
              if (response.data.data === 'ok') {
                this.setState({
                  username: this.state.dcUsername
                }, );
                history.push(this.path + '/doccheck-welcome');
              } else {
                this.setState({
                  loginState: 'error',
                  errorMessage: 'Wrong credentials supplied'
                });
              }
            }).catch((err) => {
              this.setState({
                loginState: 'error',
                loginError: err
              });
            });
          }}
        />
      </div>
    );
  };

  handleRenderDocCheckWelcomeFragment = ({history}) => {
    const {username} = this.state;

    return (
      <DocCheckWelcomeModalFragment
        fullName={username}
        onAcknowledge={() => {
          this.setState({
            password: '',
          }, () => {
            this.onClose();
            history.push(this.path);
          });
        }}
      />
    );
  };

  handleRenderSendCodeFragment = ({history}) => {
    const {phoneNumberOrEmail} = this.state;
    const {onCodeSend} = this.props;
    const loginState = this.props.loginState === 'loading';

    return (
      <div>
        {
          loginState && <LoadingSpinner/>
        }
        <SendCodeModalFragment
          loginState={this.props.loginState}
          phoneNumberOrEmail={phoneNumberOrEmail}
          onRequestCode={() => {
            onCodeSend({
              identity: {
                type: isPhoneNumber(phoneNumberOrEmail) ? 'phone' : 'email',
                value: phoneNumberOrEmail
              },
              action: 'Login',
            })
              .then(() => {
                history.push({
                  pathname: this.path + '/code',
                  state: {detail: {modalType: WELCOME_MODAL_TYPES.LOGIN}}
                });
              });
          }}
          onCreatePassword={() => history.push({
            pathname: this.path + '/create-password',
            state: {detail: {modalType: WELCOME_MODAL_TYPES.CREATE_PASSWORD}}
          })}
        />
      </div>
    );
  };

  handleRenderCreatePasswordFragment = ({history}) => {
    const {phoneNumberOrEmail} = this.state;
    const {onCodeSend} = this.props;
    const loginState = this.props.loginState === 'loading';

    const modalType = this.getModalType();

    return (
      <div>
        {
          loginState && <LoadingSpinner/>
        }
        <ResetPasswordModalFragment
          phoneNumberOrEmail={phoneNumberOrEmail}
          onRequestCode={() => {
            onCodeSend({
              identity: {
                type: isPhoneNumber(phoneNumberOrEmail) ? 'phone' : 'email',
                value: phoneNumberOrEmail
              },
              action: 'ResetPassword',
            })
            .then(() => history.push({
              pathname: this.path + '/password-code',
              state: {detail: {modalType}},
            }))
          }}
        />
      </div>
    );
  };

  handleRenderPasswordCodeFragment = ({history}) => {
    const {securityCode, phoneNumberOrEmail} = this.state;
    const {onCodeSend, onCodeVerify, validateSecurityCode} = this.props;
    const loginState = this.props.loginState === 'loading';

    const modalType = this.getModalType();
    const submitButtonTitle = this.getCodeSubmitButtonTitle();

    return (
      <div>
        {
          loginState && <LoadingSpinner/>
        }
        <PasswordCodeModalFragment
          phoneNumberOrEmail={phoneNumberOrEmail}
          code={securityCode}
          submitButtonTitle={submitButtonTitle}
          onCodeChange={value => this.setState({securityCode: value})}
          onNext={() => {
            validateSecurityCode(securityCode) && onCodeVerify({
              identity: {
                type: isPhoneNumber(phoneNumberOrEmail) ? 'phone' : 'email',
                value: phoneNumberOrEmail
              },
              verification_code: securityCode
            })
            .then(({username, password_code}) => this.setState(
              {username},
              () => history.push({
                pathname: this.path + '/password-submit',
                state: {detail: {modalType, password_code}}
              })))
            .catch(() => {
              this.setState({securityCode: ''});
            });
          }}
          onRequestCode={() => {
            onCodeSend({
              identity: {
                type: isPhoneNumber(phoneNumberOrEmail) ? 'phone' : 'email',
                value: phoneNumberOrEmail
              },
              action: 'ResetPassword',
            })
          }}
        />
      </div>
    );
  };

  handleRenderCodeFragment = ({history}) => {
    const {securityCode, phoneNumberOrEmail} = this.state;
    const {onCodeSend, onCodeVerify, validateSecurityCode} = this.props;
    const loginState = this.props.loginState === 'loading';

    const submitButtonTitle = this.getCodeSubmitButtonTitle();
    const modalType = this.getModalType();
    const isRegister = modalType === WELCOME_MODAL_TYPES.REGISTER;

    if (isRegister) {
      this.successEvent = CHOICE_ACTION_EVENTS.confirm_register_cancel;
      this.canceledEvent = CHOICE_ACTION_EVENTS.confirm_register_cancel;
    }

    return (
      <div>
        {
          loginState && <LoadingSpinner/>
        }
        <CodeModalFragment
          phoneNumberOrEmail={phoneNumberOrEmail}
          code={securityCode}
          submitButtonTitle={submitButtonTitle}
          onCodeChange={value => this.setState({securityCode: value})}
          onRequestCode={() => {
            onCodeSend({
              identity: {
                type: isPhoneNumber(phoneNumberOrEmail) ? 'phone' : 'email',
                value: phoneNumberOrEmail
              },
              action: 'Register',
            });
          }}
          onLogin={() => {
            validateSecurityCode(securityCode) && onCodeVerify({
              identity: {
                type: isPhoneNumber(phoneNumberOrEmail) ? 'phone' : 'email',
                value: phoneNumberOrEmail
              },
              verification_code: securityCode
            })
            .then(({username}) => this.setState({username}, () => {
              history.push({
                pathname: this.path + '/welcome',
                state: {detail: {modalType}}
              })
            }))
            .catch(() => this.setState({securityCode: ''}));
          }}
        />
      </div>
    );
  };

  handleRenderPasswordFragment = ({history}) => {
    const {phoneNumberOrEmail, password} = this.state;
    const {onLogin, validatePassword} = this.props;
    const loginState = this.props.loginState === 'loading';

    return (
      <div>
        {
          loginState && <LoadingSpinner/>
        }
        <PasswordModalFragment
          phoneNumberOrEmail={phoneNumberOrEmail}
          password={password}
          onPasswordChange={value => this.setState({password: value})}
          onLogin={() => {
            validatePassword(password) && onLogin({
              identity: {
                type: isPhoneNumber(phoneNumberOrEmail) ? 'phone' : 'email',
                value: phoneNumberOrEmail
              },
              password
            }).then(({username}) => (
              this.setState({username}, () => (
                history.push({
                  pathname: this.path + '/welcome',
                  state: {detail: {modalType: WELCOME_MODAL_TYPES.LOGIN}}
                })
              ))
            )).catch(() => this.setState({password: ''}));
          }}
          onResetPassword={() => history.push({
            pathname: this.path + '/create-password',
            state: {detail: {modalType: WELCOME_MODAL_TYPES.RESET_PASSWORD}}
          })}
        />
      </div>
    );
  };

  handleRenderPasswordSubmitFragment = ({history}) => {
    const {phoneNumberOrEmail, passwordNew, passwordRepeat} = this.state;
    const {onUpsertPassword, validatePassword} = this.props;
    const loginState = this.props.loginState === 'loading';

    const modalType = this.getModalType();
    const password_code = _get(this.props.location, 'state.detail.password_code');

    return (
      <div>
        {
          loginState && <LoadingSpinner/>
        }
        <CreatePasswordSubmitModalFragment
          phoneNumberOrEmail={phoneNumberOrEmail}
          passwordNew={passwordNew}
          passwordRepeat={passwordRepeat}
          onPasswordNewChange={value => this.setState({passwordNew: value})}
          onPasswordRepeatChange={value => this.setState({passwordRepeat: value})}
          onLogin={() => {
            passwordNew === passwordRepeat && validatePassword(passwordNew) && onUpsertPassword({
              identity: {
                type: isPhoneNumber(phoneNumberOrEmail) ? 'phone' : 'email',
                value: phoneNumberOrEmail,
              },
              password_code,
              password: passwordNew,
            }).then(({username}) => (
              this.setState({username}, () => (
                history.push({
                  pathname: this.path + '/welcome',
                  state: {detail: {modalType}}
                })
              ))
            )).catch(() => this.setState({passwordNew: '', passwordRepeat: ''}));
          }}
        />
      </div>
    );
  };

  handleRenderWelcomeFragment = ({history}) => {
    const {username} = this.state;

    const modalType = this.getModalType();
    const isRegister = modalType === WELCOME_MODAL_TYPES.REGISTER;

    const greetingMessage = this.getGreetingMessage(username, modalType);
    const welcomeBackMessage = this.getWelcomeBackMessage(modalType);
    const acknowledgeButton = this.props.t('LOGIN_WELCOME_OK');

    this.successEvent = isRegister ? CHOICE_ACTION_EVENTS.confirm_register_success : CHOICE_ACTION_EVENTS.login_success;
    this.canceledEvent = isRegister ? CHOICE_ACTION_EVENTS.confirm_register_success : CHOICE_ACTION_EVENTS.login_success;

    return (
      <WelcomeModalFragment
        isRegister={isRegister}
        greetingMessage={greetingMessage}
        welcomeBackMessage={welcomeBackMessage}
        acknowledgeButton={acknowledgeButton}
        onAcknowledge={() => {
          this.setState({
            securityCode: '',
            password: '',
          }, () => {
            this.onClose();
            history.push({
              pathname: this.path,
              state: {
                detail: {
                  prevLocation: this.props.location.pathname,
                  modalType,
                }
              }
            })
          });
        }}
      />
    );
  };

  renderBackButton() {
    const {onGoBack} = this.props;
    if (!onGoBack) {
      return null;
    }

    return (
      <div style={{position: 'fixed', top: '-2px', left: '0px'}}>
        <IconButton size="small"
                    className="mdc-theme--primary"
                    onClick={() => this.setState({isGoingBack: true}, () => {
                      // back transition takes 400ms, so wait 800ms to be save,  even on ms edge
                      setTimeout(() => this.setState({isGoingBack: false}), 800);
                      onGoBack();
                    })}>
          <MaterialIcon icon="arrow_back"/>
        </IconButton>
      </div>
    );
  }

  renderCloseButton() {
    return (
      <div style={{position: 'fixed', top: '-2px', right: '0px', color: `xSlateLighter` }}>
        <IconButton size="small"
                    className="closeBtn"
                    onClick={this.onClose}>
          <MaterialIcon icon="clear"/>
        </IconButton>
      </div>
    );
  }

  onEnterErrorMessage = () => {
    this.setState({errorMessage: this.props.error});
  };

  onExitedErrorMessage = () => {
    this.setState({errorMessage: null});
  };

  renderError() {
    const {error, resetError} = this.props;
    const {errorMessage} = this.state;

    return (
      <ErrorMessage
        error={error}
        errorMessage={errorMessage}
        resetError={resetError}
        onEnterErrorMessage={this.onEnterErrorMessage}
        onExitedErrorMessage={this.onExitedErrorMessage}
      />
    )
  }

  onClose = (ev) => {
    const {onToggle} = this.props;

    if (this.state.username) {
      this.successEvent && window.dispatchEvent(new Event(this.successEvent));
    } else {
      this.canceledEvent && window.dispatchEvent(new Event(this.canceledEvent));
    }

    this.successEvent = null;
    this.canceledEvent = null;

    this.setState({username: ''});

    onToggle(ev);
  };

  isDoccheck = (title) => title === 'DocCheck Login';

  componentDidMount() {
    this.node = ReactDOM.findDOMNode(this);
    this.child = this.node.querySelector('.mdc-dialog__scrim');
    this.child.addEventListener('click', (event) => this.props.isOpen && this.onClose(event));
  }

  render() {
    const {isOpen} = this.props;

    this.path = this.props.location.pathname.split('/').filter(p => p !== '').slice(0, -1).map(p => `/${p}`).join('');
    this.title = this.getTitle();
    const isDoccheckVisible = this.isDoccheck(this.title);

    return (
      <Dialog className="xircles-login-dialog" open={isOpen}>
        <DialogTitle style={{textAlign: 'center'}}>
          {this.renderError()}
          {this.renderBackButton()}
          {this.title}
          {this.renderCloseButton()}
        </DialogTitle>
        <DialogContent>
          {
            !!isDoccheckVisible && <img className="doccheck-doc-image" src="/images/doccheck.svg"/>
          }
          <div className={`my-wrapper${this.state.isGoingBack ? ' back' : ''}`}>
            <TransitionGroup className="transition-group">
              <CSSTransition
                key={this.props.location.key}
                timeout={{enter: 400, exit: 400}}
                classNames="fade"
              >
                <section className="route-section">
                  <Switch location={this.props.location}>
                    <Route path='/questions/:conversationId?/login'
                           render={this.handleRenderLoginFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/login-unregister'
                           render={this.handleRenderLoginUnregisterFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/logout'
                           render={this.handleRenderLogoutFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/send-code'
                           render={this.handleRenderSendCodeFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/password-code'
                           render={this.handleRenderPasswordCodeFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/create-password'
                           render={this.handleRenderCreatePasswordFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/password-submit'
                           render={this.handleRenderPasswordSubmitFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/code'
                           render={this.handleRenderCodeFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/password'
                           render={this.handleRenderPasswordFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/welcome'
                           render={this.handleRenderWelcomeFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/doccheck-welcome'
                           render={this.handleRenderDocCheckWelcomeFragment}
                           exact/>
                    <Route path='/questions/:conversationId?/doccheck'
                           render={this.handleRenderDocCheckFragment}
                           exact/>
                  </Switch>
                </section>
              </CSSTransition>
            </TransitionGroup>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default translate()(withRouter(LoginModal));
